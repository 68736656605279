<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Dashboard v2</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Dashboard v2</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content content_waveData">
      <div class="container-fluid">
        <div class="wavegraph">
        
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <button class="back_btn" (click)="backButton(surveyID);"><i class="fa fa-caret-left"></i> Back</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="action_notes">
                <h3>Note -</h3>
                <ul>
                  <li><div class="ind_icon playbtn"><i class="fa fa-play"></i></div> Play button used for playing audio.</li>
                  <li><div class="ind_icon stopbtn"><i class="fa fa-stop"></i></div> Stop button is used to stop the audio.</li>
                  <li><div class="ind_icon addbtn"><i class="fa fa-plus"></i></div> Add Wave button use for choose start seconds and stop-seconds for intro, options, and keypress.</li>
                  <li><div class="ind_icon dropdownbrn"><i class="fa fa-angle-down"></i></div> Dropdown is used to choose waves for Intro, Options, and Keypress.</li>
                  <li><div class="ind_icon deletebtn"><i class="fa fa-trash"></i></div> Clear audio button used for clear audio variations.</li>
                </ul>
              </div>
            
            </div>
          </div>
          <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                  <h2>{{ Survey_name}}</h2>
                  <div class="waveform_wrap">
                      <div id="waveform"></div>
                      <div id="wave-timeline"></div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                  <div class="btn_actionWave">
                      <button class="view_wave_btn" (click)="playAudio()"><i class="fa fa-play"></i> Play</button>
                      <button class="view_wave_btn" (click)="stopAudio()"><i class="fa fa-stop"></i> Stop</button>
                      <button class="add_wave_btn" (click)="addReligion()" *ngIf="isRandom=='0'"><i class="fa fa-plus"></i> Add Segment</button>
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-10">
            <button class="back_btn" (click)="backButton(surveyID);"><i class="fa fa-caret-left"></i> Back</button>
          </div>
        </div>

        <div class="wavegraph2" *ngIf="isRandom=='0'">
          <div class="row" *ngIf="resultArray.length>0">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                <form method="POST" [formGroup]="randomForm">
                <table class="table responsive table-bordered table-striped" >
                    <thead >
                    <tr>
                    <th>Title</th> 
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Action</th>
                    <!-- <th>Edit(Start Time -End Time)</th> -->

                    </tr>
                    </thead>
                    <tbody formArrayName="randomOption">
                    <tr *ngFor="let res of resultArray; let i = index" [formGroupName]="i">
                    <td width="30%">
                      <select name="audio_option" id="audio_option"  formControlName="audio_option"  class="form-control form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected value="">Select Audio Type</option> 
                        <option *ngFor="let ran of random_default_parameter;let p = index" value="{{ ran.id }}">{{ ran.name }}</option>
                      </select>
                    </td> 
                    <!-- <td><div class="startend_time"><div class="start_time">{{ res.start | number: '1.2-2' }}</div> &nbsp; - &nbsp; <div class="end_time">{{ res.end | number: '1.2-2' }}</div></div></td> -->
                    <!-- <td><a class="tab_btn" href="javascript:void(0);" (click)="playAudioReligion(i);"><i class="fa fa-play"></i></a><a class="tab_btn" href="javascript:void(0);" (click)="stopAudio();"><i class="fa fa-stop"></i></a></td> -->
                    <td width="20%"><div class="startend_time_2"><input class="start_time width-220px" type="text" [(ngModel)]="res.start" (change)="updateRegion(i)" name="start_time_frm" id="start_time_frm"  formControlName="start_time_frm" required value="{{ res.start }}" ></div></td> 
                    <td width="20%"><div class="startend_time_2"> <input class="end_time width-220px" type="text" [(ngModel)]="res.end" (change)="updateRegion(i)"  name="end_time_frm" required id="end_time_frm" formControlName="end_time_frm"   value="{{ res.end }}"></div></td>

                    <td width="30%"><a class="tab_btn" href="javascript:void(0);" (click)="playAudioReligion(i);"><i class="fa fa-play"></i></a><a class="tab_btn" href="javascript:void(0);" (click)="stopAudio();"><i class="fa fa-stop"></i></a><a class="tab_btn" href="javascript:void(0);" (click)="deleteAudio(i);"><i class="fa fa-trash"></i></a></td>

                  </tr>
                    </tbody>
                </table>
                <a class="submit_file" style="display: inline-block;"   (click)="randomFormsubmit()"><i class="fa fa-file-audio"></i>Save</a>
                <a class="clear_file" style="display: inline-block;" (click)="removeData();"><i class="fa fa-trash"></i>Delete Segments</a>

                <!-- <button (click)="randomFormsubmit($event,'button')" class="submit_file"><i class="fa fa-file-audio"></i> Save</button> -->
                <!-- <button class="clear_file" (click)="removeData();"><i class="fa fa-trash"></i> Clear</button> -->
                </form> 
             </div>
          </div>
        </div>
        <div class="wavegraph2" *ngIf="isRandom=='1'">
          <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                <form method="POST">
                <table class="table responsive table-bordered table-striped" >
                    <thead>
                    <tr>
                      <th width="50%">Audio</th> 
                      <th>Title</th>
                    </tr>
                    </thead>
                    <tbody >
                    <tr *ngFor="let vari of randomArry; let i = index">
                      <td>
                        <audio controls  style="width: 100%;">
                        <source src="{{vari.url}}" type="audio/ogg">
                        <source src="{{vari.url}}" type="audio/mpeg">
                        Your browser does not support the audio tag.
                      </audio></td>
                      <td>
                        {{ vari.title }}
                      </td>
                    </tr>
                    </tbody>
                </table>
                </form> 
                <button class="clear_file" (click)="detailSurvey()"><i class="fa fa-trash"></i> Delete All Variations</button>
             </div>
          </div>
        </div>
      </div><!--/. container-fluid -->
    </section>
    <!-- /.content -->
</div>
 
