<div class="login-box">
    <div class="login-logo">
      <a routerLink="/"><img src="assets/logo.png" width="100%;"></a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
       <app-loader *ngIf="isLoader"></app-loader>
        <p class="login-box-msg">Sign in</p>
       
        <form  method="post" [formGroup]="Loginfrm" (ngSubmit)="frmLogin();">
          <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
          <div class="input-group mb-3">
            <input type="text" maxlength="10" formControlName="euserData" class="form-control" placeholder="Enter Mobile Number">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-phone"></span>
              </div>
            </div>
          </div>
          <span *ngIf="isLoginSubmit && f.euserData.errors">
            <span *ngIf="f.euserData.errors.required" style="color:red;" class="help-block">Please enter mobile number.</span>
            <span *ngIf="f.euserData.errors.pattern" style="color:red;" class="help-block">Please enter valid mobile number.</span>
          </span>
          <div class="row">
            <div class="col-8"></div>
            <!--/.col-->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
       <!-- /.social-auth-links -->
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>