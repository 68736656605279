  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Requested text survey list</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Survey</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Requested text survey list</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="table-responsive">
                <table id="example1"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Sr.No</th> 
                    <th>Customer Name</th>
                    <th>Mobile</th>
                    <th>Survey Name</th>
                    <th>Date</th>
                    <th>Updated Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="cursor: pointer;"  *ngFor="let survey of surveyArray; let i = index" (click)="redirectText(survey.id)">
                    <td>{{ i+1 }}</td>
                    <td>{{ survey.user_data.full_name }}</td>
                    <td>{{ survey.user_data.user_mobile  }}</td>
                    <td>{{ survey.survey_name  }}</td>
                    <td>{{ survey.created_at1}}</td>
                    <td>{{ survey.updated_at1}}</td>
                    <td><a routerLink="{{ survey.id }}/edit"><i class="fas fa-eye" style="font-size: 20px;"></i></a></td>
                  </tr>
                  <tr *ngIf="surveyArray.length==0">
                    <td colspan="7">No Record Found</td>
                  </tr>
                  </tbody>
                </table>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->