<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
           
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="card-header">
                <h3 class="card-title" *ngIf="!editForm">Add Audio</h3>
                <h3 class="card-title" *ngIf="editForm">Edit Audio</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              
              <form [formGroup]="Audiofrm" (ngSubmit)="audioFormsubmit();" >
                <div class="card-body">
                   <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                  <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                  <div class="form-group">
                    <label for="name">Audio File Name<span style="color: red;">*</span></label>
                    <input type="text" formControlName="audio_file_name" class="form-control" id="name" placeholder="Enter Name">
                    <span *ngIf="isFormsubmit && f.audio_file_name.errors">
                      <span *ngIf="f.audio_file_name.errors.required" style="color:red;" class="help-block">Please enter name.</span>
                    </span>
                  </div>
                <div class="form-group ">
                  <label for="state_name">Audio File <span style="color: red;">*</span></label>
                  <input type="file" (change)="fileChangeAudio($event);" formControlName="audio_file" accept="audio/*" class="form-control" id="audio_file" placeholder="Please upload file">
                  <span *ngIf="isFormsubmit && f.audio_file.errors">
                    <span *ngIf="f.audio_file.errors.required" style="color:red;" class="help-block">Please upload file.</span>
                  </span>
                </div>
                <div class="form-group">
                    <audio controls #audioPlay>
                      <source type="audio/mpeg">
                      Your browser does not support the audio tag.
                    </audio>
                 </div>
                 <div class="form-group">
                    <label for="state_name1">State <span style="color: red;">*</span></label>
                    <select class="form-control" formControlName="state_id"  >
                        <option value="">Select State</option>
                        <option *ngFor="let state of state_array" value="{{state.state_id}}">{{ state.name }}</option>
                    </select>  
                    <span *ngIf="isFormsubmit && f.state_id.errors">
                      <span *ngIf="f.state_id.errors.required" style="color:red;" class="help-block">Please select state.</span>
                    </span>
                 </div>
                </div>
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>