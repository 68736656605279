import { Component, OnInit,ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SettingService } from 'src/app/service/setting.service';

@Component({
  selector: 'app-data-download',
  templateUrl: './data-download.component.html',
  styleUrls: ['./data-download.component.css']
})
export class DataDownloadComponent implements OnInit {
  customeListArray =[];
  
  token ='';
  isLoader = false;
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  isfirstinit=true;
  userID:number;
  downloadListArray=[];
  constructor(private setting : SettingService) { }

  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [4],
        orderable :false
      }]
     
    };

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.userID =userData.user_id;
    this.getDownloadList();
  }
  getDownloadList()
  {
    this.isLoader=true;
    this.setting.downloadList(this.token,{'user_id':this.userID}).subscribe(res => {
      if(res['status']=='success')
      {
        this.downloadListArray = res.response_data;
        this.dtTrigger.next();
      }   
      this.isLoader=false;
    });
  }
  downloadData(downloadData)
  {
    return this.setting.datadownload(downloadData,this.userID);
  }

}
