<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-5">
            <!-- general form elements -->
           
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="card-header">
                <h3 class="card-title">Add Campaign</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form >
                <div class="card-body">
                  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                  <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                  <div class="form-group ownsurvey">
                    <a href="javascript:void(0);" (click)="userAdminLogin('admin_user')" title="create your own survey">Own survey</a>
                  </div>
                  <div class="form-group orline"><span>OR</span></div>
                  <div class="form-group ">
                    <label for="state_name">Select User</label>
                    <div class="ng-autocomplete">
                      <!-- <ng-autocomplete [data]="data" [searchKeyword]="keyword" (selected)='selectedDrop($event)'
                          (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate" placeHolder="Search by name or mobile number">
                      </ng-autocomplete> -->
                      <mat-form-field class="example-full-width">
                        <input type="text"
                               placeholder="Search by name or mobile number"
                               matInput
                               [formControl]="myControl"
                               [matAutocomplete]="auto"
                               (input)="onChangeSearch($event)">
                        <mat-autocomplete  #auto="matAutocomplete" >
                          <mat-option *ngFor="let option of filteredOptions | async"  [value]="option.full_name" (onSelectionChange)="selectedDrop(option.user_mobile)">
                            {{option.full_name}}({{option.user_mobile}})
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <ng-template #itemTemplate let-item>
                          <!--<a [innerHTML]="item.full_name"></a>-->
                          <a href="javascript:void(0)">
                            <span [innerHTML]="item.full_name"></span>
                            (<span [innerHTML]="item.user_mobile"></span>)
                        </a>
                       
                      </ng-template>
                  
                      <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                      </ng-template>
                  </div>
                    <!--<select class="form-control" (change)="selectedDrop($event)">
                        <option value="">Select User</option>
                        <option [value]="cust.user_mobile" *ngFor="let cust of customeListArray; let i = index" >{{ cust.full_name }} ({{ cust.user_mobile }})</option>   
                    </select>-->
                  </div>
                </div>
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" [disabled]="isDisable" class="btn btn-primary" (click)="userAdminLogin('normal_user')">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
