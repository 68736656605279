  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Survey List</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Survey</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header ch_header">
                <span>Question List</span>
                <h3 class="card-title">                    
                   Survey Name: {{ surveyName }}  
                </h3>
                <div class="float-right">
                    <button (click)="goToAdd()" class="btn btn-md btn-info px-5"   type="submit">Add Question</button>
                 </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <app-loader *ngIf="isLoader"></app-loader>
                  <div class="alert alert-danger" *ngIf="errorMessage">
                      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                      {{ errorMessage }}
                  </div>
                  <div class="alert alert-success alert-dismissible" *ngIf="successMessage">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>  
                    {{ successMessage }}
                  </div>
                <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="example1" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Sr.No</th> 
                    <th>Question No</th>
                    <th>Question Name</th>
                    <th>Language</th>
                    <th width="12%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let questions of stepsArray; let i = index"   style="cursor: pointer;" >
                        <td>{{ i+1 }} </td>
                        <td>{{ questions.placeholder_name }}</td>
                        <td>{{ questions.question_text }}</td>
                        <td>{{ questions.language_name  }}</td>
                        <td>
                            <!-- <a title="View survey" class="mr-2 mb-2 d-inline-block"><i class="fas fa-eye" style="font-size: 20px;"></i></a> -->
                            <a (click)="goToEdit(questions.question_id)" title="View survey" class="mr-2 mb-2 d-inline-block"><i class="fas fa-edit" style="font-size: 20px;"></i></a>
                            <a (click)="deleteStep(questions.question_id)" class="d-inline-block" title="View survey"><i class="fas fa-trash" style="font-size: 20px;"></i></a>
                        </td>
                  </tr>
                  <tr *ngIf="stepsArray.length == 0">
                    <td colspan="8">No Record Found</td>
                  </tr>
                 </tbody>
                </table>                
              </div>
             </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->