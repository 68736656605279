import { Component, OnInit, ViewChild } from '@angular/core';
import { AudioService } from 'src/app/service/audio.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-audio-list',
  templateUrl: './audio-list.component.html',
  styleUrls: ['./audio-list.component.css']
})
export class AudioListComponent implements OnInit {

  audioListArray =[];
  token ='';
  isLoader = false;
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  isfirstinit=true;
  constructor(private audiosrv:AudioService,) { }

  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [4],
        orderable :false
      }]
     
    };

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.getList();
    
  }
  getList()
  {
    this.isLoader=true;
    this.audiosrv.audioList(this.token).subscribe(audio => {
      if(audio['status']=='success')
      {
        this.audioListArray = audio.response_data;
        this.dtTrigger.next();
      }   
      this.isLoader=false;
    });
  }
  
  audioStatus(audio_id,status)
  {
      if(status=='0')
      {
        status = '1'
      }
      else
      {
        status = '0';
      }
      if(confirm("Are you sure you want to change the status?"))
      {
        this. isLoader=true;
        const audioArray = {
          'status': status,
          'custom_audio_id':audio_id
        };
        this.audiosrv.statusChange(this.token,audioArray).pipe(first()).subscribe(user => {
          if(user.status=='success')
          {
            this.rerender();
            this.ngOnInit();
            //window.location.reload();
            this.isLoader=false;
          }   
          else
          {
            this.isLoader=false;
          }
          
        });
      }
  }
  rerender():void {
    this.dtElement.dtInstance.then((dtInstance : DataTables.Api)=>{
      dtInstance.destroy();
      //this.dtTrigger.next();
    })
  }

}
