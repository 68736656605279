<style>
    .action-links a{font-size:22px;}
    .edit-link{color:#000}
    .active-status-link{color:green}
    .block-status-link{color:red;}
  </style>
  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper download_wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
     <!-- Download option-->
     <div class="modal fade approve_modal custom_download_modal" id="modal-default">
        <div class="modal-dialog">
          <div class="modal-content">
            <app-loader *ngIf="downloadLoader"></app-loader>  
            <div class="modal-header">
              <h3>Download Report</h3>
              <button type="button" #closebuttonapprove class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" *ngIf="err_msg">{{ err_msg }}</div>
              <div class="button_actions">
                <div class="row">
                  <div class="col-6" *ngFor="let btndata of ButtonName;let p = index;" style="margin-top: 10px;">
                    <button [disabled]="btndata.button_status" type="button"  class="btn btn-primary mr-2 download_btn1 w-100" (click)="downloadData(btndata.slug);">{{btndata.button_name}}</button>
                  </div>
               </div>
              </div>
              <div class="download_or">
                <span>OR</span>
              </div>
              <div class="download_form">
                <form [formGroup]="Datefrm" (ngSubmit)="CustomeFormsubmit();">
                  <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col-6">
                        <div class="cal_wrap">
                          <input type="text" class="form-control" placeholder="From Date"  bsDatepicker [bsConfig]="{dateInputFormat: 'DD-MM-YYYY'}" formControlName="from_date" >
                          <i class="far fa-calendar-alt"></i>
                        </div>
                        <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.from_date.errors">
                          <span *ngIf="f.from_date.errors.required" style="color:red;" class="help-block">Please select from date.</span>
                        </span></p>
                      </div>
                      <div class="col-6">
                        <div class="cal_wrap">
                          <input type="text" class="form-control"  formControlName="to_date" placeholder="To Date" bsDatepicker [bsConfig]="{dateInputFormat: 'DD-MM-YYYY'}">
                          <i class="far fa-calendar-alt"></i>
                        </div>
                        <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.to_date.errors">
                          <span *ngIf="f.to_date.errors.required" style="color:red;" class="help-block">Please select to date.</span>
                        </span></p>
                      </div>

                    </div>
                  </div>
                  <div class="col-3">
                    <button type="submit" class="btn btn-primary mr-2 gen_btn1">Download</button>
                  </div>
                  </div>
                </form>
              </div>
             </div>
          </div>
        </div>
     </div>
    <!--Download option--> 


    <!-- Main content -->
     <section class="content">
      <!-- http://localhost:4200/customer/72/log -->
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header mt-1">
                   <div class="row">
                       <div class="col-sm-2"><h3 class="card-title">Payment Log</h3></div>
                       <div class="col-sm-3"><h3 class="card-title">Customer : {{ user_name }} ({{ user_mobile }})</h3></div>
                       <div class="col-sm-3"><h3 class="card-title">Current Balance  : {{  total_wallet_available_credits }}</h3></div>
                       <div class="col-sm-4 text-right actions_header">
                         <a routerLink="/customer/{{customerId}}/add/" class="edit-link t-extra-btn addbtn"  title="Add Credits" style="margin-right: 10px;" ><i class="fa fa-plus"></i></a>
                         <a routerLink="/customer/{{customerId}}/debit/" class="edit-link t-extra-btn debitbtn" title="Debit Credits" ><i class="fa fa-minus"></i></a>
                      </div>
                      <!---Don't send-->
                    </div>
               </div>

              <div class="card-header" *ngIf="isShowButton">
                <div class="row">
                  <div class="col-lg-3">
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#modal-default" class="custom_down_btn">Download Report</a>
                  </div>
                </div>
              </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped " datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Credit Added</th> 
                        <th>Date</th>
                        <th>Credits</th>
                        <th>Price</th>
                        <th>Survey Name</th>
                        <th>Short Desc</th>
                        <th>Desc</th>
                        <th>Type</th>
                    </tr>
                   </thead>
                   <tbody>
                   <tr *ngFor="let cust of customeListArray; let i = index">
                     <td>{{ i+1 }}</td>
                     <td>{{ cust.added_by }}</td>
                     <td>{{ cust.date }}</td>
                     <td>{{ cust.credits }}</td>
                     <td>{{ cust.price  }}</td>
                     <td>{{ cust.survey_name  }}</td>
                     <td>{{ cust.shortdesc  }}</td>
                     <td>{{ cust.text  }}</td>
                     <td>
                       <span *ngIf="cust.type==='credit'"><span class="badge badge-success">Credit</span></span>
                       <span *ngIf="cust.type==='debit'"><span class="badge badge-danger">Debit</span></span>
                     </td>
                   </tr>
                 
                   </tbody>
                 </table>
               </div>
              </div>
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->