
<style>
    .survey-status-box{min-height:60px;}
    .survey-status-box .info-box-content h4{margin-bottom:0}
  </style>
  <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Project Detail</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Project Detail</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>
  
      <!-- Main content -->
      <section class="content">
        <app-loader *ngIf="isLoader"></app-loader>
        <div class="card">
            <div class="card-body">
              <ul class="list-navi-info">
                <li><span class="greenbox"></span> Question Tags Added</li>
                <li><span class="yellowbox"></span> Question Tags Pending</li>
              </ul>
                <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
            </div>
        </div>
        <!-- Default box -->
        
        <div  *ngFor="let au of surveyAudio;let i = index"  [ngClass]="{'collapsed-card': au.audio_id!=isID}"  class="card">
          <div class="card-header"  [ngClass]="{'cart-back-yellow' : au.arr_survey_question.length==0,'cart-back-green' :  au.arr_survey_question.length==1}">
            <div class="row">
              <div class="col-6 col-md-4 col-lg-4 p-1"><strong class="f-w">{{ title[0].type | titlecase }} Choosen :</strong>{{ title[0].list_state}}  </div>
              <div class="col-6 col-md-3 col-lg-3 p-1"></div>
              <div class="col-6 col-md-1 col-lg-1 p-1"></div>
              <div class="col-5 col-md-3 col-lg-3 p-1 text-right"><strong class="f-w" >Customer No :</strong> {{ mobile_number }}</div>
              <div class="card-tools col-1 col-md-1 col-lg-1 p-1 text-right">
                <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i class="fas fa-minus"></i></button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 mb-3">
                <audio controls  style="width: 100%;">
                  <source src="{{au.original_bip_file}}" type="audio/ogg">
                  <source src="{{au.original_bip_file}}" type="audio/mpeg">
                  Your browser does not support the audio tag.
                </audio>
              </div>
              <div class="col-12 col-md-12 col-lg-12"  *ngIf="au.moderator_status=='1'">
                  <div class="card card-success">
                    <div class="card-header with-border">
                      <h3 class="card-title"><i class="fas fa-tags"></i> Add Tag</h3>
                    </div>
                    <!-- form start -->
                    <div class="left-right-wrap">
                        <div class="row">
                           <div class="col-lg-8" *ngIf="au.arr_survey_question.length==0">
                            <div class="alert alert-danger" *ngIf="errorMessageAudio && au.audio_id==isID">{{ errorMessageAudio }}</div>
                            <div class="alert alert-success" *ngIf="successMessageAudio && au.audio_id==isID">{{ successMessageAudio }}</div>
                              <div class="left-form">
                                  <form  #c="ngForm" (ngSubmit)="formSubmitTag(c,au.audio_id,survey_id)"> 
                                      <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-2 col-form-label">Tag</label>
                                        <div class="col-sm-10">
                                          <!--<input type="text" class="form-control" placeholder="Enter tag">-->
                                          <!-- <tag-input [(ngModel)]='item[au.audio_id]' name="item[au.audio_id]" [addOnBlur]="true" [clearOnBlur]="true" [ngModelOptions]="{standalone: true}">
                                            <tag-input-dropdown theme='bootstrap' [autocompleteItems]="autocompleteItems"></tag-input-dropdown>
                                          </tag-input> -->
                                          <span *ngIf="isFormsubmittag && item[au.audio_id].length==0 && au.audio_id==isIDError">
                                            <span  style="color:red;" class="help-block">Please add tags.</span>
                                          </span>
                                        </div>
                                      </div>
                                      
                                      <div class="form-group row">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Question</label>
                                        <div class="col-sm-10">
                                          <textarea class="form-control" ngModel name="question" rows="3" required placeholder="Enter question"></textarea>
                                          <span *ngIf="isFormsubmittag && c.value.question=='' && au.audio_id==isIDError">
                                            <span  style="color:red;" class="help-block">Please enter question.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="form-group row" *ngIf="au.audio_option>1">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option 1</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option1" required placeholder="Enter option 1">
                                          <span *ngIf="isFormsubmittag && c.value.option1=='' && au.audio_id==isIDError">
                                            <span   style="color:red;" class="help-block">Please enter option 1.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="form-group row" *ngIf="au.audio_option>=2">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option 2</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option2" required placeholder="Enter option 2">
                                          <span *ngIf="isFormsubmittag && c.value.option2=='' && au.audio_id==isIDError">
                                            <span   style="color:red;" class="help-block">Please enter option 2.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="form-group row" *ngIf="au.audio_option>=3">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option 3</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option3" required placeholder="Enter option 3">
                                          <span *ngIf="isFormsubmittag && c.value.option3=='' && au.audio_id==isIDError">
                                            <span   style="color:red;" class="help-block">Please enter option 3.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="form-group row" *ngIf="au.audio_option>=4">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option 4</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option4" required placeholder="Enter option 4">
                                          <span *ngIf="isFormsubmittag && c.value.option4=='' && au.audio_id==isIDError">
                                            <span   style="color:red;" class="help-block">Please enter option 4.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="form-group row" *ngIf="au.audio_option>=5">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option 5</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option5" required placeholder="Enter option 5">
                                          <span *ngIf="isFormsubmittag && c.value.option5=='' && au.audio_id==isIDError">
                                            <span   style="color:red;" class="help-block">Please enter option 5.</span>
                                          </span>
                                        </div>
                                      </div>
                                      <!--<div class="form-group row" *ngFor="let l of optionSelected(au.audio_option);let p= index;">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Option {{ p+1 }}</label>
                                        <div class="col-sm-10">
                                          <input type="text" class="form-control" ngModel name="option{{ p+1 }}" required placeholder="Enter option {{ p+1 }}">
                                          <span *ngIf="isFormsubmittag && au.audio_id==isID">
                                            <span   style="color:red;" class="help-block">Please enter option {{ p+1 }}.</span>
                                          </span>
                                        </div>
                                      </div>-->
                                      <div class="form-group row">
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-10">
                                          <button type="submit" class="btn btn-primary">Submit</button>
                                          <button type="button" (click)="clearData(c,au.audio_id,'add');" class="btn btn-secondary">Cancel</button>
                                        </div>
                                      </div>
                                    </form>
                              </div>
                          </div>
                          <div class="col-lg-8" *ngIf="au.arr_survey_question.length >0">
                              <div class="alert alert-danger" *ngIf="errorMessageAudio && au.audio_id==isID">{{ errorMessageAudio }}</div>
                              <div class="alert alert-success" *ngIf="successMessageAudio && au.audio_id==isID">{{ successMessageAudio }}</div>
                                <div class="left-form" *ngIf="!this.hideme[au.audio_id]"  >
                                    <button type="button" class="btn edit-all-form" (click)="DivHide(au.audio_id,i)"><i class="far fa-edit"></i></button>
                                    <div class="form-group row">
                                      <label class="col-sm-2 col-form-label">Tags</label>
                                      <div class="col-sm-8">
                                       <p class="already-text">{{ au.arr_survey_question[0].tag_name }}</p>
                                      </div>
                                      <div class="col-sm-2">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label class="col-sm-2 col-form-label">Question</label>
                                      <div class="col-sm-8">
                                       <p class="already-text">{{ au.arr_survey_question[0].question_description}}</p>
                                      </div>
                                      <div class="col-sm-2">
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.arr_survey_question[0].option_1" >
                                      <label class="col-sm-2 col-form-label">Option 1</label>
                                      <div class="col-sm-10" >
                                        <p class="already-text">{{ au.arr_survey_question[0].option_1 }}</p>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.arr_survey_question[0].option_2" >
                                      <label class="col-sm-2 col-form-label">Option 2</label>
                                      <div class="col-sm-10" >
                                        <p class="already-text">{{ au.arr_survey_question[0].option_2 }}</p>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.arr_survey_question[0].option_3" >
                                      <label class="col-sm-2 col-form-label">Option 3</label>
                                      <div class="col-sm-10" >
                                        <p class="already-text">{{ au.arr_survey_question[0].option_3 }}</p>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.arr_survey_question[0].option_4" >
                                      <label class="col-sm-2 col-form-label">Option 4</label>
                                      <div class="col-sm-10" >
                                        <p class="already-text">{{ au.arr_survey_question[0].option_4 }}</p>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.arr_survey_question[0].option_5" >
                                      <label class="col-sm-2 col-form-label">Option 5</label>
                                      <div class="col-sm-10" >
                                        <p class="already-text">{{ au.arr_survey_question[0].option_5 }}</p>
                                      </div>
                                    </div>
                                </div>
                                <div class="left-form" *ngIf="this.hideme[au.audio_id]">
                                  <button type="button" class="btn edit-all-form" (click)="DivClose(au.audio_id,i)"><i class="fas fa-times"></i></button>
                                  <form  #cedit="ngForm" (ngSubmit)="formSubmitTagEdit(cedit,au.audio_id,survey_id)"> 
                                    <div class="form-group row">
                                      <label for="staticEmail" class="col-sm-2 col-form-label">Tag</label>
                                      <div class="col-sm-10">
                                        <!--<input type="text" class="form-control" placeholder="Enter tag">-->
                                        <!-- <tag-input [(ngModel)]='items_edit' name="items_edit" [addOnBlur]="true" [clearOnBlur]="true" [ngModelOptions]="{standalone: true}">
                                          <tag-input-dropdown theme='bootstrap' [autocompleteItems]="autocompleteItems"></tag-input-dropdown>
                                        </tag-input> -->
                                        <span *ngIf="isFormsubmittag && items_edit.length==0 && au.audio_id==isID">
                                          <span  style="color:red;" class="help-block">Please add tags.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Question</label>
                                      <div class="col-sm-10">
                                        <textarea class="form-control" [(ngModel)]="question_edit" name="question_edit" required rows="3" placeholder="Enter question"></textarea>
                                        <span *ngIf="isFormsubmittag && cedit.value.question_edit=='' && au.audio_id==isIDError">
                                          <span  style="color:red;" class="help-block">Please enter question.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.audio_option>1">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Option 1</label>
                                      <div class="col-sm-10">
                                        <input type="text" class="form-control" [(ngModel)]="option_edit_1" name="option_edit_1" required placeholder="Enter option 1">
                                        <span *ngIf="isFormsubmittag && cedit.value.option_edit_1=='' && au.audio_id==isIDError">
                                          <span   style="color:red;" class="help-block">Please enter option 1.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.audio_option>=2">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Option 2</label>
                                      <div class="col-sm-10">
                                        <input type="text" class="form-control" [(ngModel)]="option_edit_2" name="option_edit_2" required placeholder="Enter option 2">
                                        <span *ngIf="isFormsubmittag && cedit.value.option_edit_2=='' && au.audio_id==isIDError">
                                          <span   style="color:red;" class="help-block">Please enter option 2.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row"  *ngIf="au.audio_option>=3">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Option 3</label>
                                      <div class="col-sm-10">
                                        <input type="text" class="form-control" [(ngModel)]="option_edit_3" name="option_edit_3" required placeholder="Enter option 3">
                                        <span *ngIf="isFormsubmittag && cedit.value.option_edit_3=='' &&  au.audio_id==isIDError">
                                          <span   style="color:red;" class="help-block">Please enter option 3.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.audio_option>=4">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Option 4</label>
                                      <div class="col-sm-10">
                                        <input type="text" class="form-control" [(ngModel)]="option_edit_4" name="option_edit_4" required placeholder="Enter option 4">
                                        <span *ngIf="isFormsubmittag && cedit.value.option_edit_4=='' && au.audio_id==isIDError">
                                          <span   style="color:red;" class="help-block">Please enter option 4.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row" *ngIf="au.audio_option>=5">
                                      <label for="inputPassword" class="col-sm-2 col-form-label">Option 5</label>
                                      <div class="col-sm-10">
                                        <input type="text" class="form-control" [(ngModel)]="option_edit_5" name="option_edit_5" required placeholder="Enter option 5">
                                        <span *ngIf="isFormsubmittag && cedit.value.option_edit_5=='' && au.audio_id==isIDError">
                                          <span   style="color:red;" class="help-block">Please enter option 5.</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-sm-2"></div>
                                      <div class="col-sm-10">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <button type="button" (click)="clearData(cedit,au.audio_id,'update');" class="btn btn-secondary">Cancel</button>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                            </div>
                            <div class="col-lg-4">
                              <div class="right-box">
                                <div class="row">
                                  <div class="col-sm-12">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Language </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_language }} </span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Option</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_option }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Date</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.date }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12" *ngIf="au.reason!=null">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Reason </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.reason }}</span>
                                      </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div> 
                      </div> 
                    </div>
                </div>
         </div>
       </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
    </section>
      <!-- /.content -->
</div>
<!-- /.content-wrapper -->