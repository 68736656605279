
<style>
  .survey-status-box{min-height:60px;}
  .survey-status-box .info-box-content h4{margin-bottom:0}
</style>
<!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Project Detail</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Project Detail</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <app-loader *ngIf="firstLoader"></app-loader>
      <div class="callout callout-info">
          <h5><i class="fas fa-info"></i> Note:</h5>
          1.Allowed file formats MP3,MP4,wav.<br/>
          2.Maximum 40 seconds audio file will accepted.<br/>
          3.Kindly please select proper option.<br/>
          4.Please enter proper reason for accept or reject audio.<br/>

      </div>
      <div class="callout  callout-info">
        <h5>Survey Type :IVR [{{special_survey_type}}]</h5>
      </div>

      <div class="callout  callout-info d-flex">
        
          <div class="CSV-file m-1">
            <!-- <input type="file" class="custom-file-input" id="exampleInputFile"> -->
            <input type="file"  #fileUploader  (change)="onCsvFileSelect($event)"  ngModel class="form-control file-input" name="filereplace" >
           
           </div>
           <div class="text-left m-1  ">
            <button class="btn btn-md btn-success px-5" [disabled]="csvDisable" (click)="csvupload()" type="submit">Upload Demo CSV</button>
           </div>
           <div class="text-left m-1 " *ngIf="demo_csv_file">
            <button class="btn btn-md btn-info px-5" (click)="csvDownload()" type="submit">Download</button>
           </div>
           <div class="text-left m-1 " *ngIf="demo_csv_file">
            <button class="btn btn-md btn-danger px-5" (click)="csvDelete()" type="submit">Delete Demo CSV</button>
           </div>
           <div class="text-left m-1">
              <button class="btn btn-md btn-info px-5"  (click)="gotosteps()" type="submit">Add Question</button>
           </div>
         
      </div>
      

      <!-- Default box -->
      <div  *ngFor="let au of surveyAudio;let i = index"  [ngClass]="{'collapsed-card': au.audio_id!=isAudioId}"  class="card">
        <app-loader *ngIf="isLoader && isID==au.audio_id"></app-loader>
        <div class="card-header " [ngClass]="{'cart-back-yellow' : au.moderator_status=='0','cart-back-green' :  au.moderator_status=='1','cart-back-red' :  au.moderator_status=='2'}">
          <!-- <h3 class="card-title">Projects Detail</h3> -->
          <div class="row">
            <div class="col-3 p-1 titail-into"><strong class="tital">Total CSV Count :</strong> {{ totalCsvCount }}</div>
            <div class="col-4 p-1 titail-into"><strong class="tital">Target Sample Size :</strong> {{ sample_size }}</div>
            <div class="col-3 p-1 titail-into"><strong class="tital">Pull Data(%) :</strong> {{calc_survey_count}} </div>
          </div>
          <div class="row">
            <div class="col-3 p-1 titail-into "><strong class="tital">{{ title[0].type | titlecase }} Choosen :</strong>{{
              title[0].list_state}}</div>
            <!-- <div class="col-2 p-1"><strong>Target Sample Size :</strong> {{ sample_size }}</div>
              <div class="col-2 p-1"><strong>Pull Data(%) :</strong> {{calc_survey_count}} </div> -->
            <div class="col-4 p-1 titail-into"><strong class="tital">Customer Name :</strong> {{ userName }}</div>
            <div class="col-3 p-1 titail-into"><strong class="tital">Customer No :</strong> {{ mobile_number }}</div>
  
            <div class="card-tools col-2 p-1 text-right">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                title="Collapse">
                <i class="fas fa-minus"></i></button>
              <!--<button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i class="fas fa-times"></i></button>-->
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="panel_builder_survey_type=='call'">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 mb-3" *ngIf="au.original_bip_file">
              <audio controls  style="width: 100%;">
                <source src="{{au.original_bip_file}}" type="audio/ogg">
                <source src="{{au.original_bip_file}}" type="audio/mpeg">
                Your browser does not support the audio tag.
              </audio>
            </div>
            <div class="col-12 col-md-12 col-lg-12 mb-3" *ngIf="!au.original_bip_file">
              <div class="que-main-wraper">
                <p>Question : {{ au.text_question }}</p>
                <ul>
                  <li *ngIf="au.text_question_options.option1;">{{ au.text_question_options.option1 }}</li>
                  <li *ngIf="au.text_question_options.option2;">{{ au.text_question_options.option2 }}</li>
                  <li *ngIf="au.text_question_options.option3;">{{ au.text_question_options.option3 }}</li>
                  <li *ngIf="au.text_question_options.option4;">{{ au.text_question_options.option4 }}</li>
                  <li *ngIf="au.text_question_options.option5;">{{ au.text_question_options.option5 }}</li>
                </ul>
              </div>
            </div>
            <!-- <div class="col-12 col-md-12 col-lg-12 mb-5" *ngIf="au.moderator_status=='1'">
              <h1>Approved</h1>
            </div>
            <div class="col-12 col-md-12 col-lg-12 mb-5" *ngIf="au.moderator_status=='2'">
              <h1>Rejected</h1>
            </div> -->
            <div class="col-4 col-md-4 col-lg-4"></div>
            <div class="col-4 col-md-4 col-lg-4">
            <div class="info-box survey-status-box bg-green" *ngIf="au.moderator_status=='1'">
              <span class="info-box-icon"><i class="fas fa-check"></i></span>
                <div class="info-box-content">
                  <h4>Approved</h4>
                </div>
              <!-- /.info-box-content -->
            </div>
            <div class="info-box survey-status-box bg-red" *ngIf="au.moderator_status=='2'">
              <span class="info-box-icon"><i class="fas fa-times"></i></span>
                <div class="info-box-content">
                  <h4>Rejected</h4>
                </div>
              <!-- /.info-box-content -->
            </div>
          </div>
          <div class="col-4 col-md-4 col-lg-4"></div>
            <!--<div class="col-12 col-md-12 col-lg-12">
              <div class="text-right mb-4">
                <button class="btn btn-md btn-primary px-5" (click)="onClickNext(i+1)">Next <i class="fa fa-angle-right ml-2"></i></button>
              </div>
             </div>-->
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="wave_btn">
                <a class="add_wave_btn" href="javascript:void(0);" (click)="AddWave(au.audio_id,au.survey_id)"><i class="fa fa-plus"></i> Add Version</a>
                <a class="view_wave_btn"  href="javascript:void(0);" (click)="openPopup()"><i class="fa fa-eye"></i> View Version</a>
              </div>
            </div>

            <div
            class="modal List_of_segments"
            tabindex="-1"
            role="dialog"
            [ngStyle]="{'display':displayStyle}">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <h4 class="modal-title">List of segments</h4>
                  <div class="li_audio" *ngFor="let vari of au.variations;let p = index;">
                    <h6>{{ vari.variation }}</h6>
                    <audio controls  style="width: 100%;">
                      <source src="{{vari.link}}" type="audio/ogg">
                      <source src="{{vari.link}}" type="audio/mpeg">
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
                </div>
              </div>
            </div>
        </div>   

        <div class="col-4 col-md-4 col-lg-4" *ngIf="au.moderator_status=='0'">
          <div class="card card-primary h-100">
            <div class="card-header with-border">
              <h3 class="card-title">Approve</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form method="POST" #f="ngForm" (ngSubmit)="moderatorFormsubmit(f,au.audio_id)">
              <div class="card-body">
                <div class="alert alert-danger" *ngIf="first_error && isID==au.audio_id">Error : {{ first_error }}</div>
                <input type="hidden" name="audio_id_1" id="audio_id_1" [ngModel]="au.audio_id"  >
                <div class="dis-que-approve" *ngIf="!au.original_audio_file"><p><i class="fa fa-exclamation-circle" aria-hidden="true"></i>Please upload audio file</p><i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
                <div class="form-group">
                  <label>Choose number of options <span style="color: red;">*</span></label>
                  <select class="form-control select2" name="audio_option" required id="audio_option" [(ngModel)]='au.audio_option' ngModel style="width: 100%;">
                    <option value="" selected="selected">Select Number</option>
                    <option value="1">1 - Voice option</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <span *ngIf="isFormsubmitAccept && f.value.audio_option=='' && au.audio_id==isID">
                    <span  style="color:red;" class="help-block">Please select option</span>
                  </span>
                </div>
              
                <div class="form-group">
                  <label>Choose language <span style="color: red;">*</span></label>
                  <select class="form-control select2" [disabled]="isDisabledLang" required  name="language"  id="language" [(ngModel)]='language' style="width: 100%;">
                    <option value=""  >Select language</option>
                    <option *ngFor="let lang of lang_array;"  value="{{lang.language_name}}" >{{ lang.language_name}}</option>
                  </select>
                  <span *ngIf="isFormsubmitAccept && f.value.language=='' && au.audio_id==isID">
                    <span  style="color:red;" class="help-block">Please select language.</span>
                  </span>
                </div>
                <div class="form-group" *ngIf="i==0">
                  <label>Panel builder  <span style="color: red;">*</span></label>
                  <select class="form-control select2" required  name="isPanelBuilder"  id="isPanelBuilder" [(ngModel)]='isPanelBuilder' style="width: 100%;">
                    <option value="">Select Type</option>
                    <option value="valid_response">Valid Response</option>
                    <option value="call_success">Successful Call</option>
                    <option value="dont_send">Dont send</option>
                  </select>
                  <span *ngIf="isFormsubmitAccept && f.value.isPanelBuilder=='' && au.audio_id==isID">
                    <span  style="color:red;" class="help-block">Please select sms type.</span>
                  </span>
                </div>
                <!--<div class="form-group" *ngIf="i==0">
                  <label>Panel builder <span style="color: red;">*</span></label>
                  <div class="d-block">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio"  [(ngModel)]="isPanelBuilder" name="isPanelBuilder" id="inlineRadio2" value="valid_response">
                      <label class="form-check-label" for="inlineRadio2">Valid Response</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" [(ngModel)]="isPanelBuilder" name="isPanelBuilder" checked="checked" id="inlineRadio1" value="call_success">
                      <label class="form-check-label" for="inlineRadio1">Successful Call</label>
                    </div>
                  </div>
                </div>-->
                <div class="pt-5" style="margin-top: 40px;" *ngIf="i==1"></div>
                <div class="text-left">
                  <button class="btn btn-md btn-success px-5" type="submit" >Approve</button>
                </div> 
                <div class="pt-2 mt-1"></div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-4 col-md-4 col-lg-4" *ngIf="au.moderator_status=='0'">
          <div class="card card-primary h-100">
            <div class="card-header with-border">
              <h3 class="card-title">New Version</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form method="POST"  #c="ngForm" (ngSubmit)="formSubmitNewFile(c,au.audio_id,survey_id)">
              <div class="card-body">
                <div class="alert alert-danger" *ngIf="mp3_error && isID==au.audio_id">Error : {{ mp3_error }}</div>
                <div class="form-group">
                  <label for="exampleInputFile">Upload New Version <span style="color: red;">*</span></label>
                  <div class="input-group">
                    <div class="custom-file">
                      <!-- <input type="file" class="custom-file-input" id="exampleInputFile"> -->
                      <input type="file"  (change)="onFileSelect($event)"  ngModel class="form-control" name="filereplace" id="exampleInputFile_{{au.audio_id}}">
                      <label class="custom-file-label" for="exampleInputFile_{{au.audio_id}}">Upload File</label>
                    </div>
                  </div>
                  <span *ngIf="isFormsubmitNew && c.value.filereplace=='' && au.audio_id==isID ">
                    <span  style="color:red;" class="help-block">Please select file.</span>
                  </span>
                </div>
                <div class="form-group">
                  <label>Notes <span style="color: red;">*</span></label>
                  <textarea class="form-control" ngModel name="frm_note" id="note" required rows="3" placeholder="Enter ..."></textarea>
                  <span *ngIf="isFormsubmitNew && c.value.frm_note=='' && au.audio_id==isID">
                    <span  style="color:red;" class="help-block">Please enter note.</span>
                  </span>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" ngModel name="ismorethan" type="checkbox"  value="yes" id="flexCheckChecked_{{au.audio_id}}" >
                  <label class="form-check-label" for="flexCheckChecked_{{au.audio_id}}">
                    More than 40 second audio file
                  </label>
                </div>
                <div class="form-check mb-2" *ngIf="i==0">
                  <input class="form-check-input" ngModel name="is_custom_intro_upload" type="checkbox"  value="yes" id="iscusome_{{au.audio_id}}" >
                  <label class="form-check-label" for="iscusome_{{au.audio_id}}">
                    Custom Intro Preamble Audio
                  </label>
                </div>
                <div class="li_audio" *ngIf="custom_intro_preamble">
                  <h6>Custom Intro Preamble Audio</h6>
                  <audio controls  style="width: 80%;">
                    <source src="{{custom_intro_preamble}}" type="audio/ogg">
                    <!-- <source src="" type="audio/mpeg"> -->
                    Your browser does not support the audio tag.
                  </audio>
                  <div class="delete-btn m-1" *ngIf="custom_intro_preamble">
                    <button class="btn btn-md btn-danger " (click)="csvpreamble(c)" type="button"><i class="fa fa-times" aria-hidden="true"></i></button>
                  </div>  
                  </div>
                
                <div class="text-left">
                  <button class="btn btn-md btn-info px-5" type="submit">New Version</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-4 col-md-4 col-lg-4" *ngIf="au.moderator_status=='0'">
          <div class="card card-primary h-100">
            <div class="card-header with-border">
              <h3 class="card-title">Rejected</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form method="POST"  #rej="ngForm" (ngSubmit)="FormsubmitReject(rej,au.audio_id)">
              <div class="card-body">
                <div class="alert alert-danger"  *ngIf="third_error && isID==au.audio_id">Error : {{ third_error }}</div>
                <div class="form-group">
                  <label>Reason for Rejection <span style="color: red;">*</span></label>
                  <select class="form-control select2" required style="width: 100%;" ngModel name="reject_reason" id="reject_reason"  >
                    <option  selected="selected" value="" >Select Reason</option>
                    <option value="Inappropriate language">Inappropriate language</option>
                    <option value="Wrong language for the selected geography">Wrong language for the selected geography</option>
                    <option value="Unintelligible audio">Unintelligible audio</option>
                  </select>
                  <span *ngIf="isFormsubmitReject && rej.value.reject_reason=='' && au.audio_id==isID">
                    <span  style="color:red;" class="help-block">Please select reason.</span>
                  </span>
                  </div>
                  <div class="form-group">
                    <label>Rejection Notes <span style="color: red;">*</span></label>
                    <textarea class="form-control"  required ngModel name="reject_note" id="reject_note" rows="3" placeholder="Enter ..."></textarea>
                    <span *ngIf="isFormsubmitReject && rej.value.reject_note=='' && au.audio_id==isID">
                      <span  style="color:red;" class="help-block">Please enter rejection note.</span>
                    </span>
                  </div>
                  <div style="margin-top:48px;" *ngIf="i==0"></div>
                  <div style="margin-top:47px;" *ngIf="i==1"></div>
                  <div class="text-left mb-3">
                    <button class="btn btn-md btn-danger px-5" type="submit">Reject</button>
                  </div>
              </div>
            </form>
          </div>
        </div>
          </div>
        </div>
        <div class="card-body" *ngIf="panel_builder_survey_type=='sms'">
          <div class="col-12 col-md-12 col-lg-12 mb-3">
            <div class="que-main-wraper">
              <p>SMS Text :</p>
              <p style="white-space: pre-wrap;">{{ sms_text }}</p>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 mt-3 mb-3" *ngIf="au.moderator_status=='0'">
            <div class="text-left">
              <button class="btn btn-md btn-success px-5 mr-2" data-toggle="modal" data-target="#modal-default" type="submit" >Approve</button>
              <button class="btn btn-md btn-danger px-5" data-toggle="modal" data-target="#modal-reject" type="submit" >Reject</button>
            </div>
          </div>
        </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->


      <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
        <h4>Approve</h4>
        <form method="POST" #f="ngForm" (ngSubmit)="moderatorFormsubmit(f,au.audio_id)">
         
        </form>
      </div> -->
      <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
        <h4>New Version</h4>
        <form method="POST"  #c="ngForm" (ngSubmit)="formSubmitNewFile(c,au.audio_id,survey_id)">
         
       </form>
      </div> -->
      <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
        <h4>Rejected</h4>
        <form method="POST"  #rej="ngForm" (ngSubmit)="FormsubmitReject(rej,au.audio_id)">

        </form>
      </div> -->
  
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->


 <div class="modal fade approve_modal" id="modal-default">
  <app-loader *ngIf="isLoaderApprove"></app-loader>
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" #closebuttonapprove class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <div class="modal-body">
          <i class="fa fa-check"></i>
          <h4 class="modal-title">Approve</h4>
          <p>Are you sure you want to sent direct sms to users?</p>
          <button type="button" class="btn btn-primary mr-2" (click)="approveData();">Approve</button>
          <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade reject_modal" id="modal-reject">
    <app-loader *ngIf="isLoaderReject"></app-loader>
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" #closebuttonreject class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <div class="modal-body">
          <i class="fa fa-times"></i>
          <h4 class="modal-title">Reject</h4>
          <p>Are you sure you want to reject this survey?</p>
          <button type="button" class="btn btn-primary mr-2" (click)="rejectSurvey();">Reject</button>
          <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->