import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/service/survey.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-survey-text',
    templateUrl: './survey-text.component.html',
    styleUrls: ['./survey-text.component.css']
  })
export class SurveyTextComponent implements OnInit {
    
  isLoader =true;
  token ="";
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  user_id :number;
  surveyArray = [];
  constructor(private surveysrv : SurveyService,private router :Router ) { 

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
  }
  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [6],
        orderable :false
      }]
    };
    
    this.surveysrv.listText(this.token,this.user_id,'2').pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyArray = survey.response_data.arr_survey;
        this.dtTrigger.next();
      }   
    });
  }
  redirectText(id)
  {
    this.router.navigate(['survey/'+id+'/edit']);
    return true;
  }
}
