import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../service/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  Loginfrm : FormGroup;
  isLoginSubmit =false;
  errorMessage = '';
  isLoader = false;
  mobnumPattern ='^((\\+91-?)|0)?[0-9]{10}$';

  constructor(private formBuilder : FormBuilder,private routerLink : Router,private login:LoginService) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
     body.classList.add('login-page');
     this.Loginfrm= this.formBuilder.group({
       euserData : ['',[Validators.required,Validators.pattern(this.mobnumPattern)]],
     });

     const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    if(userData)
    {
      this.routerLink.navigate(['/dashboard']);
      return;
    }
  }
  get f(){ return this.Loginfrm.controls;}
  /*form login submit*/
  frmLogin()
  {
    this.errorMessage ='';
    this.isLoginSubmit=true;
    if(this.Loginfrm.invalid)
    {
      return false;
    }
    this.isLoader=true;
    this.login.sendOtp(this.Loginfrm.value).subscribe(res=>{
      if(res['status']=='error')
      {
        this.errorMessage = res['msg'];
      }
      this.isLoader=false;
    });
    
  }
  


}
