import { Component, OnInit,NgModule } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add-sample',
  templateUrl: './add-sample.component.html',
  styleUrls: ['./add-sample.component.css']
})
export class AddSampleComponent implements OnInit {
  addSample : FormGroup;
  token ='';
  isLoader=false;
  isFormsubmit=false;
  successMessage='';
  errorMessage='';
  mobnumPattern ='^((\\+91-?)|0)?[0-9]{10}$';
  constructor(private route : ActivatedRoute,private listser : UserService,private formBuilder:FormBuilder,private router : Router) { }

  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.addSample= this.formBuilder.group({
          first_name : ['',[Validators.required]],
          mobile_no:['',[Validators.required,Validators.pattern(this.mobnumPattern)]],
       });
  }
  get f(){ return this.addSample.controls;}

  sampleFormsubmit()
  {
    this.isFormsubmit=true;
    if(this.addSample.invalid)
    {
      return false;
    }
    this.isLoader=true;
    this.successMessage='';
    this.errorMessage = '';
    const userData ={
      'first_name':this.addSample.value.first_name,
      'mobile_no':this.addSample.value.mobile_no,
    }
    console.log(userData);
    this.listser.userAddSample(userData,this.token).subscribe(res =>{
        if(res.status=='success')
        {
          this.successMessage=res.msg;
          this.addSample.reset();
          this.isFormsubmit=false;
        }
        else
        {
          this.errorMessage=res.msg;
        }
        this.isLoader=false;
    });
  }

}
