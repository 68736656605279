import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StepsService } from 'src/app/service/steps.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-step',
  templateUrl: './edit-step.component.html',
  styleUrls: ['./edit-step.component.css']
})
export class EditStepComponent implements OnInit {
  questionsForm      : FormGroup ;
  langArray = [];
  refQuestionArray  = [];
  questionTypeArray  = [];
  questionDetails: any = [];
  optionsArray  = [];
  surveyId   : any = "";
  questionId : any = "";
  surveyName : any = "";
  questionNo : any = "";
  isLoader = true;
  token = "";
  user_id :number;
  errorMessage = '';
  successMessage='';
  selectedQuestion: boolean = false;
  selectedOptionType : boolean = false;
  constructor(
      private fb       : FormBuilder,
      private stepServ : StepsService,  
      private router   : Router,
      private route    : ActivatedRoute
  ) {
      this.questionsForm = this.fb.group({
          lang_id: ['', Validators.required],
          txt_question: ['', Validators.required],
          ref_id: [''],
          option_type: ['', Validators.required],
          question_type: ['', Validators.required],
          qoptions: this.fb.array([]),
      });
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } = JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
    this.surveyName =  localStorage.getItem('survey_name');

   }

  ngOnInit(): void {
    this.route.params.subscribe((param :  Params)=> {
      console.log("Params => ",param )
      this.surveyId  = atob(param['id']);
      this.questionId  = atob(param['qid']);
    });
    this.getQuestionData();
  }
  addRows() {
    let ctrl = <FormArray>this.questionsForm.controls.qoptions;
    ctrl.push(
      this.fb.group({
        name: ['', Validators.required],
        preference: [''],
      })
    );
  }
  removeAddressAt(i) {
      let ctrl = <FormArray>this.questionsForm.controls.qoptions;
      ctrl.removeAt(i);
  }
  getQuestionData(){
    this.errorMessage   = "";
    this.successMessage = ""; 
    this.stepServ.getQuestion(this.token,this.questionId).pipe(first()).subscribe((data: any) => { 
        this.isLoader = false;       
        if(data.status == 'success'){ 
            this.langArray  = data.response_data.language;
            this.questionTypeArray = data.response_data.question_type;
            this.refQuestionArray  = data.response_data.reference_questions;
            this.optionsArray      = data.response_data.options;
            this.questionDetails   =  data.response_data.question;
            if(this.questionDetails){
              this.questionsForm.controls.lang_id.setValue(this.questionDetails.language_id);
              this.questionsForm.controls.txt_question.setValue(this.questionDetails.question_text);
              this.questionsForm.controls.ref_id.setValue((this.questionDetails.parent_question_id == 0) ? '' : this.questionDetails.parent_question_id);
              this.questionsForm.controls.question_type.setValue(this.questionDetails.question_type);
              this.questionNo =  this.questionDetails.placeholder_name;
              if(this.questionDetails.question_type == 3){
                  this.selectedQuestion = false;
              }else{
                  this.selectedQuestion   = true;
                  this.selectedOptionType = (this.questionDetails.option_type == 0) ? true : false;
                  this.questionsForm.controls.option_type.setValue(this.questionDetails.option_type);
                  for (let val of this.optionsArray) {              
                      let ctrl = <FormArray>this.questionsForm.controls.qoptions;
                      ctrl.push(
                        this.fb.group({
                          name: [val.option_text, Validators.required],
                          preference: [(val.option_preference == 1) ? true : false ]
                        })
                      );      
                  }
              }
            }
        }else{
          this.errorMessage = data.msg;
        }
      },(error) => {
          this.isLoader = false;
          this.errorMessage = "Something Went Wrong, Please Try Again";
      }
    );
    
  }
  checkQuestionType(id:any){
    if(id == 3){
      this.selectedQuestion = false;
      this.questionsForm.controls.option_type.clearValidators(); // 6. Clear All Validators
      this.questionsForm.controls.option_type.updateValueAndValidity();
      this.questionsForm.controls.option_type.setValue('');
      let ctrl = <FormArray>this.questionsForm.controls.qoptions;
      ctrl.clear();
    }else{
      this.selectedQuestion = true;
      this.questionsForm.controls.option_type.setValidators([Validators.required]);
      this.questionsForm.controls.option_type.updateValueAndValidity();
    }
  }
  getQuestions(id: any){
    if(id){
      this.isLoader = true;
      this.errorMessage   = "";
      this.successMessage = ""; 
      this.stepServ.getQuestions(this.token,this.surveyId,id,this.questionId).pipe(first()).subscribe((data: any) => { 
        this.isLoader = false;
        if(data.status == 'success'){ 
            this.refQuestionArray  = data.response_data;
        }else{
            this.errorMessage = data.msg;
            this.refQuestionArray  = [];
        }
        },(error) => {
            this.isLoader = false;
            this.errorMessage = "Something Went Wrong, Please Try Again";
        }
      );
    }
  }
  onSubmit(values: any){
    if(this.questionsForm.valid){
      this.isLoader = true;
      values['question_id'] = this.questionId;
      values['survey_id'] = this.surveyId;
      values['user_id']   = this.user_id;
      if(values.qoptions.length > 0){
        for (let val of values.qoptions) {              
              val['preference']  = (val.preference == true) ? '1' : '0';       
        }
      }
      
      this.errorMessage   = '';
      this.successMessage = '';
      this.stepServ.addStep(this.token,values).subscribe((res: any) => { 
        this.isLoader = false;
        this.scrollPage();
        if(res.status=='success'){
            this.successMessage = res.msg;            
        }else{
            this.errorMessage   = res.msg;
        }
        },(error) => {
            this.isLoader = false;
            this.errorMessage = "Something Went Wrong, Please Try Again";
        }
      );
    }
  }
  handleChangeOptionType(value: any){
     let ctrl = <FormArray>this.questionsForm.controls.qoptions;
     console.log("length =>",ctrl.length);
      if(value == 0){
        this.selectedOptionType = true;  
        this.addRows();     
      }else{  
        this.selectedOptionType = false;
        ctrl.clear();
      }
  }
  scrollPage(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
