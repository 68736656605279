import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  siteUrl = environment.apiUrl;
  constructor(private http:HttpClient,private router: Router) { }

  listSurvey(token,userId,status)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/survey_list',{'status':status,'moderator_id':'','token':token},{headers});
  }
  listText(token,userId,status)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/text_file_survey_list',{'status':status,'moderator_id':'','token':token},{headers});
  }
  listApproved(token,userId,status)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/survey_list',{'status':status,'moderator_id':userId,'token':token},{headers});
  }
  detailSurvey(token,survey_id,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/survey_detail',{'survey_id':survey_id,'user_id':user_id},{headers});
  }
  trackSurvey(token,survey_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/get_survey_flow',{'survey_id':survey_id},{headers});
  }
  

  moderatorAction(token,data)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/survey_accept_reject',data,{headers});
  }
  moderatorNewfile(token,data)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/update_audio_file',data,{headers});
  }
  createRequest(token,sentRequest)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/ivr_request_report',sentRequest,{headers});
  }
  listBuilder(token,sentRequest)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/panel_builder_report',sentRequest,{headers});
  }
  resentMessage(token,sentmessage)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/panel_builder_resend_sms',sentmessage,{headers});
  }
  listLog(token,datapass)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.get<any>(this.siteUrl+'api/v1/admin/survey_log_list',{headers});
  }
  audioDetails(token,AudioID,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/get_audio_details',{'audio_id':AudioID,'user_id':user_id},{headers});
  }
  addRandom(token,formArray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/create_random_audios',formArray,{headers}); 
  }
  deleteRandom(token,AudioID)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    console.log('DELETE random URL',this.siteUrl+'api/v1/admin/delete_random_variations',AudioID,{headers})

    return this.http.post<any>(this.siteUrl+'api/v1/admin/delete_random_variations',AudioID,{headers}); 
  }
  uploadCsv(token,formData){
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/upload_test_csv',formData,{headers});
  }
  deleteCsv(token,formData){
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/delete_test_csv',{'survey_id':formData},{headers});
  }
  deletepreamble(token,formData){
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/delete_preamble_audio',{'survey_id':formData},{headers});
  }

  downloadserveyreport(formdata){
    return this.http.post<any>(this.siteUrl+'api/v1/admin/download_survey_report',formdata);
  }
  downloadcsv(fileurl,userID)
  {
    return this.siteUrl+'api/v1/admin/download_survey_csv?csv_url='+fileurl+'&user_id='+userID;
  }

  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
