 
 <style>
   .action-links a{font-size:22px;}
   .edit-link{color:#000}
   .active-status-link{color:green}
   .block-status-link{color:red;}
 </style>
 <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>DataTables</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">DataTables</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">User List</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="table-responsive">
                <table id="example1" class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                  <thead>
                  <tr>
                    <th>Sr.No</th> 
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>User Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr  *ngFor="let user of userListArray; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.email  }}</td>
                    <td>{{ user.mobile_no  }}</td>
                    <td>{{ user.admin_type | titlecase}}</td>
                    <td>
                      <span *ngIf="user.status==='1'" title="Active"><span class="badge badge-success"  >Active</span></span>
                      <span *ngIf="user.status==='0'" title="Block"><span class="badge badge-danger">Block</span></span>
                    </td>
                    <td class="action-links">
                      <a routerLink="/user/{{user.id}}/edit/" class="edit-link" title="Edit user"><i class="far fa-edit"></i></a>
                      <a class="active-status-link" *ngIf="user.status==='1'" href="javascript:void(0);" title="User active" (click)="changeStatus(user.id,user.status)" style="margin-left: 10px;"><i class="fas fa-toggle-on"></i></a>
                      <a class="block-status-link" *ngIf="user.status==='0'" href="javascript:void(0);" title="User block" (click)="changeStatus(user.id,user.status)" style="margin-left: 10px;"><i class="fas fa-toggle-off"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="userListArray.length==0">
                    <td colspan="7">No Record Found</td>
                  </tr>
                  </tbody>
                 
                </table>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->