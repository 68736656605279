<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>

              <div class="card-header">
                <h3 class="card-title" *ngIf="!editForm">Add User</h3>
                <h3 class="card-title" *ngIf="editForm">Edit User</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form [formGroup]="Userfrm" (ngSubmit)="userFormsubmit();" >
                <div class="card-body">
                   <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                  <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                  <div class="form-group">
                    <label for="name">Name <span style="color: red;">*</span></label>
                    <input type="text" formControlName="first_name" class="form-control" id="name" placeholder="Enter Name">
                    <span *ngIf="isFormsubmit && f.first_name.errors">
                      <span *ngIf="f.first_name.errors.required" style="color:red;" class="help-block">Please enter name.</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email <span style="color: red;">*</span></label>
                    <input type="email" formControlName="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
                    <span *ngIf="isFormsubmit && f.email.errors">
                      <span *ngIf="f.email.errors.required" style="color:red;" class="help-block">Please enter email id.</span>
                      <span *ngIf="f.email.errors.email" style="color:red;" class="help-block">Please enter valid  email id.</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="mobile_no">Mobile No <span style="color: red;">*</span></label>
                    <input type="text" maxlength="10" formControlName="mobile_no" class="form-control" id="mobile_no" placeholder="Mobile number">
                    <span *ngIf="isFormsubmit && f.mobile_no.errors">
                      <span *ngIf="f.mobile_no.errors.required" style="color:red;" class="help-block">Please enter mobile no.</span>
                      <span style='color:red;' *ngIf="f.mobile_no.errors.pattern" >Please enter valid mobile number</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="address">Address <span style="color: red;">*</span></label>
                    <textarea class="form-control" formControlName="address" name="address" placeholder="Address"></textarea>
                    <span *ngIf="isFormsubmit && f.address.errors">
                      <span *ngIf="f.address.errors.required" style="color:red;" class="help-block">Please enter address.</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="state_name">Role <span style="color: red;">*</span></label>
                    <select class="form-control" formControlName="role">
                        <option value="">Select Role</option>
                        <option *ngFor="let roles of role_array" value="{{roles.id}}">{{ roles.name  | titlecase }}</option>
                    </select>  
                    <span *ngIf="isFormsubmit && f.role.errors">
                      <span *ngIf="f.role.errors.required" style="color:red;" class="help-block">Please select role.</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="state_name">State </label>
                    <select class="form-control" #state_id formControlName="state_id"  (change)="onChangeState($event.target.value)">
                        <option value="">Select State</option>
                        <option *ngFor="let state of state_array" value="{{state.state_id}}">{{ state.name }}</option>
                    </select>  
                  </div>
                  <div class="form-group" style="display: none;">
                    <label for="state_name">Parliament Constituency </label>
                    <select class="form-control" formControlName="pc_id" (change)="onChangePc(state_id.value,$event.target.value)">
                      <option value="">Select Parliament Constituency</option>
                      <option *ngFor="let pc of pc_array" value="{{pc.pc_no}}">{{ pc.pc_name }}</option>
                    </select>  
                  </div>
                  <div class="form-group" style="display: none;">
                    <label for="state_name">Assembly Constituency</label>
                    <select class="form-control" formControlName="ac_id">
                      <option value="">Select assembly constituency</option>
                      <option *ngFor="let ac of ac_array" value="{{ac.ac_id}}">{{ ac.ac_name }}</option>
                    </select>  
                  </div>
                  <div class="form-group">
                    <label for="state_name">Language <span style="color: red;">*</span></label>
                    <br/>
                    <div class="form-check form-check-inline"  *ngFor="let lng of lang_array;let i = index">
                      <input class="form-check-input" [checked]="langaugeChecked(lng.language_id)"  type="checkbox" id="inlineCheckbox{{ i }}"  [value]="lng.language_id" (change)="onCheckboxChange($event)" >
                      <label class="form-check-label" for="inlineCheckbox{{ i }}">{{ lng.language_name }}</label>
                    </div>
                    <br/>
                    <span *ngIf="isFormsubmit && f.langauge.errors">
                      <span *ngIf="f.langauge.errors.required" style="color:red;" class="help-block">Please select language.</span>
                    </span>
                  </div>
                </div>
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>