<div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Dashboard v2</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Dashboard v2</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Info boxes -->
          <!--<div class="row">
            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">CPU Traffic</span>
                  <span class="info-box-number">
                    10
                    <small>%</small>
                  </span>
                </div>
                
              </div>
              
            </div>
           
            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Likes</span>
                  <span class="info-box-number">41,410</span>
                </div>
               
              </div>
              
            </div>
            
  
            
            <div class="clearfix hidden-md-up"></div>
  
            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Sales</span>
                  <span class="info-box-number">760</span>
                </div>
               
              </div>
             
            </div>
            
            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">New Members</span>
                  <span class="info-box-number">2,000</span>
                </div>
                
              </div>
              
            </div>
         
          </div>
       /.row -->
          <!--<div class="row">
          <div class="col-12 col-sm-6 col-md-3">
          <table id="example1" class="table responsive table-bordered table-striped">
            <thead>
            <tr>
              <th colspan="3">Option 1</th> 
              <th colspan="3">Option 2</th>
              <th colspan="3">Option 3</th>
            </tr>
            </thead>
            <tbody>
            <tr style="cursor: pointer;">
              <td colspan="3">
                <tr rowspan='2'> 100 </tr>
                <tr>
                  <td>10</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
              </td>
              <td colspan="3">
                100
                <tr> 
                  <td>10</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
              </td>
              <td colspan="3">
                100
                <tr>
                  <td>10</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
             </td>
            </tr>
            </tbody>
           
          </table>
        </div>
        </div>-->
          <!-- /.row -->
          <!-- Main row -->
         <!-- /.row -->
         <div class="col-12">
          <div id="waveform"></div>
          <div id="wave-timeline"></div>
          <!--<div class="col-10">
            <button (click)="playAudio()">Play Button</button>
            <button (click)="addReligion()">Add Wave</button>
            <button (click)="stopAudio()">Stop Button</button>
          </div>-->
         </div> 
        </div><!--/. container-fluid -->
        <div class="row" style="margin-top: 10;" *ngIf="resultArray.length>0">
          <div class="col-12 col-sm-7 col-md-7">
            <table class="table responsive table-bordered table-striped">
              <thead>
              <tr>
                <th>Title</th> 
                <th>Start Time -End Time</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
               <tr *ngFor="let res of resultArray; let i = index">
                <td>
                  <select class="form-control">
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    <option value="4">Option 4</option>
                  </select>
                </td> 
                <td>{{ res.start | number: '1.2-2' }} - {{ res.end | number: '1.2-2' }}</td>
                <td><a href="javascript:void(0);" (click)="playAudioReligion(i);">Play</a> / <a href="javascript:void(0);" (click)="stopAudio();">Stop</a></td>
               </tr>
              </tbody>
            </table>
          </div>
          <div class="col-10">
            <button (click)="removeData();">Clear All File</button>
          
          </div>
         </div>
      </section>
      <!-- /.content -->
   
</div>
   
