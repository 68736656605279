import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthModule } from '../auth/auth/auth.module';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  siteUrl = environment.apiUrl;
  constructor(private http:HttpClient,private router: Router) { }
  addAudio(token,formData)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/add_custom_audio',formData,{headers});
  }
  audioList(token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/added_custom_audio_list',{'status':1},{headers});
  }
  statusChange(token,audioArray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/custom_audio_block_unbock',audioArray,{headers});
  }
  getaudioDetails(token,audioId)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/custom_audio_details',{'custom_audio_id':audioId},{headers});
  }
  updateAudio(token,updateArray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/update_custom_audio',updateArray,{headers});
  }
  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
