<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
           
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="card-header">
                <h3 class="card-title">Data Extraction</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form [formGroup]="Datafrm" (ngSubmit)="DataextractFormsubmit();">
                <div class="card-body">
                    <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                    <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">State <span>*</span></label>
                        <div class="col-sm-8">
                            <ng-select [multiple]="false" formControlName="state_data"  placeholder="Select State" (change)="selectState($event)">
                              <ng-option *ngFor="let st of state_list" [value]="st.state_id" >{{st.name | titlecase }}</ng-option>
                            </ng-select>  
                            <p style="margin:0;height:15px;display:block;"><span *ngIf="isFormsubmit && f.state_data.errors">
                              <span *ngIf="f.state_data.errors.required" style="color:red;" class="help-block">Please select state.</span>
                            </span></p>
                        </div>
                    </div>
                    <div *ngIf="isShow">
                      <div class="hr-line extra-line"></div>    
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label">District</label>
                          <div class="col-sm-8">
                            <ng-select [multiple]="true" formControlName="district_data"   placeholder="Select District" (change)="getDitrict($event)"  >
                              <ng-option *ngFor="let dt of district_list" [value]="dt.district_no" >{{dt.district_name | titlecase }}</ng-option>
                            </ng-select>  
                          </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label">Parliamentary Constituency</label>
                          <div class="col-sm-8">
                                  <ng-select   [multiple]="true" formControlName="pc_data" placeholder="Select Parliamentary Constituency"  (change)="getPc($event)" >
                                    <ng-option *ngFor="let pc of pc_list" [value]="pc.pc_no" >{{pc.pc_name | titlecase }}</ng-option>
                                  </ng-select>  
                          </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label">Assembly Constituency</label>
                          <div class="col-sm-8">
                            <ng-select [multiple]="true" (change)="getPincode($event)" placeholder="Select Assembly Constituency" formControlName="ac_data"  >
                              <ng-option *ngFor="let ac of ac_list" [value]="ac.ac_no" >{{ac.ac_name | titlecase }}</ng-option>
                            </ng-select>  
                          </div>
                      </div>
                      <div class="form-group row" *ngIf="isPincode" >
                          <label class="col-sm-4 col-form-label">Pincode</label>
                          <div class="col-sm-8">
                            <ng-select [multiple]="true" placeholder="Select Pincode" formControlName="pincode_data"  >
                              <ng-option *ngFor="let pin of pincod_list" [value]="pin" >{{pin}}</ng-option>
                            </ng-select>  
                          </div>
                      </div>
                    </div>
                    <div *ngIf="hideDiv">
                      <div class="hr-line"><p>OR Upload List</p></div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Type</label>
                        <div class="col-sm-8">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" formControlName="radio_type" (change)="clickCheck($event)" type="radio" name="radio_type" id="inlineRadio1" value="district_no">
                            <label class="form-check-label" for="inlineRadio1">District</label>
                          </div>
                          <div class="form-check form-check-inline" >
                            <input class="form-check-input" formControlName="radio_type" (change)="clickCheck($event)" type="radio" name="radio_type" id="inlineRadio3" value="ac_no">
                            <label class="form-check-label" for="inlineRadio3">Assembly Constituency</label>
                          </div>

                          <div class="form-check form-check-inline" style="margin-top: 10px;;">
                            <input class="form-check-input" formControlName="radio_type" (change)="clickCheck($event)" type="radio" name="radio_type" id="inlineRadio2" value="pc_no">
                            <label class="form-check-label" for="inlineRadio2">Parliamentary Constituency</label>
                          </div>
                          <div class="form-check form-check-inline" *ngIf="isPincode">
                            <input class="form-check-input" formControlName="radio_type"  (change)="clickCheck($event)"type="radio" name="radio_type" id="inlineRadio4" value="pincode">
                            <label class="form-check-label" for="inlineRadio4">Pincode</label>
                          </div>
                        </div>
                     </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Upload</label>
                        <div class="col-sm-8">
                            <input type="file" class="form-control-file" (change)="fileChangeData($event);" id="exampleFormControlFile1">
                            <small class="form-text text-muted">Note: Upload file extension allowed: csv, xls </small>
                            <small class="form-text text-muted" *ngIf="state_url">Mapping download file <a download [href]="state_url">Click here</a> </small>
                            <p style="margin:0;height:15px;display:block;"><span *ngIf="upload_data">
                              <span style="color:red;" class="help-block">{{ upload_data }}</span>
                            </span></p>
                        </div>
                      </div>
                   </div>
                   <div class="hr-line extra-line"></div> 
                   <div class="form-group row">
                      <label class="col-sm-4 col-form-label">By Pass</label>
                      <div class="col-sm-8">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" formControlName="by_pass"  type="checkbox" id="by_pass_id" value="yes">
                          <label class="form-check-label" for="by_pass_id">Age & Gender</label>
                        </div>
                      </div>
                   </div>
                   <div class="form-group row" *ngIf="area_Type_check">
                      <label class="col-sm-4 col-form-label">Area Type</label>
                      <div class="col-sm-8">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" formControlName="area_type" (change)="onCheckboxarea($event)"  type="checkbox" id="inlinecheckbox_urban" value="urban">
                          <label class="form-check-label" for="inlinecheckbox_urban">Urban</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" formControlName="area_type" (change)="onCheckboxarea($event)"  type="checkbox" id="inlinecheckbox_rural" value="rural">
                          <label class="form-check-label" for="inlinecheckbox_rural">Rural</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" formControlName="area_type" (change)="onCheckboxarea($event)"  type="checkbox" id="inlinecheckbox_semi_urban" value="semi-urban">
                          <label class="form-check-label" for="inlinecheckbox_semi_urban">Semi Urban</label>
                        </div>
                      </div>
                   </div>
                   <div class="hr-line extra-line"></div> 
                   <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Total Records</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" (keypress)="numberOnly($event)" (change)="sampleRecords($event)" formControlName="sample_size" placeholder="Total Records">
                          <p style="margin:0;height:15px;display:block;"><span *ngIf="isFormsubmit && f.sample_size.errors">
                            <span *ngIf="f.sample_size.errors.required" style="color:red;" class="help-block">Please enter sample size.</span>
                          </span></p>
                        </div>
                   </div>
                   <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Per Unit</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" (keypress)="numberOnly($event)" (change)="perRecords($event)"  formControlName="per_record" placeholder="Per Unit">
                        <p style="margin:0;height:15px;display:block;"><span *ngIf="req_sample">
                          <span style="color:red;" class="help-block">{{ req_sample }}</span>
                        </span></p>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Details Option</label>
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="detailed_option"  type="checkbox" id="inlinecheckbox4" value="yes">
                        <label class="form-check-label" for="inlinecheckbox4">All details</label>
                      </div>
                      <div class="csv-note">Note : if you not marked on select all details filed then you will receive only mobile numbers in csv file.</div>
                    </div>
                  </div>
                 </div>
                <!--card-body-->
                <div class="card-footer">
                    <div class="form-group row m-0">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <button type="submit" class="btn btn-primary">Submit</button>
                            <button type="button" class="btn btn-secondary ml-2" (click)="resetButton();">Cancel</button>
                        </div>
                    </div>
                </div>
              </form>
            </div>
            <div class="card card-primary" *ngIf="csv_file_url">
              <div class="card-header">
                <h3 class="card-title">Download file</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-sm-8">
                    <a [href]="csv_file_url" target="_new" class="btn">Download</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>