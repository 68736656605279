import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-credit-debite',
  templateUrl: './credit-debite.component.html',
  styleUrls: ['./credit-debite.component.css']
})
export class CreditDebiteComponent implements OnInit {

  Customerfrm : FormGroup;
  isFormsubmit=false;
  isLoader=false;
  token ='';
  errorMessage ='';
  successMessage ='';
  customerId : number;
  editForm:boolean;
  credit_price :number;
  current_balance : number;
  user_name ='';
  tax_percentage:number;
  user_mobile = '';
  resData =[];
  final_price :number;
  amountCalFinal : number;
  taxAmount:number;
  imageUrl = '';;
  tax_note ='';
  
  constructor(private formBuilder:FormBuilder,private route : ActivatedRoute,private cust : CustomerService) { }

  ngOnInit(): void {

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.isFormsubmit=false;
     /*form validation*/
     this.Customerfrm= this.formBuilder.group({
        customer_credit : ['',[Validators.required]],
        shortdesc : ['',[Validators.required]],
        comment_payment :[''],
      });
    /*form validation*/
    this.isLoader=true;
    this.route.params.subscribe((param :  Params)=>{
      this.customerId = +param['id']
      this.editForm=param['id'] !=null;
      this.userInfo();
    }); 
  }
  get f(){ return this.Customerfrm.controls;}
  userInfo()
  {
     this.cust.customerDetails(this.token,this.customerId).subscribe(custRes =>{
        this.isLoader=false;
        if(custRes['status']=='success')
        {
            this.resData = custRes['response_data']; 
            this.credit_price = custRes['response_data']['credit_price'];
            this.current_balance = custRes['response_data']['current_balance']/custRes['response_data']['credit_price'];;
            this.user_name = custRes['response_data']['name'];
            this.tax_percentage = custRes['response_data']['tax_percentage'];
            this.user_mobile = custRes['response_data']['user_mobile'];
            this.tax_note = custRes['response_data']['tax_note'];
        }
        else
        {
            this.errorMessage =custRes['msg'];
        }
     })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  PriceCalculation(event:any)
  {
    const amountCal = event.target.value*this.credit_price;
    const totalAmountper = (this.tax_percentage/100)*amountCal;
    this.final_price = totalAmountper+amountCal;
    this.amountCalFinal = amountCal; 
    this.taxAmount = totalAmountper;
  }
  CustomerFormdebitsubmit()
  {
   
    if(this.Customerfrm.valid)
    {
        this.isLoader=true;
        this.successMessage ='';
        this.errorMessage ='';
        const formData: FormData = new FormData();
        formData.append('front_user_id',this.customerId.toString());
        formData.append('debite_credits',this.Customerfrm.value.customer_credit);
        formData.append('comment_payment',this.Customerfrm.value.comment_payment);
        formData.append('shortdesc',this.Customerfrm.value.shortdesc);
       
        this.cust.customerDebite(this.token,formData).subscribe(resData=>{
              if(resData['status']=='success')
              {
                this.successMessage = resData['msg'];
                this.ngOnInit();
              }
              else
              {
                this.errorMessage =resData['msg'];
              }
              this.isLoader=false;
        });
        //console.log(get_array);
    }
    else
    {
       this.isFormsubmit=true;
    }
  }
}
