<div class="login-box">
    <div class="login-logo">
      <a routerLink="/"><img src="assets/logo.png" width="100%;"></a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <app-loader *ngIf="isLoader"></app-loader>
        <p class="login-box-msg">Verify OTP</p>
        <form  method="post" [formGroup]="Verifyfrm" (ngSubmit)="frmVerify();">
          <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
          <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
          <div class="input-group mb-3">
            <input type="text" maxlength="10"  formControlName="euserData" class="form-control" placeholder="Enter Mobile Number">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-phone"></span>
              </div>
            </div>
          </div>
          <span *ngIf="isformSubmit && f.euserData.errors">
            <span *ngIf="f.euserData.errors.required" style="color:red;" class="help-block">Please enter mobile number.</span>
          </span>
          <div class="input-group mb-3" >
            <input type="text" class="form-control" maxlength="6" formControlName="euserVerifyOtp" placeholder="OTP">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <span *ngIf="isformSubmit && f.euserVerifyOtp.errors">
            <span *ngIf="f.euserVerifyOtp.errors.required" style="color:red;" class="help-block">Please enter otp.</span>
          </span>
          <div class="row">
            <div class="col-8"><a routerLink="/">Back to Login</a></div>
            <!--/.col-->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">Sign In</button>
            </div>
            
            <!-- /.col -->
          </div>
          <div class="row mt-4">
            <div class="col-2"></div>
            <div class="col-8 text-center">
             <strong class="d-inline-block border p-2"> 00:{{ timeLeft }}</strong>
              <button type="button"  (click)="resentOtp();" [disabled]='button_disable' class="btn btn-primary btn-block mt-2">Resend OTP</button>
            </div>
          </div>  
        </form>
       <!-- /.social-auth-links -->
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>