import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/service/customer.service';
import {map, startWith} from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  isLoader =false;
  errorMessage='';
  successMessage ='';
  customeListArray = [];
  token ='';
  user_mobile_no  ='';
  isDisable = true;
  normal_user_mobile='';
  myControl = new FormControl();
  keyword = 'full_name';
  filteredOptions: Observable<string[]>;
  data = [];

  constructor(private customer : CustomerService,private router : Router,private cookieSevice : CookieService) { }

  ngOnInit(): void {

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_mobile_no = userData.mobile_no;

    this.isLoader=true;
    const custSearch = {
      'search_key':''
    };
    this.customer.userCustomerList(custSearch,this.token).subscribe(customeres => {
      if(customeres['status']=='success')
      {
         this.data = customeres.response_data;
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }   
      this.isLoader=false;
    });
  }
  private filter(value: any) {
    const filterValue = value.toLowerCase();
    return this.data.filter(option => option.full_name.includes(filterValue) || option.user_mobile.includes(filterValue));
     
  }
  selectedDrop(event:any)
  {
  
    if(event)
    {
      this.normal_user_mobile = event;
      this.isDisable =false;
    }
    else
    {
      this.isDisable = true;
    }
  }
  userAdminLogin(user_type)
  {
    
    if(user_type!='')
    {
      let usermobile= ''
      if(user_type=='admin_user')
      {
        usermobile = this.user_mobile_no;
      }
      else
      {
        usermobile=this.normal_user_mobile;
      }
      this.isLoader=true;
      const sentarray  = {
        'user_type':user_type,
        'mobile_number': usermobile
      };
      this.customer.loginAdminUser(this.token,sentarray).subscribe(res =>{
          if(res['status']=='success')
          {
            localStorage.setItem('userPrashnamData','');
            localStorage.setItem('userPrashnamData',JSON.stringify(res.response_data));
            const dateNow = new Date();
            dateNow.setMinutes(dateNow.getMinutes() + 10);

            if(environment.apiUrl=='http://prashnam.datagrid.co.in/')
            {
             
              this.cookieSevice.set('userPrashnamData',JSON.stringify(res.response_data),dateNow,'/','app.datagrid.co.in');
              //this.router.navigate(['dashboard']);
              //window.location.href="http://app.datagrid.co.in/";
              window.location.href="http://app.datagrid.co.in/auto-login/"+encodeURIComponent(JSON.stringify(res.response_data));
            }
            else
            {
              this.cookieSevice.set('userPrashnamData',JSON.stringify(res.response_data),dateNow,'/','https://app.prashnam.ai/');
              window.location.href="https://app.prashnam.ai/auto-login/"+encodeURIComponent(JSON.stringify(res.response_data));
            }
          }
          this.isLoader=false;
      });
    }
  }
  onChangeSearch(event) {
    this.isLoader=false;
    const custSearch = {
      'search_key':event.target.value
    };
    this.customer.userCustomerList(custSearch,this.token).subscribe(customeres => {
      if(customeres['status']=='success')
      {
         this.data = customeres.response_data;
         this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filter(value))
         
        );
       
      }   
      this.isLoader=false;
    });
  }
  
  onFocused(e){
    // do something when input is focused
  }


}
