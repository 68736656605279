import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/service/survey.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-survey-approved',
  templateUrl: './survey-approved.component.html',
  styleUrls: ['./survey-approved.component.css']
})
export class SurveyApprovedComponent implements OnInit {
  Datefrm : FormGroup;
  isLoader =true;
  isFormsubmit = false;
  token ="";
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  user_id :number;
  surveyArray = [];
  err_msg='';
  // downloadBtn=[
  //   {name:'Client',value:'client'},
  //   {name:'State',value:'state'}
  // ]
  constructor( public datepipe: DatePipe,private surveysrv : SurveyService,private router :Router , private formBuilder:FormBuilder ) { 

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
  }
  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [6],
        orderable :false
      }]
      
      
    };
    
    this.surveysrv.listApproved(this.token,this.user_id,'1').pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyArray = survey.response_data.arr_survey;
        
        this.dtTrigger.next();
      }   
    });
    this.Datefrm= this.formBuilder.group({
      from_date : ['',[Validators.required]],
      to_date :['',[Validators.required]],
    });
  }
  get f(){ return this.Datefrm.controls;}
  redirect(id)
  {
    this.router.navigate(['survey/approved/'+id+'/view']);
    return true;
  }
  CustomeFormsubmit(){
    this.isFormsubmit= true
    if(this.Datefrm.valid)
    {
     
      let from_date =this.datepipe.transform(this.Datefrm.value.from_date, 'yyyy-MM-dd');
      let to_date =this.datepipe.transform(this.Datefrm.value.to_date, 'yyyy-MM-dd');
      const get_data= {user_id: this.user_id,csv_type:'custom','from_date':from_date,'to_date':to_date};
      this.surveysrv.downloadserveyreport(get_data).subscribe(res => {
            if(res.status=='success')
            {
              
              window.open(this.downloadFinal(res.data.url),'target="_new"');
            }  
            else 
            {
              this.err_msg = "something is wrong!";
            }
        });
      
    }
  }
 
  downloadFinal(downloadData)
  {
    return this.surveysrv.downloadcsv(downloadData,this.user_id);
  }

}
