import { NgModule,Inject } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AuthModule { 

constructor(@Inject(String) public  email:string,
           @Inject(String) public   mobile_no:string,
           @Inject(String) public user_id:number,
           @Inject(String) public  full_name : string,
           @Inject(String) public  admin_type:string,
           @Inject(String) public  token:string
          )
{

}

}
