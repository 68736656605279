<style>
    .action-links a{font-size:22px;}
    .edit-link{color:#000}
    .active-status-link{color:green}
    .block-status-link{color:red;}
  </style>
  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
    <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header">
                 <h3 class="card-title">Customer List</h3>
               </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                    <tr>
                        <th>Sr.No</th> 
                        <th>Customer Name</th>
                        <th>Customer Mobile</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Expert</th>
                        <th>Branch Survey</th>
                        <th>Action</th>
                    </tr>
                   </thead>
                   <tbody>
                   <tr *ngFor="let cust of customeListArray; let i = index">
                     <td>{{ i+1 }}</td>
                     <td>{{ cust.full_name }}</td>
                     <td>{{ cust.user_mobile }}</td>
                     <td>{{ cust.email  }}</td>
                     <td>
                       <span *ngIf="cust.user_status==='1'"><span class="badge badge-success">Active</span></span>
                       <span *ngIf="cust.user_status==='0'"><span class="badge badge-danger">Block</span></span>
                     </td>
                     <td class="action-links">
                      <a class="active-status-link" *ngIf="cust.is_extractor_on==='1'" href="javascript:void(0);" title="Expert mode on" (click)="changeStatus(cust.id,cust.is_extractor_on)" style="margin-left: 10px;"><i class="fas fa-toggle-on"></i></a>
                      <a class="block-status-link" *ngIf="cust.is_extractor_on==='0'" href="javascript:void(0);" title="Expert mode off" (click)="changeStatus(cust.id,cust.is_extractor_on)" style="margin-left: 10px;"><i class="fas fa-toggle-off"></i></a>
                     </td>
                     <td class="action-links">
                      <a class="active-status-link" *ngIf="cust.is_branch_survey_on==='1'" href="javascript:void(0);" title="Branch mode on" (click)="changeBranch(cust.id,cust.is_branch_survey_on)" style="margin-left: 10px;"><i class="fas fa-toggle-on"></i></a>
                      <a class="block-status-link" *ngIf="cust.is_branch_survey_on==='0'" href="javascript:void(0);" title="Branch mode off" (click)="changeBranch(cust.id,cust.is_branch_survey_on)" style="margin-left: 10px;"><i class="fas fa-toggle-off"></i></a>
                     </td>
                     <td class="action-links" align="center">
                       <a routerLink="/customer/{{cust.id}}/add/" class="edit-link t-extra-btn addbtn"  title="Add Credits" style="margin-right: 10px;" ><i class="fa fa-plus"></i></a>
                       <a routerLink="/customer/{{cust.id}}/debit/" class="edit-link t-extra-btn debitbtn" title="Debit Credits" ><i class="fa fa-minus"></i></a>
                       <a routerLink="/customer/{{cust.id}}/log/" class="edit-link t-extra-btn listbtn" title="Payment Log" ><i class="fa fa-list"></i></a>
                      </td>
                   </tr>
                   <tr *ngIf="customeListArray.length==0">
                     <td colspan="7">No Record Found</td>
                   </tr>
                   </tbody>
                 </table>
                </div>
               </div>
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->