import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';
import { AddComponent } from './user/add/add.component';
import { ListComponent } from './user/list/list.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyPendingComponent } from './survey/survey-pending/survey-pending.component';
import { SurveyEditComponent } from './survey/survey-edit/survey-edit.component';
import { SurveyApprovedComponent } from './survey/survey-approved/survey-approved.component';
import { SurveyRejectComponent } from './survey/survey-reject/survey-reject.component';
import { SurveyDetailsComponent } from './survey/survey-details/survey-details.component';
import { AddSampleComponent } from './sample/add-sample.component';
import { AudioComponent } from './audio/audio.component';
import { AudioListComponent } from './audio/audio-list/audio-list.component';
import { AudioAddComponent } from './audio/audio-add/audio-add.component';
import { CreditComponent } from './credit/credit.component';
import { CreditListComponent } from './credit/credit-list/credit-list.component';
import { CreditAddComponent } from './credit/credit-add/credit-add.component';
import { CreditLogComponent } from './credit/credit-log/credit-log.component';
import { CreditDebiteComponent } from './credit/credit-debite/credit-debite.component';
import { ExpertModeComponent } from './expert-mode/expert-mode.component';
import { SurveyTextComponent } from './survey/survey-text/survey-text.component';
import { CampaignComponent } from './campaign/campaign.component';
import { DataExtractComponent } from './data-extract/data-extract.component';
import { DataFormComponent } from './data-extract/data-form/data-form.component';
import { DataDownloadComponent } from './data-extract/data-download/data-download.component';
import { SurveyTagsComponent } from './survey/survey-tags/survey-tags.component';
import { BuilderReportComponent } from './builder-report/builder-report.component';
import { SurveyLogComponent } from './survey-log/survey-log.component';
import { SurveyWaveComponent } from './survey/survey-wave/survey-wave.component'
import { SurveyWhatsappComponent } from './survey/survey-whatsapp/survey-whatsapp.component';
import { SurveyOnlineComponent } from './survey/survey-online/survey-online.component';
import { StepListingComponent } from './survey-steps/step-listing/step-listing.component';
import { AddStepComponent } from './survey-steps/add-step/add-step.component';
import { EditStepComponent } from './survey-steps/edit-step/edit-step.component';

const routes: Routes = 
[
    {path:'',component:LoginComponent,pathMatch:'full'},
    {path :'verify-otp',component:VerifyOtpComponent},
    {path :'dashboard',component:DashboardComponent},
    {path : 'user',component:UserComponent, 
    children:[
            {path:'',component:ListComponent},
            {path:'add',component:AddComponent},
            {path:':id',component:AddComponent},
            {path:':id/edit',component:AddComponent}
          ]
    },
    {path : 'survey',component:SurveyComponent, 
    children:[
            {path:'',component:SurveyPendingComponent},
            {path:':id/edit',component:SurveyEditComponent},
            {path:':id/edit/whatsapp',component:SurveyWhatsappComponent},
            {path:':id/edit/online',component:SurveyOnlineComponent},
            {path:'approved',component:SurveyApprovedComponent},
            {path:'approved/:id/view',component:SurveyDetailsComponent},
            {path:'approved/:id/tag',component:SurveyTagsComponent},
            {path:'reject',component:SurveyRejectComponent},
            {path:'reject/:id/view',component:SurveyDetailsComponent},
            {path:'request',component:SurveyTextComponent},
            {path:'wave/:id/:surveyID',component:SurveyWaveComponent},
            //Steps route under survey list
            //Listing steps 
            {path:'steps/:id',component: StepListingComponent },
            //Add step
            {path:'steps/:id/add-step',component: AddStepComponent },
            //Edit step
            {path:'steps/:id/edit-step/:qid',component: EditStepComponent },
            {path:'steps/:id/view-step/:id',component: EditStepComponent }
          ]
    },
    {path : 'audio',component:AudioComponent, 
    children:[
          {path:'',component:AudioListComponent},
          {path:'add',component:AudioAddComponent},
          {path:':id',component:AudioAddComponent},
          {path:':id/edit',component:AudioAddComponent}
        ]
    },
    {path : 'customer',component:CreditComponent, 
    children:[
          {path:'',component:CreditListComponent},
          {path:'add',component:CreditAddComponent},
          {path:':id/add',component:CreditAddComponent},
          {path:':id/log',component:CreditLogComponent},
          {path:':id/debit',component:CreditDebiteComponent}
        ]
    },
  {path :'campaign/user',component:CampaignComponent},  
  {path :'sample/add',component:AddSampleComponent},
  {path :'expert-mode',component:ExpertModeComponent},
  {path : 'panel-builder',component:BuilderReportComponent},
  {path : 'data',component:DataExtractComponent, 
    children:[
            {path:'extract',component:DataFormComponent},
            {path:'manage',component:DataDownloadComponent},
          ]
  },
  {path : 'survey-log',component:SurveyLogComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
