<aside class="main-sidebar sidebar-dark-primary elevation-4" >
    <!-- Brand Logo -->
    <a routerLink='/' class="brand-link">
      <img src="assets/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light">{{ user_name }}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="assets/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ user_name }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column " data-widget="treeview"  role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item ">
            <a href="#" class="nav-link" >
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="/dashboard" class="nav-link " >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
               User
                <i class="fas fa-angle-left right"></i>
                <!--<span class="badge badge-info right">6</span>-->
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="user/add" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add User</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="user" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>User List</p>
                </a>
              </li>
            </ul>
          </li>
     
          <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin' || admin_type=='finance'"> 
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p> Wallet <i class="fas fa-angle-left right"></i>
                <!--<span class="badge badge-info right">6</span>-->
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="customer" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Customer List</p>
                </a>
              </li>
            </ul>
         </li>
         <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p> Audio <i class="fas fa-angle-left right"></i></p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="audio/add" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add Audio</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="audio" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Audio List</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin' || admin_type=='campaign-manager'"> 
            <a routerLink="campaign/user" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
               Add Campaign
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin'"> 
            <a routerLink="sample/add" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
               Add Sample User
                <!--<i class="fas fa-angle-left right"></i>-->
                <!--<span class="badge badge-info right">6</span>-->
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='moderator' || admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link"   >
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Survey 
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <!--<li class="nav-item">
                <a routerLink="survey/request" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Text Question Request</p>
                </a>
              </li>-->
              <li class="nav-item">
                <a routerLink="survey" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Survey List</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="survey/approved" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Approved Survey List</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="survey/reject" class="nav-link" >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Reject Survey List</p>
                </a>
              </li>
            </ul>
          </li>
          <!--<li class="nav-item has-treeview" *ngIf="admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
               Settings
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="expert-mode" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Expert Mode</p>
                </a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item has-treeview" *ngIf="admin_type=='moderator' || admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link"   >
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Data Extraction
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" >
              <li class="nav-item" *ngIf="admin_type=='superadmin'">
                <a routerLink="data/extract" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Data Request</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="data/manage" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Data Download</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='panel builder' || admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link"   >
              <i class="nav-icon fas fa-copy"></i>
              <p>Reports
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" >
              <li class="nav-item" *ngIf="admin_type=='superadmin'">
                <a routerLink="panel-builder" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Panel Builder</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item has-treeview" *ngIf="admin_type=='superadmin'"> 
            <a href="javascript:void(0);" class="nav-link"   >
              <i class="nav-icon fas fa-copy"></i>
              <p>Survey Log
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" >
              <li class="nav-item" *ngIf="admin_type=='superadmin'">
                <a routerLink="survey-log" class="nav-link"  >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Survey Log</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a  (click)="logout()" href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>Logout</p>
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
    <!-- <div id="salesChart" style="display:none;"></div> -->
    <!-- <canvas id="salesChart"></canvas> -->
</aside>