  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Approved Survey List</h1>
          </div>
         
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Survey</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
     <!-- Download option-->
     <div class="modal fade approve_modal custom_download_modal" id="modal-default">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- <app-loader *ngIf="downloadLoader"></app-loader>   -->
          <div class="modal-header">
            <h3>Download Report</h3>
            <button type="button" #closebuttonapprove class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" *ngIf="err_msg">{{ err_msg }}</div>
            <!-- <div class="button_actions">
              <div class="row"  >
                <div class="col-6"  style="margin-top: 10px;" *ngFor=" let btn of downloadBtn">
                  <button type="button"  class="btn btn-primary mr-2 download_btn1 w-100" (click)="downloadData(btn.value);">{{btn.name}}</button>
                </div>

             </div>
            </div>
            <div class="download_or">
              <span>OR</span>
            </div> -->
            <div class="download_form">
              <form [formGroup]="Datefrm" (ngSubmit)="CustomeFormsubmit();">
                <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="col-6">
                      <div class="cal_wrap">
                        <input type="text" class="form-control"    placeholder="From Date"  bsDatepicker [bsConfig]="{dateInputFormat: 'DD-MM-YYYY'}" formControlName="from_date" >
                        <i class="far fa-calendar-alt"></i>
                      </div>
                      <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.from_date.errors">
                        <span *ngIf="f.from_date.errors.required" style="color:red;" class="help-block">Please select from date.</span>
                      </span></p>
                    </div>
                    <div class="col-6">
                      <div class="cal_wrap">
                        <input type="text" class="form-control"  formControlName="to_date" placeholder="To Date" bsDatepicker [bsConfig]="{dateInputFormat: 'DD-MM-YYYY'}">
                        <i class="far fa-calendar-alt"></i>
                      </div>
                      <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.to_date.errors">
                        <span *ngIf="f.to_date.errors.required" style="color:red;" class="help-block">Please select to date.</span>
                      </span></p>
                    </div>

                  </div>
                </div>
                <div class="col-3">
                  <button type="submit" class="btn btn-primary mr-2 gen_btn1">Download</button>
                </div>
                </div>
              </form>
            </div>
           </div>
        </div>
      </div>
   </div>
  <!--Download option--> 

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Approved Survey List</h3>
              </div>
              <div class="card-header" >
                <div class="row">
                  <div class="col-lg-3">
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#modal-default" class="custom_down_btn">Download Report</a>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              
              <div class="card-body">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="table-responsive">
                <table id="example1"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Sr.No</th> 
                    <th>Customer Name</th>
                    <th>Mobile</th>
                    <th>Survey Name</th>
                    <th>Date</th>
                    <th>Updated Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="cursor: pointer;"  *ngFor="let survey of surveyArray; let i = index" >
                    <td (click)="redirect(survey.id)">{{ i+1 }}</td>
                    <td (click)="redirect(survey.id)">{{ survey.user_data.full_name }}</td>
                    <td (click)="redirect(survey.id)">{{ survey.user_data.user_mobile  }}</td>
                    <td (click)="redirect(survey.id)">{{ survey.survey_name  }}</td>
                    <td (click)="redirect(survey.id)">{{ survey.created_at1}}</td>
                    <td (click)="redirect(survey.id)">{{ survey.updated_at1}}</td>
                    <td class="action-links">
                      <a class="edit-link t-extra-btn addbtn" routerLink="{{ survey.id }}/view"><i class="fas fa-eye"></i></a>
                      <a class="edit-link t-extra-btn listbtn" routerLink="{{ survey.id }}/tag"><i class="fas fa-tags"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="surveyArray.length==0">
                    <td colspan="7">No Record Found</td>
                  </tr>
                  </tbody>
                </table>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->