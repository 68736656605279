import { Component, OnInit,ViewChild } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  userListArray = [];
  token ='';
  isLoader=true;
  isFormsubmit=false;
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  errorMessage = '';
  successMessage='';
  isfirstinit=true;
  constructor(private userlist : UserService) { }

  ngOnInit(): void {
    
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [6],
        orderable :false
      }]

    };
  const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.getList();
    
  }
  getList()
  {
    this.isLoader=true;
    this.userlist.listUser(this.token).pipe(first()).subscribe(user => {
      if(user.status=='success')
      {
        this.userListArray = user.response_data;
        this.dtTrigger.next();
      }   
      this.isLoader=false;
    });
  }
  changeStatus(user_id,status)
  {
      if(status=='0')
      {
        status = '1'
      }
      else
      {
        status = '0';
      }
      if(confirm("Are you sure you want to change the status?"))
      {
        this. isLoader=true;
        this.userlist.statusChange(this.token,status,user_id).pipe(first()).subscribe(user => {
          if(user.status=='success')
          {
            this.rerender();
            this.ngOnInit();
            this.isLoader=false;
          }   
          else
          {
            this.isLoader=false;
          }
        });
      }
  }
  rerender():void {
    this.dtElement.dtInstance.then((dtInstance : DataTables.Api)=>{
      dtInstance.destroy();
      //this.dtTrigger.next();
    })
  }

}
