import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthModule } from '../auth/auth/auth.module';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  siteUrl = environment.apiUrl;
  userSession = new BehaviorSubject<AuthModule>(null); 
  constructor(private http:HttpClient,private router: Router) { }
  /*verify otp*/
  sendOtp(userLogin)
  {
    const user_mobile = userLogin.euserData;
    return this.http.post<any>(this.siteUrl+'api/v1/admin/send_otp',{'user_otp_field':user_mobile}).pipe(catchError(this.handleError),tap(responseData=>{
          console.log(responseData['response_data']);
          if(responseData['status']=='success')
          {
            localStorage.setItem("userVerify",JSON.stringify(responseData['response_data']));
            this.router.navigate(['/verify-otp']); 
          }
      }));
  }
  /*verify otp*/
  verifyOtp(userData)
  {
    const user_otp_field = userData.euserData;
    const otp = userData.euserVerifyOtp;
    return this.http.post<AuthModule>(this.siteUrl+'api/v1/admin/login',{'user_login_field':user_otp_field,'otp':otp}).pipe(catchError(this.handleError),tap(responseData=>{
          if(responseData['status']=='success')
          {
             localStorage.removeItem('userVerify');
             this.handleAuthentication(responseData['response_data']['email'],responseData['response_data']['mobile_no'],responseData['response_data']['user_id'],responseData['response_data']['full_name'],responseData['response_data']['admin_type'],responseData['response_data']['token']);
             this.router.navigate(['/dashboard']);
          }
      }));
  }
  /*resend otp*/
  resendOtp(userData)
  {
    const user_otp_field = userData.euserData;
    return this.http.post<any>(this.siteUrl+'api/v1/admin/resend_otp',{'user_resend_field':user_otp_field}).pipe(catchError(this.handleError),tap(responseData=>{}));
  }
  /*outologin*/
  autoLogin(){
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    if(!userData){
      return;
    }
    const loadedData = new AuthModule(userData.email,userData.mobile_no,userData.user_id,userData.full_name,userData.admin_type,userData.token)
    if(loadedData){
      this.userSession.next(loadedData);
      this.router.navigate(['/']);
    }
  }
  /*logout*/
  logout()
  {
    this.userSession.next(null);
    this.router.navigate(['/']);
    localStorage.removeItem('userData');
  }
  handleAuthentication(email:string,mobile_no:string,user_id:number,full_name : string,admin_type:string,token:string)
  {
     const user = new AuthModule(email,mobile_no,user_id,full_name,admin_type,token);
     this.userSession.next(user);
     localStorage.setItem("userData",JSON.stringify(user));
  }
  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
