<style>
    .action-links a{font-size:22px;}
    .edit-link{color:#000}
    .active-status-link{color:green}
    .block-status-link{color:red;}
  </style>
  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
    <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header">
                 <h3 class="card-title">Download Request</h3>
               </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                    <tr>
                      <th>Sr.No</th> 
                      <th>State Name/Survey</th>
                      <th>District</th>
                      <th>PC</th>
                      <th>AC</th>
                      <th>Pincode</th>
                      <th>Sample size</th>  
                      <th>Per unit/Type</th>
                      <th>Action</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr *ngFor="let down of downloadListArray; let i = index">
                      <td>{{ i+1 }}</td> 
                      <td>{{ down.state_name }}{{ down.survey_name }}</td>
                      <td>{{ down.district_name }}</td>
                      <td>{{ down.pc_name }}</td>
                      <td>{{ down.ac_name }}</td>
                      <td>{{ down.pincode }}</td>
                      <td>{{ down.sample_size }}</td>  
                      <td>{{ down.per_unit }}{{ down.request_type }}</td>
                      <td *ngIf="down.is_csv_generated=='1' && down.status!='2'"><a href="{{downloadData(down.download_csv_url)}}">Download</a></td>
                      <td *ngIf="down.is_csv_generated=='0' && down.status!='2'">In-Progress</td>
                      <td *ngIf="down.status=='2'">Failed</td>
                    </tr>
                    <tr *ngIf="downloadListArray.length==0">
                      <td colspan="9">No Record Found</td>
                    </tr>
                   </tbody>
                 </table>
                </div>
               </div>
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->