import { Component, OnInit,ViewChild } from '@angular/core';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
import { CustomerService } from 'src/app/service/customer.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-credit-log',
  templateUrl: './credit-log.component.html',
  styleUrls: ['./credit-log.component.css']
})
export class CreditLogComponent implements OnInit {
  Datefrm : FormGroup;
  customeListArray =[];
  token ='';
  isLoader = false;
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  isfirstinit=true;
  customerId : number;
  editFor=''; 
  user_name = '';
  user_mobile = '';
  total_wallet_available_credits ='';
  isShowButton = false;
  err_msg='';
  admin_user_id:number;
  downloadLoader=false;
  minDate: Date;
  maxDate: Date;
  isFormsubmit=false;
  ButtonName = [{'button_name':'Today','slug':'today','button_status':false},
                {'button_name':'Yesterday','slug':'yesterday','button_status':false},
                {'button_name':'This week','slug':'this_week','button_status':false},
                {'button_name':'Last week','slug':'last week','button_status':false},
                {'button_name':'This Month','slug':'this_month','button_status':false},
                {'button_name':'Last Month','slug':'last month','button_status':false},
                {'button_name':'This Quarter','slug':'quarterly','button_status':false},
                {'button_name':'Last Quarter','slug':'last_quarter','button_status':false},
                {'button_name':'This Year','slug':'yearly','button_status':false},
                {'button_name':'Last Year','slug':'last_year','button_status':false}
              ];
  constructor(public datepipe: DatePipe,private route : ActivatedRoute,private cust : CustomerService,private formBuilder:FormBuilder) { }

  ngOnInit(): void {
      this.minDate = new Date();
      this.dtOptions= {
        pagingType : 'full_numbers',
        pageLength : 50,
        processing : false,
        columnDefs : [{ 
          targets : [4],
          orderable :false
        }],
        responsive:true,
      
      };
      const userData:{
        email:string,
        mobile_no:string,
        user_id:number,
        full_name : string,
        admin_type:string,
        token:string
      } 
      =JSON.parse(localStorage.getItem('userData'));
      this.token = userData.token;
      this.admin_user_id =  userData.user_id;

      this.route.params.subscribe((param :  Params)=>{
        this.customerId = +param['id']
        this.getCustomerList();
      }); 

      this.Datefrm= this.formBuilder.group({
        from_date : ['',[Validators.required]],
        to_date :['',[Validators.required]],
      });
  }
  get f(){ return this.Datefrm.controls;}
  getCustomerList()
  {
    this.isLoader=true;
    this.cust.customerLog(this.token,this.customerId).subscribe(customeres => {
      if(customeres['status']=='success')
      {
        this.customeListArray = customeres.response_data.history;
        if(customeres.response_data.history)
        {
           this.isShowButton = true;
        }
        this.user_name = customeres.response_data.user_name;
        this.user_mobile = customeres.response_data.user_mobile;
        this.total_wallet_available_credits = customeres.response_data.total_wallet_available_credits;
        this.dtTrigger.next();
      }   
      this.isLoader=false;
    });
  }
  rerender():void {
    this.dtElement.dtInstance.then((dtInstance : DataTables.Api)=>{
      dtInstance.destroy();
      //this.dtTrigger.next();
    })
  }
  downloadData(targetEvent)
  {
    if(targetEvent!='')
    {
        this.downloadLoader=true;
        const get_data= {'user_id':this.customerId,'csv_type':targetEvent };
        this.cust.downloadCsv(this.token,get_data).subscribe(res => {
            if(res.status=='success')
            {
              this.downloadLoader=false;
              window.open(this.downloadFinal(res.data.url),'target="_new"');
            } 
            else
            {
              this.err_msg = "something is wrong!";
            } 
        });
    }
    else
    {
       this.err_msg = "something is wrong!";
    }
  }
  CustomeFormsubmit()
  {
    this.isFormsubmit=true;
    if(this.Datefrm.valid)
    {
      this.downloadLoader=true;
      let from_date =this.datepipe.transform(this.Datefrm.value.from_date, 'yyyy-MM-dd');
      let to_date =this.datepipe.transform(this.Datefrm.value.to_date, 'yyyy-MM-dd');
      const get_data= {'user_id':this.customerId,'csv_type':'custom','from_date':from_date,'to_date':to_date};
      this.cust.downloadCsv(this.token,get_data).subscribe(res => {
            if(res.status=='success')
            {
              this.downloadLoader=false;
              window.open(this.downloadFinal(res.data.url),'target="_new"');
            }  
            else
            {
              this.err_msg = "something is wrong!";
            }
        });
      
    }
  }
  downloadFinal(downloadData)
  {
    return this.cust.downloadWallet(downloadData,this.admin_user_id);
  }

}
