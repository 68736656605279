import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  siteUrl = environment.apiUrl;
  constructor(private http:HttpClient,private router: Router) { }

  expertMode(token,status)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/extractor_on_off',status,{headers});
  }
  stateList(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/select_state',Passarray,{headers});
  }
  getDistrict(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/select_district',Passarray,{headers});
  }
  getPc(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/select_pc',Passarray,{headers});
  }
  getAc(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/select_ac',Passarray,{headers});
  }
  getPincode(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/select_pincode',Passarray,{headers});
  }
  dataextract(token,Passarray)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/data_extraction',Passarray,{headers});
  }
  datadownload(fileurl,userID)
  {
    return this.siteUrl+'api/v1/admin/download_count?csv_url='+fileurl+'&user_id='+userID;
  }
  downloadsample(file_name)
  {
    return this.siteUrl+'uploads/state/'+file_name;
  }
  downloadList(token,passData)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/download_file_csv',passData,{headers});
  }
  downloadbuilder(fileurl,userID)
  {
    return this.siteUrl+'api/v1/admin/csv_download_panel_builder?csv_url='+fileurl+'&user_id='+userID;
  }
  uploadCsv(token,formData){
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/upload_test_csv',formData,{headers});
  }

  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
