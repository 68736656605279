import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/login.service';
import { Router } from '@angular/router';

import * as $ from 'jquery';
import * as AdminLte from 'admin-lte';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private logincheck : LoginService,private router : Router) { }

 
  togglediv()
  {
    $('[data-widget="pushmenu"]').each(function() {
      AdminLte.PushMenu._jQueryInterface.call($(this),'toggle','init');
    });
  }

  ngOnInit(): void {
    this.logincheck.userSession.subscribe(res=>{
      if(res)
      {
        
      }
      else
      {
        this.router.navigate(['/']);
      }
    })
  }

}
