import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  siteUrl = environment.apiUrl;

  constructor(private http:HttpClient,private router: Router) { }

  taglist(token,status)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/tag_list',{'status':status},{headers});
  }
  tagAdd(token,datapass)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/insert_survey_question',datapass,{headers});
  }
  detailSurvey(token,survey_id,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/survey_questions_detail',{'survey_id':survey_id,'user_id':user_id},{headers});

  }
}
