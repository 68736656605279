import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  Verifyfrm : FormGroup;
  isformSubmit =false;
  errorMessage = '';
  isLoader = false;
  verifyOTP=false;
  usermobile='';

  timeLeft: number = 60;
  interval;
  button_disable=true;
  successMessage='';
  loginPage = true;
  constructor(private formBuilder : FormBuilder,private router : Router,private login : LoginService) { }

  ngOnInit(): void {
    console.log('testest');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    const userData:{
      user_id : number,
      mobile_no : string,
      otp : string,
      is_complete_status : string,
    } 
    =JSON.parse(localStorage.getItem('userVerify'));
    if(!userData)
    {
      this.router.navigate(['/']);
      return;
    }
    this.usermobile =userData['mobile_no'];
    this.Verifyfrm= this.formBuilder.group({
      euserData : [{value: this.usermobile, disabled: true},[Validators.required]],
      euserVerifyOtp : ['',[Validators.required]]
    });
    this.starttimer();
    
    
     
  }
  starttimer()
  {
    this.timeLeft=60;
    this.button_disable=true;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.button_disable=false;
      }
    },1000)
  }

  get f(){ return this.Verifyfrm.controls;}
  frmVerify()
  {
      this.errorMessage ='';
      this.successMessage='';
      this.isformSubmit=true;
      if(this.Verifyfrm.invalid)
      {
        return false;
      }
      this.isLoader=true;
      const passdata ={'euserData':this.usermobile,'euserVerifyOtp':this.Verifyfrm.value.euserVerifyOtp}; 
      this.login.verifyOtp(passdata).subscribe(res=>{
        if(res['status']=='error')
        {
          this.errorMessage = res['msg'];
        }
        this.isLoader=false;
      });

  }
  resentOtp()
  {
      
      this.errorMessage ='';
      this.successMessage='';
      this.isLoader=true;
      const passdata ={'euserData':this.usermobile}; 
      this.login.resendOtp(passdata).subscribe(res=>{
       
        if(res['status']=='error')
        {
          this.errorMessage = res['msg'];
        }
        else
        {
          this.successMessage=res['msg'];
        }
        this.isLoader=false;
        this.button_disable=true;
        this.starttimer();
      });
  }
}
