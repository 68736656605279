import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  siteUrl = environment.apiUrl;
  constructor(private http:HttpClient,private router: Router) { }
  /*verify otp*/
  listRole(token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.get<any>(this.siteUrl+'api/v1/admin/role_list?token='+token,{headers});
  }
  /*user data save data*/
  userSave(user_data,token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/add_subadmin',user_data,{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));
  }
  listUser(token){
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.get<any>(this.siteUrl+'api/v1/admin/subadmin_list?token='+token,{headers});
  }
  statusChange(token,status,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/subadmin_block_unbock',{'id':user_id,'status':status},{headers});
  }
  updateUser(userdata,token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/update_subadmin',userdata,{headers});
  }  
  getUserDetails(token,id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/profile_details',{'id':id},{headers});
  }
  getAllstate(token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/state_list',{'country_id':'101'},{headers});
  }
  getAllpc(token,state_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/pc_list',{'state_id':state_id},{headers});
  }
  getAllAc(token,state_id,pc_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/ac_list',{'state_id':state_id,'pc_no':pc_id},{headers});
  }
  listLanguge(token,userId)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/languages',{'user_id':userId,'type':'all_list'},{headers});
  }
  userAddSample(userData,token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/addsampleuser',userData,{headers});
  }

  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
