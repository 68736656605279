import { Component, OnInit } from '@angular/core';
import { SurveyService } from 'src/app/service/survey.service';
import { UserService } from 'src/app/service/user.service';
import { TagService } from 'src/app/service/tag.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-survey-tags',
  templateUrl: './survey-tags.component.html',
  styleUrls: ['./survey-tags.component.css']
})
export class SurveyTagsComponent implements OnInit {

  isLoader =true;
  token ="";
  user_id :number;
  editForm = false;
  isDisabled=false;
  survey_id :number;
  surveyRes = [];
  surveyAudio =[];
  getlist ='';
  mobile_number='';
  title =[];
  isID :number;
  lang_array : [];
  isUserID = '';
  isSuccess='';
  solr_file_for_database_url ='';
  errorMessage='';
  successMessage='';
  count_data=[];
  autocompleteItems = [];
  items = [];
  isFormsubmittag=false;
  optionId:number;
  successMessageAudio='';
  errorMessageAudio='';
  hideme = {};
  question_edit='';
  option_edit_1='';
  option_edit_2='';
  option_edit_3='';
  option_edit_4='';
  option_edit_5="";
  items_edit=[];
  item =[];
  isIDError:number;
  constructor(private surveysrv : SurveyService,private tagsrv : TagService,private route : ActivatedRoute,private listser : UserService,) { }
  
  ngOnInit(): void {
    this.hideme = {};
    this.count_data.length=0;
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;

    this.route.params.subscribe((param :  Params)=>{
      this.survey_id = +param['id']
      this.editForm=param['id'] !=null;
      this.isDisabled=true;
      //console.log(this.editmode);
    }); 
    this.tagsrv.detailSurvey(this.token,this.survey_id,this.user_id).pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyRes = survey.response_data.arr_survey;
        this.solr_file_for_database_url = survey.response_data.arr_survey.solr_file_for_database;
        this.isUserID  =survey.response_data.arr_survey.user_id;
        this.mobile_number = survey.response_data.arr_survey.user_data.user_mobile;
        this.title=this.getSurveyType(this.surveyRes);
        this.surveyAudio = survey.response_data.arr_survey.audio_data;

        this.surveyAudio.forEach( (currentValue, index) => {
          this.item[this.surveyAudio[index].audio_id]=[];
        });

      }   
      this.isLoader=false;
    });
    this.tagsrv.taglist(this.token,this.survey_id).pipe(first()).subscribe(tag =>{
      if(tag.status=='success')
      {
         this.autocompleteItems=tag.response_data.name;
      }
    });
  
  }
  public options = {
    readonly: undefined,
    placeholder: '+ Tag'
  };
  getSurveyType(survey_response)
  {
    if(survey_response!='')
    {
      const getArray=JSON.parse(survey_response.survey_criteria);
      if(getArray.length>0)
      {
        const type:any=getArray[0].type;
        const mbstring:string =getArray.map(function(o) { return o.name;}).join(", ");
        return  [{'type':type,'list_state':mbstring}];
      } 
    }
    return  [{'type':'','list_state':''}];
  }
  
  optionSelected(n:number):Array<number>
  {
    return Array(n);
  }
  formSubmitTag(form: any,au_id,survey_id)
  {
    this.isFormsubmittag=true;
    this.isIDError = au_id;
    if(form.valid && this.item[au_id].length>0)
    { 
      this.isID =0;
      this.isLoader=true;  
      const put_array=form.value;
      const getItem=this.item[au_id].map(function(obj){
        return obj.display;
      }).join(',');
      put_array['tag_names']=getItem;
      put_array['survey_id']=survey_id;
      put_array['audio_id']=au_id;
      put_array['type']='add';
      this.tagsrv.tagAdd(this.token,put_array).subscribe(res=>{

        this.isID = au_id;
        if(res.status=='success')
        {
          this.successMessageAudio = res.msg;
          this.ngOnInit();
        }
        else
        {
          this.errorMessageAudio = res.msg;
        }
        this.item[au_id]=[];
        this.isLoader=false;  
        //form.reset();
      });
      this.isFormsubmittag=false;
    }
   
  }
  DivHide(id,index_one)
  { 
    this.question_edit = this.surveyAudio[index_one].arr_survey_question[0].question_description;
    this.option_edit_1 = this.surveyAudio[index_one].arr_survey_question[0].option_1;
    this.option_edit_2 = this.surveyAudio[index_one].arr_survey_question[0].option_2;
    this.option_edit_3 = this.surveyAudio[index_one].arr_survey_question[0].option_3;
    this.option_edit_4 = this.surveyAudio[index_one].arr_survey_question[0].option_4;
    this.option_edit_5 = this.surveyAudio[index_one].arr_survey_question[0].option_5;
    this.items_edit =this.surveyAudio[index_one].arr_survey_question[0].tag_name.split(',');
    this.surveyAudio.forEach( (currentValue, index) => {
      this.hideme[this.surveyAudio[index].audio_id]=false;
    });
    this.hideme[id]=true;
  }
  DivClose(id,index_one)
  {
    this.question_edit = this.surveyAudio[index_one].arr_survey_question[0].question_description;
    this.option_edit_1 = this.surveyAudio[index_one].arr_survey_question[0].option_1;
    this.option_edit_2 = this.surveyAudio[index_one].arr_survey_question[0].option_2;
    this.option_edit_3 = this.surveyAudio[index_one].arr_survey_question[0].option_3;
    this.option_edit_4 = this.surveyAudio[index_one].arr_survey_question[0].option_4;
    this.option_edit_5 = this.surveyAudio[index_one].arr_survey_question[0].option_5;
    this.items_edit =this.surveyAudio[index_one].arr_survey_question[0].tag_name.split(',');
    this.surveyAudio.forEach( (currentValue, index) => {
      this.hideme[this.surveyAudio[index].audio_id]=false;
    });
  }
  clearData(form: any,audio_id,form_action)
  {
    this.item[audio_id]=[];
    form.reset();
    if(form_action=='add')
    {
      form.value.question='';
      form.value.option1='';
      form.value.option2='';
      form.value.option3='';
      form.value.option4='';
      form.value.option5='';
    }
    else
    {
      form.value.question_edit='';
      form.value.option_edit_1='';
      form.value.option_edit_2='';
      form.value.option_edit_3='';
      form.value.option_edit_4='';
      form.value.option_edit_5='';
    }
    this.isFormsubmittag=false;
  }
  formSubmitTagEdit(form: any,au_id,survey_id)
  {
    this.isFormsubmittag=true;
    this.isIDError = au_id;
    if(form.valid && this.items_edit.length>0)
    {
      this.isID = 0;
      const getItem=this.items_edit.map(function(obj){
                  if(obj.display)
                  {
                    return obj.display;
                  }
                  else
                  {
                    return obj;
                  }
        }).join(',');
      this.isLoader=true;  
      const put_array = {'audio_id':au_id,
                         'survey_id':survey_id,
                         'question':form.value.question_edit,
                         'option1':form.value.option_edit_1,
                         'option2':form.value.option_edit_2,
                         'option3':form.value.option_edit_3,
                         'option4':form.value.option_edit_4,
                         'option5':form.value.option_edit_5,
                         'tag_names':getItem,
                         'type':'update'
                        };
      this.tagsrv.tagAdd(this.token,put_array).subscribe(res=>{
          this.isID = au_id;
          if(res.status=='success')
          {
            this.successMessageAudio = res.msg;
            this.ngOnInit();
          }
          else
          {
            this.errorMessageAudio = res.msg;
          }
         // this.item[au_id]=[];
          this.isLoader=false;  
          //form.reset();
      });                  
      this.isFormsubmittag=false;
    }
  }
  
}
