import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
// import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgSelectModule } from '@ng-select/ng-select'; 
// import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { LoaderComponent } from './loader/loader.component';
import { UserComponent } from './user/user.component';
import { ListComponent } from './user/list/list.component';
import { AddComponent } from './user/add/add.component';
import { SurveyPendingComponent } from './survey/survey-pending/survey-pending.component';
import { SurveyApprovedComponent } from './survey/survey-approved/survey-approved.component';
import { SurveyEditComponent } from './survey/survey-edit/survey-edit.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyRejectComponent } from './survey/survey-reject/survey-reject.component';
import { SurveyDetailsComponent } from './survey/survey-details/survey-details.component';
import { AddSampleComponent } from './sample/add-sample.component';
import { AudioComponent } from './audio/audio.component';
import { AudioAddComponent } from './audio/audio-add/audio-add.component';
import { AudioListComponent } from './audio/audio-list/audio-list.component';
import { CreditComponent } from './credit/credit.component';
import { CreditAddComponent } from './credit/credit-add/credit-add.component';
import { CreditListComponent } from './credit/credit-list/credit-list.component';
import { CreditLogComponent } from './credit/credit-log/credit-log.component';
import { CreditDebiteComponent } from './credit/credit-debite/credit-debite.component';
import { ExpertModeComponent } from './expert-mode/expert-mode.component';
import { SurveyTextComponent } from './survey/survey-text/survey-text.component';
import { CampaignComponent } from './campaign/campaign.component';
import { DataExtractComponent } from './data-extract/data-extract.component';
import { DataDownloadComponent } from './data-extract/data-download/data-download.component';
import { DataFormComponent } from './data-extract/data-form/data-form.component';
import { SurveyTagsComponent } from './survey/survey-tags/survey-tags.component';
import { BuilderReportComponent } from './builder-report/builder-report.component';
import { SurveyLogComponent } from './survey-log/survey-log.component';
import { SurveyWaveComponent } from './survey/survey-wave/survey-wave.component';
import { SurveyWhatsappComponent } from './survey/survey-whatsapp/survey-whatsapp.component';
import { SurveyOnlineComponent } from './survey/survey-online/survey-online.component';
import { StepListingComponent } from './survey-steps/step-listing/step-listing.component';
import { AddStepComponent } from './survey-steps/add-step/add-step.component';
import { EditStepComponent } from './survey-steps/edit-step/edit-step.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerifyOtpComponent,
    LoaderComponent,
    UserComponent,
    ListComponent,
    AddComponent,
    SurveyPendingComponent,
    SurveyApprovedComponent,
    SurveyEditComponent,
    SurveyComponent,
    SurveyRejectComponent,
    SurveyDetailsComponent,
    AddSampleComponent,
    AudioComponent,
    AudioAddComponent,
    AudioListComponent,
    CreditComponent,
    CreditAddComponent,
    CreditListComponent,
    CreditLogComponent,
    CreditDebiteComponent,
    ExpertModeComponent,
    SurveyTextComponent,
    CampaignComponent,
    DataExtractComponent,
    DataDownloadComponent,
    DataFormComponent,
    SurveyTagsComponent,
    BuilderReportComponent,
    SurveyLogComponent,
    SurveyWaveComponent,
    SurveyWhatsappComponent,
    SurveyOnlineComponent,
    StepListingComponent,
    AddStepComponent,
    EditStepComponent,
  
    

  ],
  imports: [
    BsDatepickerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
   
    // AutocompleteLibModule,
    NgSelectModule,
    // TagInputModule
    MatAutocompleteModule,
    MatInputModule,
    
  ],
  providers: [CookieService,DatePipe],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
