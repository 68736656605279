<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Update Question</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-3"></div>
          <!-- left column -->
          <div class="col-12 col-sm-12 col-lg-7">
            <!-- general form elements -->
  
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="alert alert-danger" *ngIf="errorMessage">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                {{ errorMessage }}
              </div>
              <div class="alert alert-success alert-dismissible" *ngIf="successMessage">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                {{ successMessage }}
              </div>
              <div class="card-header ch_question">
                <h3 class="card-title">Update Question</h3>
                <div class="card-title"> Survey Name: {{surveyName}}</div>
                <div class="card-title">Q No : {{questionNo}}</div>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
  
              <form [formGroup]="questionsForm" (ngSubmit)="onSubmit(questionsForm.value);">
                <div class="card-body">
                  <div class="form-group">
                    <label for="state_name1">Select Language <span style="color: red;">*</span></label>
                    <select (change)="getQuestions($event.target.value)" class="form-control" formControlName="lang_id">
                      <option value="">Select language</option>
                      <ng-container *ngFor="let lang of langArray">
                        <option value="{{lang.language_id}}">{{ lang.language_name }}</option>
                      </ng-container>
                    </select>
                    <div *ngIf="questionsForm.get('lang_id').invalid && questionsForm.get('lang_id').errors && (questionsForm.get('lang_id').dirty || questionsForm.get('lang_id').touched)">
                      <span class="text-danger" *ngIf="questionsForm.get('lang_id').hasError('required')">
                        This field is required.
                      </span>
                    </div>
  
                  </div>
  
                  <div class="form-group">
                    <label for="name">Question<span style="color: red;">*</span></label>
                    <input type="text" formControlName="txt_question" class="form-control" id="txt_question"
                      placeholder="Enter question name">
                      <div *ngIf="questionsForm.get('txt_question').invalid && questionsForm.get('txt_question').errors && (questionsForm.get('txt_question').dirty || questionsForm.get('txt_question').touched)">
                        <span class="text-danger" *ngIf="questionsForm.get('txt_question').hasError('required')">
                          This field is required.
                        </span>
                      </div>
                  </div>
  
                  <div class="form-group">
                    <label for="state_name1">Select Reference </label>
                    <select class="form-control" formControlName="ref_id">
                      <option value="">None</option>
                      <ng-container *ngFor="let ref_que of refQuestionArray">
                        <option value="{{ref_que.question_id}}">{{ ref_que.question_text }}</option>
                      </ng-container>
                    </select>
                  </div>
  
                  <div class="form-group">
                    <label for="state_name1">Select Question Type <span style="color: red;">*</span></label>
                    <select (change)="checkQuestionType($event.target.value)" class="form-control"
                      formControlName="question_type">
                      <option value="">Select question type</option>
                      <ng-container *ngFor="let que_type of questionTypeArray">
                        <option value="{{que_type.id}}">{{ que_type.question_type_text }}</option>
                      </ng-container>
                    </select>
  
                    <div *ngIf="questionsForm.get('question_type').invalid && questionsForm.get('question_type').errors && (questionsForm.get('question_type').dirty || questionsForm.get('question_type').touched)">
                      <span class="text-danger" *ngIf="questionsForm.get('question_type').hasError('required')">
                        This field is required.
                      </span>
                    </div>
  
                  </div>
                    <ng-container *ngIf="selectedQuestion">
                       <div class="form-group">
                        <h4> Select Option Type <span style="color: red;">*</span></h4>
                        <div>
                          <input (change)="handleChangeOptionType(0)" type="radio" id="selfoptions" value="0"
                            name="option_type" formControlName="option_type"> Self Options
                          <input (change)="handleChangeOptionType(1)" type="radio" id="userSelected" value="1"
                            name="option_type" formControlName="option_type"> User Selected
                        </div>
                        <div *ngIf="questionsForm.get('option_type').invalid && questionsForm.get('option_type').errors && (questionsForm.get('option_type').dirty || questionsForm.get('option_type').touched)">
                          <span class="text-danger" *ngIf="questionsForm.get('option_type').hasError('required')">
                            This field is required.
                          </span>
                        </div>
                      </div>
  
                      <div class="row" formArrayName="qoptions">
                        <div *ngIf="selectedOptionType" class="col-sm-12">
                          <h4>Add Options <span style="color: red;">*</span></h4>
                        </div>
                        <ng-container *ngFor="let p of questionsForm.get('qoptions')['controls']; let i = index"
                          [formGroupName]="i">
                          <div class="col-6 col-sm-8 mb-3">
                            <input formControlName="name" type="text" class="form-control" id="name"
                              placeholder="Enter option name">
                            <div *ngIf="!p.controls.name.valid && p.controls.name.dirty">
                              <span class="text-danger" *ngIf="p.controls.name.hasError('required')">Please fill out this
                                field </span>
                            </div>
                          </div>
                          <div class="col-4 col-sm-2 d-flex align-items-center">
                            <div class="form-check">
                              <input formControlName="preference" class="form-check-input" type="checkbox" value="true"
                                id="flexCheckDefault">
                              <label class="form-check-label" for="flexCheckDefault">
                                Preference
                              </label>
                            </div>
                          </div>
                          <div class="col-2 col-sm-2 mb-3">
                            <button *ngIf="i != 0" (click)="removeAddressAt(i)" type="button" class="btn rmvBtn"><i
                                class="fa fa-minus text-white fa-sm" aria-hidden="true"></i></button>
                          </div>
                        </ng-container>
  
                      </div>
                        <ng-container *ngIf="selectedOptionType">
                          <button (click)="addRows()" type="button" class="btn addBtn"><i class="fa fa-plus text-white fa-sm mr-1" aria-hidden="true"></i> Add Option</button>
                        </ng-container>
                    </ng-container>
                     
  
  
                </div>
                <!-- <pre>{{ questionsForm.value | json }}</pre> -->
  
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" [disabled]="!questionsForm.valid" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>