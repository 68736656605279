import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, RouterLink,Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { StepsService } from 'src/app/service/steps.service';
import { SurveyService } from 'src/app/service/survey.service';

@Component({
  selector: 'app-step-listing',
  templateUrl: './step-listing.component.html',
  styleUrls: ['./step-listing.component.css']
})
export class StepListingComponent implements OnInit {
  surveyId   : any = "";
  surveyName : any = "";
  isLoader =true;
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions : DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  token ="";
  user_id :number;
  stepsArray = [];
  errorMessage = '';
  successMessage = '';
  constructor(
      private stepServ : StepsService,  
      private router   : Router,
      private route    : ActivatedRoute
    ){ 
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } = JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
    this.surveyName =  localStorage.getItem('survey_name');
  }
  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [4],
        orderable :false
      }]
    };
    this.route.params.subscribe((param :  Params)=> {
      this.surveyId  = atob(param['id']);
    });

    this.stepServ.listSteps(this.token,this.surveyId).pipe(first()).subscribe((data: any) => { 
        this.isLoader = false;
        if(data.status=='success'){
          this.stepsArray = data.response_data;
          this.dtTrigger.next();
        }else{      
          this.errorMessage = data.msg;
          this.stepsArray = [];
          this.dtTrigger.next();
        } 
        console.log("resultmyyyyyy =>" , data) 
      },(error) => {
          this.isLoader = false;
          this.errorMessage = "Something Went Wrong, Please Try Again";
      }
    );
  }
  deleteStep(questionId){
    this.errorMessage = '';
    this.successMessage = '';
    if(confirm("Are you sure you want to delete your step?")){
      this.isLoader = true;
      this.stepServ.deleteStep(this.token,questionId).subscribe((res: any) => { 
        this.isLoader = false;
        this.scrollPage();
        if(res.status=='success'){
          this.rerender();
          this.successMessage = res.msg;
          this.ngOnInit();
        }else{
          this.errorMessage   = res.msg;
        }
      },(error) => {
           this.isLoader = false;
          this.errorMessage = "Something Went Wrong, Please Try Again";
      }
     );
    }
  }
  goToAdd(){
    this.surveyId = btoa(this.surveyId);
    this.router.navigate(['/survey/steps/'+this.surveyId+'/add-step']);
  }
  goToEdit(questionId){
    this.surveyId = btoa(this.surveyId);
    questionId = btoa(questionId);
    this.router.navigate(['/survey/steps/'+this.surveyId+'/edit-step/'+questionId]);
  }
  goToView(questionId){
    this.surveyId = btoa(this.surveyId);
    this.router.navigate(['/survey/steps/'+this.surveyId+'/view-step/'+questionId]);
  }
  rerender():void {
    this.dtElement.dtInstance.then((dtInstance : DataTables.Api)=>{
      dtInstance.destroy();
    })
  }
  scrollPage(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  
}
