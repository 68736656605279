import { Component, OnInit,NgModule } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  Userfrm : FormGroup;
  role_array = [];
  state_array = [];
  pc_array = [];
  ac_array=[];
  token ='';
  isLoader=true;
  isFormsubmit=false;
  errorMessage = '';
  successMessage='';
  editForm = false;
  userId :number;
  isDisabled = false;
  lang_array = [];
  check_lang= [];
  mobnumPattern ='^((\\+91-?)|0)?[0-9]{10}$';

  constructor(private route : ActivatedRoute,private listser : UserService,private formBuilder:FormBuilder,private router : Router) { }
  
  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.listser.listRole(this.token).pipe(first()).subscribe(role => {
       if(role.status=='success')
       {
         this.role_array = role.response_data;
       }   
       this.isLoader=false;
     });
    this.listser.getAllstate(this.token).pipe(first()).subscribe(state => {
        if(state.status=='success')
        {
          this.state_array = state.response_data;
        }   
        this.isLoader=false;
     });
    this.listser.listLanguge(this.token,userData.user_id).pipe(first()).subscribe(lang => {
      if(lang.status=='success')
      {
        this.lang_array = lang.response_data;
      }   
    });
    /*form validation*/
    this.Userfrm= this.formBuilder.group({
         first_name : ['',[Validators.required]],
         email:['',[Validators.required,Validators.email]],
         mobile_no:['',[Validators.required,Validators.pattern(this.mobnumPattern)]],
         address:['',[Validators.required]],
         role:['',[Validators.required]],
         state_id : [''],
         ac_id : [''],
         pc_id : [''],
         langauge: this.formBuilder.array([],[Validators.required]) //[[],[Validators.required]],
      });
    this.route.params.subscribe((param :  Params)=>{
          this.userId = +param['id']
          this.editForm=param['id'] !=null;
          this.initform();
          this.isDisabled=true;
        }); 
  }
  initform()
  {
    if(this.editForm)
    {
      let receipIngraarr=new FormArray([]);
      this.isLoader=true;
      this.listser.getUserDetails(this.token,this.userId).pipe(first()).subscribe(res =>{
            if(res.status=='success')
            {
              if(res.response_data.state_id!='' && res.response_data.pc_id!='')
              {     
                this.onChangePc(res.response_data.state_id,res.response_data.pc_id);
              }
              if(res.response_data.state_id!='')
              {
                this.onChangeState(res.response_data.state_id);
              }
              if(res.response_data.language!='' && res.response_data.language!=null)
              {
                 this.check_lang=res.response_data.language.split(',');
              }
              console.log(this.check_lang);
              
              this.Userfrm= this.formBuilder.group({
                    first_name : [res.response_data.first_name,[Validators.required]],
                    email:[res.response_data.email,[Validators.required,Validators.email]],
                    mobile_no:[res.response_data.mobile_no,[Validators.required,Validators.pattern(this.mobnumPattern)]],
                    address:[res.response_data.address,[Validators.required]],
                    role:[res.response_data.role.id,[Validators.required]],
                    state_id : [res.response_data.state_id],
                    ac_id : [res.response_data.ac_id],
                    pc_id : [res.response_data.pc_id],
                    langauge :this.formBuilder.array(this.check_lang,[Validators.required])  
                });
            }
            this.isLoader=false;
       });
    }
  }
  onCheckboxChange(e) {
    const langauge: FormArray = this.Userfrm.get('langauge') as FormArray;
    if (e.target.checked) 
    {
      langauge.push(new FormControl(e.target.value));
    } 
    else 
    {
       const index = langauge.controls.findIndex(x => x.value === e.target.value);
       langauge.removeAt(index);
    }
  }
  langaugeChecked(lang_id)
  {
    let checked = false;
    if(this.check_lang.length >0)
    {
      for (let l = 0; l < this.check_lang.length; l++){
        let temp = this.check_lang[l];
        if (temp == lang_id)
        {
          checked = true;    
        } 
      }
    }
    return checked;
  }
  get f(){ return this.Userfrm.controls;}
  get formData() { return <FormArray>this.Userfrm.get('language'); }
  userFormsubmit()
  {
    this.isFormsubmit=true;
    if(this.Userfrm.invalid)
    {
      return false;
    }
    this.isLoader=true;
    this.successMessage='';
    this.errorMessage = '';
    if(this.editForm)
    {
      const userData ={
                        'first_name':this.Userfrm.value.first_name,
                        'email':this.Userfrm.value.email,
                        'mobile_no':this.Userfrm.value.mobile_no,
                        'address':this.Userfrm.value.address,
                        'role':this.Userfrm.value.role,
                        'state_id' : this.Userfrm.value.state_id,
                        'ac_id' : this.Userfrm.value.ac_id,
                        'pc_id' : this.Userfrm.value.pc_id,
                        'id':this.userId,
                        'dhananjay':JSON.stringify(this.Userfrm.value.langauge)
                      }
      this.listser.updateUser(userData,this.token).subscribe(res =>{
          if(res.status=='success')
          {
            this.successMessage=res.msg;
            this.isFormsubmit=false;
          }
          else
          {
            this.errorMessage=res.msg;
          }
          this.isLoader=false;
      });
    }
    else
    {
      const userData ={
        'first_name':this.Userfrm.value.first_name,
        'email':this.Userfrm.value.email,
        'mobile_no':this.Userfrm.value.mobile_no,
        'address':this.Userfrm.value.address,
        'role':this.Userfrm.value.role,
        'state_id' : this.Userfrm.value.state_id,
        'ac_id' : this.Userfrm.value.ac_id,
        'pc_id' : this.Userfrm.value.pc_id,
        'dhananjay':JSON.stringify(this.Userfrm.value.langauge)
      }
      this.listser.userSave(userData,this.token).subscribe(res =>{
        if(res.status=='success')
        {
          this.successMessage=res.msg;
          this.Userfrm.reset();
          this.isFormsubmit=false;
        }
        else
        {
          this.errorMessage=res.msg;
        }
        this.isLoader=false;
      });
    }
  }
 
  /*change the state to fetch all pc*/
  onChangeState(state_id : number)
  {
    this.pc_array =[];
    this.listser.getAllpc(this.token,state_id).subscribe(res =>{
      if(res.status=='success')
      {
        this.pc_array = res.response_data;
      }  
    });
  }
  /*change the state to fetch all pc*/
  /*change the state to fetch all pc*/
  onChangePc(state_id : number,pc_id:number)
  {
    console.log(state_id);
    this.listser.getAllAc(this.token,state_id,pc_id).subscribe(res =>{
      if(res.status=='success')
      {
        this.ac_array = res.response_data;
      }  
    });
  }
  /*change the state to fetch all pc*/
}
