import { Component, OnInit } from '@angular/core';
import { SurveyService } from 'src/app/service/survey.service';
import { UserService } from 'src/app/service/user.service';
import { first, count } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { SettingService } from 'src/app/service/setting.service';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.css']
})
export class SurveyDetailsComponent implements OnInit {
  isLoader =true;
  token ="";
  user_id :number;
  editForm = false;
  isDisabled=false;
  survey_id :number;
  surveyRes = [];
  surveyAudio =[];
  getlist ='';
  mobile_number='';
  title =[];
  isFormsubmitAccept =false;
  isFormsubmitReject = false;
  isFormsubmitNew = false;
  isID :number;
  lang_array : [];
  isUserID = '';
  isSuccess='';
  solr_file_for_database_url ='';
  errorMessage='';
  successMessage='';

  sent_data='';
  ivr_row='';
  ivr_detail='';
  ivr_detail_successfull='';
  ivr_client_detail_successfull='';

  sent_data_url='';
  ivr_row_url='';
  ivr_detail_url='';
  ivr_detail_successfull_url='';
  ivr_client_detail_successfull_url='';

  surve_status='';
  admin_type ='';
  summery_count = [];
  summery_available=false;
  aduioId = '';
  surveyCreatedDate:any;
  customer_name='';
  surveyAudioLang='';
  type='';
  list_state='';
  target_sample_size='';
  smspanelbuilder='';
  isBranch='';
  resultArray =[];
  branchLevel=[];
  is_random :any;
  displayStyle = "none";
  variations :any;
  variation_data=[];
  variatioData:any;
  question_no:any;
  SurveyName = '';
  online_click_url:any;
  visble = false;
  constructor(private setting:SettingService,private surveysrv : SurveyService,private route : ActivatedRoute,private listser : UserService,) { }


  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
    this.admin_type = userData.admin_type;
    this.route.params.subscribe((param :  Params)=>{
      this.survey_id = +param['id']
      this.editForm=param['id'] !=null;
      this.isDisabled=true;
      //console.log(this.editmode);
    }); 
    this.surveysrv.detailSurvey(this.token,this.survey_id,this.user_id).pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyRes = survey.response_data.arr_survey;
        this.surveyCreatedDate = survey.response_data.arr_survey.created_at;
        this.surve_status =survey.response_data.arr_survey.status;
        this.solr_file_for_database_url = survey.response_data.arr_survey.solr_file_for_database;
        this.isUserID  =survey.response_data.arr_survey.user_id;
        this.mobile_number = survey.response_data.arr_survey.user_data.user_mobile;
        this.customer_name = survey.response_data.arr_survey.user_data.full_name;
        this.target_sample_size =  survey.response_data.arr_survey.sample_size;
        this.smspanelbuilder = survey.response_data.arr_survey.is_panel_call_for;
        this.isBranch = survey.response_data.arr_survey.is_branch_survey;
        if(survey.response_data.arr_survey.online_click_url_count){
          this.online_click_url = survey.response_data.arr_survey.online_click_url_count;
          this.visble = true;
        }
        const datatitle=this.getSurveyType(this.surveyRes);
        this.type = datatitle[0]['type'];
        this.list_state = datatitle[0]['list_state'];
        this.surveyAudio = survey.response_data.arr_survey.audio_data;
       
        this.is_random = survey.response_data.arr_survey.is_random_enable;
        
        if(this.isBranch=='1')
        {
          this.branchLevel = survey.response_data.arr_survey.level_data;
         
          
        }
        this.surveyAudioLang = survey.response_data.arr_survey.audio_data[0]['audio_language'];
        this.aduioId =  survey.response_data.arr_survey.audio_data[0]['audio_id'];
        if(survey.response_data.arr_survey.total_call_response)
        {
          this.summery_count = survey.response_data.arr_survey.total_call_response;
          this.summery_available=true;
        }
        if(survey.response_data.arr_request)
        {
          for (const getvl in survey.response_data.arr_request)
          {
              if(survey.response_data.arr_request[getvl]['request_type']=='sent_data')
              {
                this.sent_data = survey.response_data.arr_request[getvl]['is_csv_generated'];
                this.sent_data_url = survey.response_data.arr_request[getvl]['download_csv_url'];
              }
              if(survey.response_data.arr_request[getvl]['request_type']=='ivr_row')
              {
                this.ivr_row = survey.response_data.arr_request[getvl]['is_csv_generated'];
                this.ivr_row_url = survey.response_data.arr_request[getvl]['download_csv_url'];
              }
              if(survey.response_data.arr_request[getvl]['request_type']=='ivr_detail')
              {
                this.ivr_detail = survey.response_data.arr_request[getvl]['is_csv_generated'];
                this.ivr_detail_url = survey.response_data.arr_request[getvl]['download_csv_url'];
              }
              if(survey.response_data.arr_request[getvl]['request_type']=='ivr_detail_successfull')
              {
                this.ivr_detail_successfull = survey.response_data.arr_request[getvl]['is_csv_generated'];
                this.ivr_detail_successfull_url = survey.response_data.arr_request[getvl]['download_csv_url'];
              }
              if(survey.response_data.arr_request[getvl]['request_type']=='ivr_client_detail_successfull')
              {
                this.ivr_client_detail_successfull = survey.response_data.arr_request[getvl]['is_csv_generated'];
                this.ivr_client_detail_successfull_url = survey.response_data.arr_request[getvl]['download_csv_url'];
              }
              
          }
        }
        
      }   
      this.isLoader=false;
    });

   

  }
  getSurveyType(survey_response)
  {
    if(survey_response!='')
    {
      const getArray=JSON.parse(survey_response.survey_criteria);
      if(getArray.length>0)
      {
        const type:any=getArray[0].type;
        const mbstring:string =getArray.map(function(o) { return o.name;}).join(", ");
        return  [{'type':type,'list_state':mbstring}];
      } 
    }
    return  [{'type':'','list_state':''}];
  }
  getDataparse(res)
  {
    if(res!='')
    {
      return JSON.parse(res);
    }
  }
  requestData(typedata)
  {
    const requesquest={
        'survey_id':this.survey_id,
        'user_id':this.user_id,
        'type' : typedata
        };
    this.isLoader=true;    
    this.surveysrv.createRequest(this.token,requesquest).subscribe(res=>{
        if(res['status']=='success')
        {
          this.successMessage = res['msg'];
          this.ngOnInit();  
        }
        else
        {
          this.errorMessage = res['msg'];
        }
        this.isLoader=false;
     });
  }
  downloadData(file_url)
  {
    return this.setting.datadownload(file_url,this.user_id);
  }
  openPopup(audio_id) {
    
    this.variation_data = this.surveyAudio;
   
    this.variation_data.forEach((res,index) => {
      if(res.audio_id == audio_id ){
         this.question_no= index;
        this.variatioData = res;
      }
      
    });
    
    // this.displayStyle = "block";
  }
  closeTrack() {
    this.displayStyle = "none";
  }

  openTrack(){
    this.displayStyle = "block";
  } 
  trackSurvey(){
    this.isLoader=true;
    this.surveysrv.trackSurvey(this.token,this.survey_id).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        console.log('Track Survay',survey);
        if(survey.response_data){
          this.resultArray = survey.response_data.records;
          this.SurveyName = survey.response_data.survey_name;

        }

      }
    });
  }

 
}
