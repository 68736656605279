import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SurveyService } from 'src/app/service/survey.service';
import { UserService } from 'src/app/service/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-survey-online',
  templateUrl: './survey-online.component.html',
  styleUrls: ['./survey-online.component.css']
})
export class SurveyOnlineComponent implements OnInit {

  isLoader =true;
  token ="";
  user_id :number;
  editForm = false;
  isDisabled=false;
  survey_id :any;
  surveyRes = [];
  surveyAudio =[];
  getlist ='';
  mobile_number='';
  title =[];
  isFormsubmitAccept =false;
  isFormsubmitReject = false;
  isFormsubmitNew = false;
  isID :number;
  lang_array : [];
  isUserID = '';
  isSuccess=''; 
  mp3_file='';
  csv_file ='';
  demo_csv_file='';
  mp3_error='';
  first_error='';
  third_error ='';
  firstLoader =true;
  submit_audio_id = '';
  isDisabledLang =false;
  language ='';
  isSelectedLang = '';
  isAudioId = '';
  panel_builder_survey_type = '';
  sms_text='';
  isLoaderApprove=false;
  isLoaderReject = false;
  isPanelBuilder = '';
  userName ='';
  sample_size='';
  calc_survey_count='';
  totalCsvCount='';
  solr_file='';
  csvDisable = true;
  displayStyle = "none";
  @ViewChild('fileUploader') fileUploader:ElementRef;
  @ViewChild('closebuttonapprove') closebuttonapprove;
  @ViewChild('closebuttonreject') closebuttonreject;
  constructor(private surveysrv : SurveyService,private route : ActivatedRoute,private listser : UserService,private router : Router) { }

  ngOnInit(): void {
    this.mp3_error='';
    this.first_error='';
    this.third_error ='';
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;

    this.route.params.subscribe((param :  Params)=>{
      this.survey_id = +param['id']
      this.editForm=param['id'] !=null;
      this.isDisabled=true;
      //console.log(this.editmode);
    });
    this.isLoader=false; 
    this.listser.listLanguge(this.token,userData.user_id).pipe(first()).subscribe(lang => {
      if(lang.status=='success')
      {
        this.lang_array = lang.response_data;
        this.firstLoader =false;
      }   
    });
    this.surveysrv.detailSurvey(this.token,this.survey_id,this.user_id).pipe(first()).subscribe(survey => {
      if(survey.status=='success')
      {
        
        this.surveyRes = survey.response_data.arr_survey;
        this.calc_survey_count = survey.response_data.arr_survey.calc_survey_count;
        this.demo_csv_file = survey.response_data.arr_survey.is_demo_csv_used;
        this.solr_file = survey.response_data.arr_survey.solr_file_for_database;
        this.sms_text = survey.response_data.arr_survey.sms_text;
        this.panel_builder_survey_type = survey.response_data.arr_survey.panel_builder_survey_type;
        this.isUserID  =survey.response_data.arr_survey.user_id;
        this.mobile_number = survey.response_data.arr_survey.user_data.user_mobile;
        this.userName = survey.response_data.arr_survey.user_data.full_name;
        this.sample_size = survey.response_data.arr_survey.sample_size;
        this.title=this.getSurveyType(this.surveyRes);
        this.surveyAudio = survey.response_data.arr_survey.audio_data;
        this.totalCsvCount= survey.response_data.arr_survey.csv_records_count;
        console.log('this is question data',this.totalCsvCount)
        console.log(this.surveyRes)
        let pInc = 0;
        for (const getvl in  this.surveyAudio)
        {
          if(this.surveyAudio[getvl]['moderator_status']=='0')
          {
            if(pInc==0)
            {
              this.isAudioId =this.surveyAudio[getvl]['audio_id'];
            }
            pInc++;
          }
          if(this.surveyAudio[getvl]['audio_language'] && this.surveyAudio[getvl]['audio_language']!='')
          {
            if(this.surveyAudio[getvl]['is_text_file_survey']=='1' && this.surveyAudio[getvl]['moderator_status']=='0') 
            {
              this.isDisabledLang=false;
            }
            else
            {
              this.isDisabledLang=true;
            }
            this.language=this.surveyAudio[getvl]['audio_language'];
          }
        }
      }   
      this.isLoader=false;
    });
  }

  getSurveyType(survey_response)
  {
    if(survey_response!='')
    {
      const getArray=JSON.parse(survey_response.survey_criteria);
      if(getArray.length>0)
      {
        const type:any=getArray[0].type;
        const mbstring:string =getArray.map(function(o) { return o.name;}).join(", ");
        return  [{'type':type,'list_state':mbstring}];
        
      } 
    }
    return  [{'type':'','list_state':''}];
  }
  moderatorFormsubmit(form: any,id)
  {
      
      this.isID = id;
      this.isFormsubmitAccept=true;
      if(form.valid)
      {
        let lang_selected ='';
        if(this.language)
        {
          lang_selected = this.language;
        }
        else
        {
          lang_selected = form.value.language;
        }
        let ispanel='';
        if(form.value.isPanelBuilder)
        {
          ispanel = form.value.isPanelBuilder;
        }
        this.isLoader=true;
        this.isFormsubmitAccept=false;
        const submitformdata = {
          'status':'1',
          'survey_id':this.survey_id,
          'audio_id':id,
          'audio_option':form.value.audio_option,
          'audio_language':lang_selected,
          'is_panel_call': ispanel
        }
      
        this.surveysrv.moderatorAction(this.token,submitformdata).subscribe(res =>{
          if(res.status=="success")
          {
            this.ngOnInit();
          }
          else
          {
            this.first_error=res.msg;
            // console.log(res.msg);
          }
          this.isLoader=false;
        });
      }
  }
  FormsubmitReject(form_data: any,id)
  {
      this.isID = id;
      this.isFormsubmitReject=true;
      if(form_data.valid)
      {
        this.isLoader=true;
        this.isFormsubmitAccept=false;
        const submitformdata = {
          'status':'2',
          'survey_id':this.survey_id,
          'audio_id':id,
          'rejection_type':form_data.value.reject_reason,
          'reason':form_data.value.reject_note,
        }
        this.surveysrv.moderatorAction(this.token,submitformdata).subscribe(res =>{
          if(res.status=='success')
          {
            this.ngOnInit();
            
          }
          else
          {
            this.third_error = res.msg;
          }
           this.isLoader=false;
        });
      }
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.mp3_file = event.target.files[0];
    }
  }
  onCsvFileSelect(event) {
    if (event.target.files.length > 0) {
      
      this.csv_file = event.target.files[0];
      this.csvDisable=false;
      
    }
    else{
      this.csvDisable=true;
    }
  }
  csvupload(){
    let formData : FormData = new FormData();
    formData.append('survey_id',this.survey_id);
    formData.append('csv_file',this.csv_file); 
    this.surveysrv.uploadCsv(this.token,formData).subscribe(res =>{
      if(res.status=='success'){
        alert(res.msg);
        this.ngOnInit();
        this.fileUploader.nativeElement.value = null;
      }
    })
  }
  csvDownload(){
    window.open(this.solr_file);
    
  }
  csvDelete(){
    console.log('qwrtyuipdfghjk',this.survey_id)
    if(confirm("Are you sure you want to delete?")){
      this.surveysrv.deleteCsv(this.token,this.survey_id).subscribe(res =>{
        if(res.status=='success'){
          alert(res.msg);
          this.ngOnInit();
        }
      })
    }
   

  }
  formSubmitNewFile(form: any,id,survey_new_id:any)
  {
      this.isID = id;
      this.isFormsubmitNew=true;
      if(form.valid)
      {
        this.isLoader=true;
        const formData: FormData = new FormData();
        formData.append('id',this.isUserID);
        formData.append('survey_id',survey_new_id);
        formData.append('audio_id',id);
        formData.append('audio_file',this.mp3_file);
        formData.append('reason',form.value.frm_note);
        formData.append('more_than_40_sec',form.value.ismorethan);
        formData.append('is_custom_intro_upload',form.value.is_custom_intro_upload);
        this.isFormsubmitNew=false;
        this.surveysrv.moderatorNewfile(this.token,formData).subscribe(res =>{
          if(res.status=='success')
          {
            this.ngOnInit();
          } 
          else
          {
            this.mp3_error=res.msg;
            this.isID = id;
            // console.log(this.isID);
          } 
          this.isLoader=false;
        });
       
        this.isFormsubmitNew=false;
      }
  }
  approveData()
  {
      this.isLoaderApprove=true;
      const submitformdata = {
        'status':'1',
        'survey_id':this.survey_id,
        'audio_id':this.isAudioId
      }
      this.surveysrv.moderatorAction(this.token,submitformdata).subscribe(res =>{
        if(res.status=="success")
        {
          this.ngOnInit();
          this.closebuttonapprove.nativeElement.click();
        }
        else
        {
          this.first_error=res.msg;
          // console.log(res.msg);
        }
        this.isLoaderApprove=false;
      });
  }
  rejectSurvey()
  {
      this.isLoaderReject=true;
      const submitformdata = {
        'status':'2',
        'survey_id':this.survey_id,
        'audio_id':this.isAudioId,
        'rejection_type':'not valid sms templete selected',
        'reason':'Not valid templete selected',
      }
      this.surveysrv.moderatorAction(this.token,submitformdata).subscribe(res =>{
        if(res.status=="success")
        {
          this.ngOnInit();
          this.closebuttonreject.nativeElement.click();
        }
        else
        {
          this.first_error=res.msg;
          // console.log(res.msg);
        }
        this.isLoaderReject=false;
      });
   
  }
  AddWave(AudioID,surveyID)
  {
    this.router.navigate(['/survey/wave/'+AudioID+'/'+surveyID]);
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
