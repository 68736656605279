<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
           
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="card-header">
                <h3 class="card-title">Add Credit ({{ user_name }} - {{ user_mobile }})
                    
                </h3>
                <h3 class="card-title" style="float: right;">Current Balance : {{ current_balance }}</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form [formGroup]="Customerfrm" (ngSubmit)="CustomerFormdebitsubmit();" >
                <div class="card-body">
                    <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                    <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label for="state_name">Debits Credits <span style="color: red;">*</span></label>
                        <input type="text"  formControlName="customer_credit" (change)="PriceCalculation($event)" (keypress)="numberOnly($event)"  class="form-control" id="name" placeholder="Enter Credits">
                        <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.customer_credit.errors">
                          <span *ngIf="f.customer_credit.errors.required" style="color:red;" class="help-block">Please enter credits.</span>
                        </span></p>
                      </div> 
                      <div class="col-sm-12">
                        <label for="state_name">Short Description <span style="color: red;">*</span></label>
                        <input type="text"  formControlName="shortdesc"   class="form-control" id="name" placeholder="short description eg.survey name">
                        <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.shortdesc.errors">
                          <span *ngIf="f.shortdesc.errors.required" style="color:red;" class="help-block">Please enter short description.</span>
                        </span></p>
                      </div>  
                      <div class="col-sm-12">
                        <label for="state_name">Comment</label>
                        <textarea formControlName="comment_payment" class="form-control"></textarea>
                        <p style="margin:0;height:24px;display:block;"><span *ngIf="isFormsubmit && f.comment_payment.errors">
                            <span *ngIf="f.comment_payment.errors.required" style="color:red;" class="help-block">Please enter comment.</span>
                        </span></p>
                      </div>
                    </div>
                 </div>
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>