<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
           
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>
              <div class="card-header">
                <h3 class="card-title">Expert Mode</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form  >
                <div class="card-body">
                    <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                    <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label for="state_name" style="margin-right: 20px;">Expert Mode</label>
                        <label class="switch">
                            <input type="checkbox" [checked]='ischecked' (change)="expertchange($event)">
                            <span class="slider round"></span>
                          </label>
                     </div>  
                     </div>
                 </div>
                <!--card-body-->
                
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>