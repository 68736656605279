import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthModule } from '../auth/auth/auth.module';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  siteUrl = environment.apiUrl;
  constructor(private http:HttpClient,private router: Router) { }
  customerList(token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/user_list',{'testing':1},{headers});
  }
  customerDetails(token,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/user_credit_info',{'front_user_id':user_id},{headers});
  }
  customerAdd(token,arrayInfo)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/add_credits',arrayInfo,{headers});
  }
  customerLog(token,user_ID)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/user_wallet_log',{'front_user_id':user_ID},{headers});
  }
  customerDebite(token,userInfo)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/debite_credits',userInfo,{headers});
  }
  loginAdminUser(token,loginDetails)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/backend_processLogin',loginDetails,{headers});
  }
  userCustomerList(custSearch,token)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/user_list_backend_survey',custSearch,{headers});
  }
  statusChange(token,status,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/extractor_on_off',{'front_user_id':user_id,'status':status},{headers});
  }
  branchChange(token,status,user_id)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/branch_survey_on_off',{'front_user_id':user_id,'status':status},{headers});
  }
  downloadCsv(token,csv_type)
  {
    const headers = { 'Authorization': 'Bearer '+token+''};
    return this.http.post<any>(this.siteUrl+'api/v1/admin/wallet_csv',csv_type,{headers});
  }
  downloadWallet(fileurl,userID)
  {
    return this.siteUrl+'api/v1/admin/csv_download_wallet_csv?csv_url='+fileurl+'&user_id='+userID;
  }
  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
