import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AudioService } from 'src/app/service/audio.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-audio-add',
  templateUrl: './audio-add.component.html',
  styleUrls: ['./audio-add.component.css']
})
export class AudioAddComponent implements OnInit {
  
  Audiofrm : FormGroup;
  isFormsubmit=false;
  isLoader=false;
  state_array = [];
  token ='';
  audioUrl ='';
  fileName ='';
  errorMessage ='';
  successMessage ='';
  audioId:number;
  customeAudioID = '';
  editForm = false;
  isDisabled = false;
  detailsAudioUrl='';
  @ViewChild('audioPlay') audioPlayerRef : ElementRef; 
  constructor(private sanitizer: DomSanitizer,private audiosrv:AudioService ,private listser : UserService,private route : ActivatedRoute,private formBuilder:FormBuilder,private router : Router) { 
  }

  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    /*form validation*/
    this.Audiofrm= this.formBuilder.group({
        audio_file_name : ['',[Validators.required]],
        audio_file:['',[Validators.required]],
        state_id:['',[Validators.required]]
    });
    /*form validation*/
    this.isLoader=true;
    this.listser.getAllstate(this.token).pipe(first()).subscribe(state => {
      if(state.status=='success')
      {
        this.state_array = state.response_data;
      }   
      this.isLoader=false;
    });
    this.route.params.subscribe((param :  Params)=>{
      this.audioId = +param['id']
      this.editForm=param['id'] !=null;
      this.initform();
      this.isDisabled=true;
    }); 
  }
  initform()
  {
    if(this.editForm)
    {
      this.isLoader=true;
      this.audiosrv.getaudioDetails(this.token,this.audioId).pipe(first()).subscribe(res =>{
        this.isLoader=false;
        if(res['status']=='success')
        {
          this.detailsAudioUrl = res['response_data']['custom_audio_file'];
          this.appendAudiofile(this.detailsAudioUrl); 
          this.Audiofrm= this.formBuilder.group({
              audio_file_name : [res['response_data']['custom_audio_name'],[Validators.required]],
              audio_file:[''],
              state_id:[res['response_data']['custom_state_id'],[Validators.required]]
         });
        }
        else
        {
          this.errorMessage = res['msg'];
        }
      });
    }
  }
  get f(){ return this.Audiofrm.controls;}
  fileChangeAudio(event)
  {
      //console.log(event.target.files);
      if(event.target.files && event.target.files[0])
      {
        this.audioUrl=event.target.files[0];
        this.fileName = event.target.files[0].name;
        const audio_file_url = URL.createObjectURL(event.target.files[0]);
        this.appendAudiofile(audio_file_url);
      }
  }
  audioFormsubmit()
  {
    this.isFormsubmit=true;
    if(this.Audiofrm.valid)
    {
      if(this.editForm)
      {
          this.successMessage = this.errorMessage ='';
          this.isLoader =true;
          const formData: FormData = new FormData();
          if(this.audioUrl!='')
          {
            formData.append('custom_audio_file',this.audioUrl);
          }
          formData.append('custom_audio_name',this.Audiofrm.value.audio_file_name);
          formData.append('custom_state_id',this.Audiofrm.value.state_id);
          formData.append('custom_audio_id',this.audioId.toString());
          this.audiosrv.updateAudio(this.token,formData).subscribe(res=>{
            this.isLoader =false;
            if(res['status']=='success')
            {
              this.successMessage = res['msg'];
              this.isFormsubmit=false;
            }
            else
            {
              this.errorMessage = res['msg'];
            }
          });
      }
      else
      {
        this.successMessage = this.errorMessage ='';
        this.isLoader =true;
        const formData: FormData = new FormData();
        formData.append('custom_audio_file',this.audioUrl);
        formData.append('custom_audio_name',this.Audiofrm.value.audio_file_name);
        formData.append('custom_state_id',this.Audiofrm.value.state_id);
        this.audiosrv.addAudio(this.token,formData).subscribe(res=>{
          this.isLoader =false;
          if(res['status']=='success')
          {
            this.successMessage = res['msg'];
            this.Audiofrm.reset();
            this.appendAudiofile('test');
            this.isFormsubmit=false;
          }
          else
          {
            this.errorMessage = res['msg'];
          }
        }); 
      }
      
    }
  }
  appendAudiofile(appendAudio)
  {
    if(appendAudio)
    {
      this.audioPlayerRef.nativeElement.src=appendAudio;
    }  
  }

}
