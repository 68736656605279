import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-extract',
  templateUrl: './data-extract.component.html',
  styleUrls: ['./data-extract.component.css']
})
export class DataExtractComponent implements OnInit {
  
  constructor() { }
  ngOnInit(): void {
  }
}
