import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from '../service/setting.service';

@Component({
  selector: 'app-expert-mode',
  templateUrl: './expert-mode.component.html',
  styleUrls: ['./expert-mode.component.css']
})
export class ExpertModeComponent implements OnInit {
 
  isFormsubmit=false;
  isLoader=false;
  token ='';
  errorMessage ='';
  successMessage ='';
  ischecked:boolean;
  constructor(private route : ActivatedRoute,private setting : SettingService) { }

  ngOnInit(): void {

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.isFormsubmit=false;
    const check_find = {
      'status' : 2
    };
    this.successMessage ='';
    this.errorMessage ='';
    this.setting.expertMode(this.token,check_find).subscribe(res=>{
      if(res['status']=='success')
      {
        if(res['response_data']['is_extractor_on']=='0')
        {
         this.ischecked = false;
        }
        else
        {
          this.ischecked = true;
        }  
      }
      else
      {
        this.errorMessage = res['msg'];
      }
    });

  }
  expertchange(event)
  {
      this.isLoader =true;
      var is_status ='';
      if(event.target.checked)
      {
        is_status = '1'
      }
      else
      {
         is_status = '0'
      }
      const check_array = {
        'status' : is_status
      };
      this.successMessage ='';
      this.errorMessage ='';
      this.setting.expertMode(this.token,check_array).subscribe(res=>{
          if(res['status']=='success')
          {
            this.successMessage =res['msg']; 
          }
          else
          {
            this.errorMessage = res['msg'];
          }
          this.isLoader =false;
      });
  }
}
