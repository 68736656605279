import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from 'src/app/service/survey.service';
import { SettingService } from 'src/app/service/setting.service';

@Component({
  selector: 'app-survey-log',
  templateUrl: './survey-log.component.html',
  styleUrls: ['./survey-log.component.css']
})
export class SurveyLogComponent implements OnInit {
  surveyLogListArray=[];
  isLoader =true;
  user_id :number;
  token='';
  @ViewChild(DataTableDirective)
  dtElement : DataTableDirective;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  errorMessage='';
  successMessage='';
  constructor(private surveysrv : SurveyService,private router :Router,private setting : SettingService) { }

  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;

    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [4],
        orderable :false
      }]
    };
    
    this.surveysrv.listLog(this.token,{'user_id':this.user_id}).pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyLogListArray = survey.response_data;
        this.dtTrigger.next();
      }   
    });
  }

}
