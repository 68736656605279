  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
    <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header">
                 <h3 class="card-title"  style="margin-right: 100px;" >Today Survey Log</h3>
               </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="row">
                   <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                   <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                 </div>
                
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                    <tr>
                        <th>Sr.No</th> 
                        <th>Survey Name</th>
                        <th>Sample Size</th>
                        <th>Pull data</th>
                        <th>Error</th>
                        <th>Process Name</th>
                        <th>Process Start</th>
                        <th>Process End</th>
                        <th>Status</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr *ngFor="let log of surveyLogListArray; let i = index" class="tr_success">
                        <td>{{ i+1 }}</td> 
                        <td>{{ log.survey_name}}</td>
                        <td>{{ log.sample_size}}</td>
                        <td>{{ log.pull_data }}</td>
                        <td>{{ log.error_descp }}</td>
                        <td>{{ log.process_slug }}</td>
                        <td>{{ log.process_start_date_time }}</td>
                        <td>{{ log.process_end_date_time }}</td>
                        <td>
                            <span *ngIf="log.status=='1'">Fail</span>
                            <span *ngIf="log.status=='2'">Success</span>
                        </td>
                    </tr>
                   <tr *ngIf="surveyLogListArray.length==0">
                     <td colspan="8">No Record Found</td>
                   </tr>
                   </tbody>
                 </table>
                </div>
               </div>
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->
   