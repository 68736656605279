import { Component, OnInit } from '@angular/core';
import { LoginService } from './service/login.service';
import { Router,NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'prashnam-admin';
  checklogin:number = 0;
  loginPage :boolean;
 
  constructor(private loginsrv : LoginService,private router : Router){
  
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // console.log('URL',event['url']);
        if (event['url'] == '/' || event['url'] =='/verify-otp') {
          this.loginPage = false;
        } else {
          let body = document.getElementsByTagName('body')[0];
          body.classList.remove('login-page');
          this.loginPage = true;
        }
      }
    });
  }
  ngOnInit(){
    this.loginsrv.autoLogin();    
  }

  ngAfterContentInit(){
    this.loginPage = true;
  }

  
}
