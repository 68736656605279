import { Component,ChangeDetectorRef, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as AdminLte from 'admin-lte';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { DomSanitizer} from '@angular/platform-browser';
import { SurveyService } from 'src/app/service/survey.service';
import { UserService } from 'src/app/service/user.service';
import { first, count } from 'rxjs/operators';
import * as _jQueryInterface from "jquery";



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loginPage =false;
  wave: WaveSurfer;
  resultArray =[];
  url_data='';
 // url = "https://ia800508.us.archive.org/15/items/LoveThemeFromTheGodfather/02LoveThemeFromTheGodfather.mp3";
 // url ='../assets/61ee5b8557299_survey.mp3';
  //url='http://prashnam.datagrid.co.in/uploads/users/268/2022/2/beep/62021529c9e9b_survey.mp3';
  constructor(private cdr: ChangeDetectorRef,private surveysrv : SurveyService) { }
  ngAfterViewInit(){
    
      $('[data-widget="treeview"]').each(function() {
        // AdminLte.Treeview._jQueryInterface.call($(this), 'init');
      });
    
    $(document).on('click','[data-widget="treeview"] .nav-link', function (e) {
      e.stopImmediatePropagation();
       });
    
  // $('[data-widget="pushmenu"]').each(function() {
  //     AdminLte.PushMenu._jQueryInterface.call($(this),'expand');
  //   });
  }
  ngOnInit(): void {
   
     const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    const token = userData.token;
    const user_id = userData.user_id;
    const admin_type = userData.admin_type;
    /*this.surveysrv.detailSurvey(token,'4029',user_id).pipe(first()).subscribe(survey => {
    
      if(survey.status=='success')
      {
        this.url_data=survey.response_data.arr_survey.audio_data[0].original_bip_file;
       // var blob = new Blob([this.url,'audio/mp3']);
        //console.log(blob);
        //this.getAudio();
      }
    }); */
   
  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wave = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'violet',
        progressColor: 'purple',
        responsive:true,
        backend: 'MediaElement',
        plugins: [
          TimelinePlugin.create({
            container: '#wave-timeline'
          }),
          Regions.create({
            regions: [
              {
                start:0,
                end: 0.9,
                loop: false,
                color: 'hsla(400, 100%, 30%, 0.5)'
              }
             ],
            dragSelection: {
              slop: 1
            },
            loopSelection:false
          })
        ],
        xhr: {
          credentials: 'same-origin',
          cache: "default",
          crossOrigin: 'anonymous',
          method: "GET",
          responseType:'arraybuffer',
        }
      
      });
      /*this.wave.on('ready', () => {
        this.wave.play();
      });*/
      this.wave.on("error", function(error){
        console.log("error = ", error);
      });
    });
  }
  addReligion()
  {
      let region = Object.values(this.wave.regions.list)[0];
      const regions =this.wave.regions.list;
      this.resultArray = Object.keys(this.wave.regions.list).map(function(personNamedIndex){
          console.log(personNamedIndex);
          let person = regions[personNamedIndex];
          return person;
      });
      console.log(this.resultArray);
  }
  removeData()
  {
    this.wave.clearRegions();
    this.addReligion();
  }
  playAudioReligion(playID)
  {
    let region = Object.values(this.wave.regions.list)[playID];
    this.wave.play(region['start'],region['end']);
  }
  getAudio()
  {
     
      if (!this.wave) {
        this.generateWaveform();
      }
      this.cdr.detectChanges();
      const get_url = this.url_data
      
      Promise.resolve().then(() => this.wave.load(this.url_data,[0.0218, 0.0183, 0.0165, 0.0198, 0.2137]));
  }
  onStopPressed(): void {
    this.wave.stop();
  }
  playAudio()
  {
    console.log('testing');
    this.wave.play();
  }
  stopAudio()
  {
    this.wave.playPause();
  }
  
  
}
