import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/login.service';
import * as _jQueryInterface from "jquery";
// const $ = JQuery.default;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  admin_type = '';
  user_name = '';
  constructor(private Login:LoginService) { }

  ngOnInit(): void {
    if(localStorage.getItem('userData'))
    {
        const userData:{
          email:string,
          mobile_no:string,
          user_id:number,
          full_name : string,
          admin_type:string,
          token:string
        } 
        =JSON.parse(localStorage.getItem('userData'));
        if(localStorage.length >0)
        {
        this.admin_type = userData.admin_type;
        this.user_name = userData.full_name;
        }  
    }
  }
  logout()
  {
    this.Login.logout();
  }
 

}
