import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/service/survey.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-survey-pending',
  templateUrl: './survey-pending.component.html',
  styleUrls: ['./survey-pending.component.css']
})
export class SurveyPendingComponent implements OnInit {
  isLoader =true;
  dtOptions: DataTables.Settings={};
  dtTrigger : Subject<any> = new Subject();
  token ="";
  user_id :number;
  surveyArray = [];
  constructor(private surveysrv : SurveyService,private router :Router) { 

    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
  }
  ngOnInit(): void {
    this.dtOptions= {
      pagingType : 'full_numbers',
      pageLength : 50,
      processing : false,
      columnDefs : [{ 
        targets : [7],
        orderable :false
      }]

      
    };
    this.surveysrv.listSurvey(this.token,this.user_id,'2').pipe(first()).subscribe(survey => {
      this.isLoader=false;
      if(survey.status=='success')
      {
        this.surveyArray = survey.response_data.arr_survey;
        console.log('this is survey data',this.surveyArray)
        this.dtTrigger.next();
      }   
    });
  }
  redirect(id)
  {
   
    this.router.navigate(['survey/'+id+'/edit']);
    return true;
  }

}
