<style>
  .survey-status-box {
    min-height: 60px;
  }

  .survey-status-box .info-box-content h4 {
    margin-bottom: 0
  }
</style>
<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Project Detail</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Project Detail</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <app-loader *ngIf="isLoader"></app-loader>
    <div class="card">
      <div class="card-body">
        <div class="summery-count-wrap">
          <h2>Campaign Summary</h2>
          <div class="wave_btn mb-3 ms-10 btn-float-right">
            <a class="btn btn-md btn-info px-5 "  href="javascript:void(0);" data-toggle="modal" data-target="#trackData" (click)="trackSurvey()"><i class="fa fa-eye"></i>Track Survey</a>
          </div>
          <div
              class="modal trackList"
              role="dialog"
              id="trackData">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <h4 class="modal-title">Tracking Details of {{SurveyName}}</h4>
                    <div class="li_audio">
                         <app-loader *ngIf="isLoader"></app-loader>

                      <table class="table responsive table-bordered table-striped" >
                          <thead>
                          <tr>
                          <th>Sr.No</th> 
                          <th>Steps</th>
                          <th>Date</th>
                          </tr>
                          </thead>
                        
                          <tbody >
                          <tr *ngFor="let res of resultArray; let i = index">
                              <td><div class="startend_time">{{i+1}}</div></td>
                              <td><div class="start_time">{{res.step}}</div></td>
                              <td><div class="start_time">{{res.created_at | date: 'dd/MM/yyyy h:mm:ss a'}}</div></td>    
                          </tr>
                          <tr *ngIf="resultArray.length==0">
                            <td colspan="7">No Record Found</td>
                          </tr>
                          </tbody>
                        
                       
                      </table>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div> 
          <ul>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Customer Name </th>
                  <td width="30%" align="right">{{ customer_name }}</td>
                </tr>
              </table>
            </li>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Customer No </th>
                  <td width="30%" align="right">{{ mobile_number }}</td>
                </tr>
              </table>
            </li>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Language </th>
                  <td width="30%" align="right" *ngIf="surveyAudioLang">{{ surveyAudioLang }}</td>
                  <td width="30%" align="right" *ngIf="!surveyAudioLang">NA</td>
                </tr>
              </table>
            </li>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Date </th>
                  <td width="30%" align="right">{{ surveyCreatedDate }}</td>
                </tr>
              </table>
            </li>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Type {{ type | titlecase }}</th>
                  <td width="30%" align="right">{{ list_state }}</td>
                </tr>
              </table>
            </li>
            <div *ngIf="summery_available">
              <li *ngFor="let scount of getDataparse(summery_count[aduioId])">
                <table class="table">
                  <tr>
                    <th width="70%">{{ scount.name }}</th>
                    <td width="30%" align="right" *ngIf="scount.count!='0'">{{ scount.count | number :'1.0-0':'en-IN' }}
                    </td>
                    <td width="30%" align="right" *ngIf="scount.count=='0'">NA</td>
                  </tr>
                </table>
              </li>
            </div>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Target Sample Size</th>
                  <td width="30%" align="right">{{ target_sample_size }}</td>
                </tr>
              </table>
            </li>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Panel Builder SMS Type</th>
                  <td width="30%" align="right">{{ smspanelbuilder }}</td>
                </tr>
              </table>
            </li>
            <li >
              <table class="table">
                <tr>
                  <th width="70%">Online Click Url Count</th>
                  <td width="30%" align="right" >{{online_click_url}}</td>             
                </tr>
              </table>
            </li>

          </ul>  
                  
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
        <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
        <div class="summery-count-wrap">
          <h2>Downloads</h2>
          <ul>
            <li>
              <table class="table">
                <tr>
                  <th width="70%">Sent Data </th>
                  <td width="30%" align="right" *ngIf="sent_data==''"><a href="javascript:void(0);"
                      (click)="requestData('sent_data')"><strong class="f-w">Request</strong></a></td>
                  <td width="30%" align="right" *ngIf="sent_data=='0'"><a href="javascript:void(0);"><strong
                        class="f-w">In-progress</strong></a></td>
                  <td width="30%" align="right" *ngIf="sent_data=='1'"><a href="{{downloadData(sent_data_url)}}"><strong
                        class="f-w">Download</strong></a></td>
                </tr>
              </table>
            </li>
            <div *ngIf="surve_status=='4' && (admin_type=='superadmin' || admin_type=='admin')">
              <li>
                <table class="table">
                  <tr>
                    <th width="70%">IVR Raw </th>
                    <td width="30%" align="right" *ngIf="ivr_row=='' && surve_status=='4'"><a href="javascript:void(0);"
                        (click)="requestData('ivr_row')"><strong class="f-w">Request</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_row=='0' && surve_status=='4'"><a
                        href="javascript:void(0);"><strong class="f-w">In-progress</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_row=='1' && surve_status=='4'"><a
                        href="{{downloadData(ivr_row_url)}}"><strong class="f-w">Download</strong></a></td>
                  </tr>
                </table>
              </li>
              <li>
                <table class="table">
                  <tr>
                    <th width="70%">Detail </th>
                    <td width="30%" align="right" *ngIf="ivr_detail==''"><a href="javascript:void(0);"
                        (click)="requestData('ivr_detail')"><strong class="f-w">Request</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_detail=='0'"><a href="javascript:void(0);"><strong
                          class="f-w">In-progress</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_detail=='1'"><a
                        href="{{downloadData(ivr_detail_url)}}"><strong class="f-w">Download</strong></a></td>
                  </tr>
                </table>
              </li>
              <li>
                <table class="table">
                  <tr>
                    <th width="70%">Detail Successful </th>
                    <td width="30%" align="right" *ngIf="ivr_detail_successfull==''"><a href="javascript:void(0);"
                        (click)="requestData('ivr_detail_successfull')"><strong class="f-w">Request</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_detail_successfull=='0'"><a
                        href="javascript:void(0);"><strong class="w-f">In-progress</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_detail_successfull=='1'"><a
                        href="{{downloadData(ivr_detail_successfull_url)}}"><strong class="f-w">Download</strong></a>
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <table class="table">
                  <tr>
                    <th width="70%">Client </th>
                    <td width="30%" align="right" *ngIf="ivr_client_detail_successfull==''"><a
                        href="javascript:void(0);" (click)="requestData('ivr_client_detail_successfull')"><strong
                          class="f-w">Request</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_client_detail_successfull=='0'"><a
                        href="javascript:void(0);"><strong class="f-w">In-progress</strong></a></td>
                    <td width="30%" align="right" *ngIf="ivr_client_detail_successfull=='1'"><a
                        href="{{downloadData(ivr_client_detail_successfull_url)}}"><strong
                          class="f-w">Download</strong></a></td>
                  </tr>
                </table>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">

        <div class="summery-count-wrap" *ngIf="isBranch=='1'">
          <div class="col-lg-12 p-0">
            <h2>Questions</h2>
          </div>
          <div class="qa_colap_wrap">
            <div *ngFor="let lev of branchLevel;let i = index">
              <div class="qa_colap_head">
                <h2>Level {{ i+1 }} </h2><span>{{ lev.total_count }}</span>
              </div>
              <div [ngClass]="{'collapsed-card': i>'0'}" class="card new_cart"
                *ngFor="let au of lev.data_audio;let p = index;">
                <div class="card-header">
                  <div class="row">
                    <div class="col-6 col-md-3 col-lg-3 p-1"><strong class="f-w">Question </strong>{{ p+1 }}</div>
                    <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.count_of_responses"><strong class="f-w">Valid
                        Reponses :</strong>{{ au.count_of_responses }}</div>
                    <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="!au.count_of_responses"><strong class="f-w">Valid
                        Reponses :</strong>NA</div>
                    <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.audio_option!=''"><strong class="f-w">Options :
                        {{ au.audio_option }}</strong></div>
                    <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.audio_option==''"><strong class="f-w">Options :
                      </strong>NA</div>
                    <!--<div class="col-6 col-md-1 col-lg-1 p-1"><a href="{{solr_file_for_database_url}}" download><strong>Download</strong></a></div>-->
                    <!--<div class="col-5 col-md-3 col-lg-3 p-1 text-right"><strong>Customer No :</strong> {{ mobile_number }}</div>-->
                    <div class="card-tools col-6 col-md-3 col-lg-3 p-1 text-right">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                        title="Collapse">
                        <i class="fas fa-minus"></i></button>
                      <!--<button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>-->
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 mb-3">
                      <audio controls style="width: 100%;">
                        <source src="{{au.original_bip_file}}" type="audio/ogg">
                        <source src="{{au.original_bip_file}}" type="audio/mpeg">
                        Your browser does not support the audio tag.
                      </audio>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12" *ngIf="au.moderator_status=='1'">
                      <div class="card card-success">
                        <div class="card-header with-border">
                          <h3 class="card-title"><i class="fas fa-check"></i> Approved</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- form start -->
                        <!--<div class="card-body" >
                              <div class="row"> 
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Language </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_language }} </span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Option</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_option }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Date</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.date }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4" *ngIf="au.reason!=null">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Reason </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.reason }}</span>
                                      </div>
                                      </div>
                                  </div>
                               </div>
                            </div>-->
                      </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12" *ngIf="au.moderator_status=='2'">
                      <div class="card card-danger">
                        <div class="card-header with-border">
                          <h3 class="card-title"><i class="fas fa-times"></i> Rejected</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- form start -->
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="info-box bg-light">
                                <div class="info-box-content">
                                  <span class="info-box-text text-center text-muted"><strong>Reason for
                                      Rejection:</strong></span>
                                  <span class="info-box-number text-center text-muted mb-0">{{ au.rejection_type }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="info-box bg-light">
                                <div class="info-box-content">
                                  <span class="info-box-text text-center text-muted"><strong>Rejection Notes:</strong>
                                  </span>
                                  <span class="info-box-number text-center text-muted mb-0">{{ au.reason }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="info-box bg-light">
                                <div class="info-box-content">
                                  <span class="info-box-text text-center text-muted"><strong>Date</strong> </span>
                                  <span class="info-box-number text-center text-muted mb-0">{{ au.date }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="summery-count-wrap" *ngIf="isBranch=='0'">
          <div class="col-lg-12 p-0">
            <h2>Questions</h2>
          </div>
          <div class="qa_colap_wrap">
            <div *ngFor="let au of surveyAudio;let p = index" [ngClass]="{'collapsed-card': p>'0'}"
              class="card new_cart">
              <div class="card-header">
                <div class="row">
                  <div class="col-6 col-md-3 col-lg-3 p-1"><strong class="f-w">Question </strong>{{ p+1 }}</div>
                  <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.count_of_responses"><strong class="f-w">Valid
                      Reponses :</strong>{{ au.count_of_responses }}</div>
                  <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="!au.count_of_responses"><strong class="f-w">Valid
                      Reponses :</strong>NA</div>
                  <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.audio_option!=''"><strong class="f-w">Options : {{
                      au.audio_option }}</strong></div>
                  <div class="col-6 col-md-3 col-lg-3 p-1" *ngIf="au.audio_option==''"><strong class="f-w">Options :
                    </strong>NA</div>
                  <!--<div class="col-6 col-md-1 col-lg-1 p-1"><a href="{{solr_file_for_database_url}}" download><strong>Download</strong></a></div>-->
                  <!--<div class="col-5 col-md-3 col-lg-3 p-1 text-right"><strong>Customer No :</strong> {{ mobile_number }}</div>-->
                  <div class="card-tools col-6 col-md-3 col-lg-3 p-1 text-right">
                    <div class="wave_btn" *ngIf="is_random == 1">
                      <!-- <a class="view_wave_btn"  href="javascript:void(0);" (click)="openPopup(au.audio_id)"><i class="fa fa-eye"></i></a> -->
                      <!-- <a class="view_wave_btn" href="javascript:void(0);" data-toggle="modal"
                        data-target="#exampleModal" (click)="openPopup(au.audio_id)"><i class="fa fa-eye"></i></a> -->
                      <a class="view_wave_btn" href="javascript:void(0);" data-toggle="modal" data-target="#audioData"
                        (click)="openPopup(au.audio_id)"><i class="fa fa-eye"></i></a>
                    </div>
                    <div class="modal List_of_segments" id="audioData" role="dialog" aria-labelledby="audioDataLabel"
                      aria-hidden="true" [ngStyle]="{'display':displayStyle}" *ngIf="variatioData">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4 class="modal-title">List of segments</h4>
                            <div class="model-list-segments">
                              <div class="model-list-segments-header">
                                <div class="row">
                                  <div class="col-4 col-md-4 col-lg-4 p-1"><strong class="f-w">Question
                                    </strong>{{question_no+1}} </div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.count_of_responses">
                                    <strong class="f-w">Valid Reponses :</strong>{{ variatioData.count_of_responses }}
                                  </div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="!variatioData.count_of_responses">
                                    <strong class="f-w">Valid Reponses :</strong>NA</div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.audio_option!=''"><strong
                                      class="f-w">Options : {{ variatioData.audio_option }}</strong></div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.audio_option==''"><strong
                                      class="f-w">Options : </strong>NA</div>
                                </div>
                              </div>

                              <div class="model-list-segments-body">
                                <div class="row">
                                  <div class="li_audio" *ngFor="let vari of variatioData.variations;">
                                    <h6>{{ vari.variation }}</h6>
                                    <audio controls style="width: 100%;">
                                      <source src="{{vari.link}}" type="audio/ogg">
                                      <source src="{{vari.link}}" type="audio/mpeg">
                                      Your browser does not support the audio tag.
                                    </audio>

                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                      title="Collapse">
                      <i class="fas fa-minus"></i></button>
                    <!--<button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>-->
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12 mb-3">

                    <audio controls style="width: 100%;" *ngIf="!au.text_question">
                      <source src="{{au.original_bip_file}}" type="audio/ogg">
                      <source src="{{au.original_bip_file}}" type="audio/mpeg">
                      Your browser does not support the audio tag.
                    </audio>
                    <div class="que-main-wraper" *ngIf="au.text_question">
                      <p>Question : {{ au.text_question}}</p>
                      <ul *ngFor="let option of au.text_question_options; let i = index">
                        <li id="options" *ngIf="option;"> {{i+1}}.{{ option }}</li>
                      </ul>

                    </div>
                  </div>
                  <div class="col-12 col-md-12 col-lg-12" *ngIf="au.moderator_status=='1'">
                    <div class="card card-success">
                      <div class="card-header with-border">
                        <h3 class="card-title"><i class="fas fa-check"></i> Approved</h3>
                      </div>
                      <!-- /.box-header -->
                      <!-- form start -->
                      <!--<div class="card-body" >
                              <div class="row"> 
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Language </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_language }} </span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Option</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.audio_option }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Date</strong> </span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.date }}</span>
                                      </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-4" *ngIf="au.reason!=null">
                                      <div class="info-box bg-light">
                                      <div class="info-box-content">
                                          <span class="info-box-text text-center text-muted"><strong>Reason </strong></span>
                                          <span class="info-box-number text-center text-muted mb-0">{{ au.reason }}</span>
                                      </div>
                                      </div>
                                  </div>
                               </div>
                            </div>-->
                    </div>
                  </div>
                  <div class="col-12 col-md-12 col-lg-12" *ngIf="au.moderator_status=='2'">
                    <div class="card card-danger">
                      <div class="card-header with-border">
                        <h3 class="card-title"><i class="fas fa-times"></i> Rejected</h3>
                      </div>
                      <!-- /.box-header -->
                      <!-- form start -->
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="info-box bg-light">
                              <div class="info-box-content">
                                <span class="info-box-text text-center text-muted"><strong class="f-w">Reason for
                                    Rejection:</strong></span>
                                <span class="info-box-number text-center text-muted mb-0 f-w">{{ au.rejection_type }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="info-box bg-light">
                              <div class="info-box-content">
                                <span class="info-box-text text-center text-muted"><strong class="f-w">Rejection
                                    Notes:</strong> </span>
                                <span class="info-box-number text-center text-muted mb-0 f-w">{{ au.reason }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="info-box bg-light">
                              <div class="info-box-content">
                                <span class="info-box-text text-center text-muted"><strong class="f-w">Date</strong>
                                </span>
                                <span class="info-box-number text-center text-muted mb-0 f-w">{{ au.date }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- Audio Data modal-->

            <!-- <div class="modal List_of_segments" id="audioData" role="dialog"
                      aria-labelledby="audioDataLabel" aria-hidden="true" [ngStyle]="{'display':displayStyle}" *ngIf="variatioData">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content" >
                          <div class="modal-body">
                            <h4 class="modal-title">List of segments</h4>
                            <div class="model-list-segments">
                              <div class="model-list-segments-header">
                                <div class="row">
                                  <div class="col-4 col-md-4 col-lg-4 p-1"><strong class="f-w">Question </strong>{{1}} </div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.count_of_responses"><strong
                                      class="f-w">Valid Reponses :</strong>{{ variatioData.count_of_responses }}</div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="!variatioData.count_of_responses"><strong
                                      class="f-w">Valid Reponses :</strong>NA</div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.audio_option!=''"><strong
                                      class="f-w">Options : {{ variatioData.audio_option }}</strong></div>
                                  <div class="col-4 col-md-4 col-lg-4 p-1" *ngIf="variatioData.audio_option==''"><strong
                                      class="f-w">Options : </strong>NA</div>
                                </div>
                              </div>

                              <div class="model-list-segments-body">
                                <div class="row">
                                  <div class="li_audio" *ngFor="let vari of variatioData.variations;">
                                    <h6>{{ vari.variation }}</h6>
                                    <audio controls style="width: 100%;">
                                      <source src="{{vari.link}}" type="audio/ogg">
                                      <source src="{{vari.link}}" type="audio/mpeg">
                                      Your browser does not support the audio tag.
                                    </audio>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" >Close</button>
                          </div>
                        </div>
                      </div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
          <h4>Approve</h4>
          <form method="POST" #f="ngForm" (ngSubmit)="moderatorFormsubmit(f,au.audio_id)">
           
          </form>
        </div> -->
    <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
          <h4>New Version</h4>
          <form method="POST"  #c="ngForm" (ngSubmit)="formSubmitNewFile(c,au.audio_id,survey_id)">
           
         </form>
        </div> -->
    <!-- <div class="col-4 col-md-4 col-lg-4 px-4" *ngIf="au.moderator_status=='0'">
          <h4>Rejected</h4>
          <form method="POST"  #rej="ngForm" (ngSubmit)="FormsubmitReject(rej,au.audio_id)">
  
          </form>
        </div> -->

  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->