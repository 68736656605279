import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  siteUrl = environment.apiUrl;
  constructor(
    private http:HttpClient,
    private router: Router
  ) { }

  addStep(token,data){
    const headers = { 'Authorization': 'Bearer '+token+''};
    // return this.http.post<any>(this.siteUrl+'api/v1/admin/submit_online_question',data,{headers});
       return this.http.post<any>(this.siteUrl+'api/v1/admin/submit_online_question',data,{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));

  }
  getQuestion(token,questionId){
    const headers = { 'Authorization': 'Bearer '+token+''};
    // return this.http.post<any>(this.siteUrl+'api/v1/admin/get_online_question',{'question_id': questionId},{headers});
       return this.http.post<any>(this.siteUrl+'api/v1/admin/get_online_question',{'question_id': questionId},{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));
  }
  listSteps(token,surveId){
    const headers = { 'Authorization': 'Bearer '+token+''};
    // return this.http.post<any>(this.siteUrl+'api/v1/admin/online_questions_list',{'survey_id' : surveId, 'token':token},{headers});
       return this.http.post<any>(this.siteUrl+'api/v1/admin/online_questions_list',{'survey_id' : surveId, 'token':token},{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));

  }
  deleteStep(token,questionId){
    const headers = { 'Authorization': 'Bearer '+token+''};
   // return this.http.post<any>(this.siteUrl+'api/v1/admin/delete__online_question',{'question_id': questionId},{headers});
      return this.http.post<any>(this.siteUrl+'api/v1/admin/delete__online_question',{'question_id' : questionId, 'token':token},{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));
  }
  getMasterOfSteps(token,surveId){
    const headers = { 'Authorization': 'Bearer '+token+''};
    // return this.http.post<any>(this.siteUrl+'api/v1/admin/add_online_question',{'survey_id' : surveId, 'token':token},{headers});
       return this.http.post<any>(this.siteUrl+'api/v1/admin/add_online_question',{'survey_id' : surveId, 'token':token},{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));

  }
  getQuestions(token,surveId,language_id,questionId){
    const headers = { 'Authorization': 'Bearer '+token+''};
    //return this.http.post<any>(this.siteUrl+'api/v1/admin/get_reference_question',{'survey_id' : surveId,'language_id' : language_id, 'token':token,'question_id': questionId },{headers});
      return this.http.post<any>(this.siteUrl+'api/v1/admin/get_reference_question',{'survey_id' : surveId,'language_id' : language_id, 'token':token,'question_id': questionId },{headers}).pipe(catchError(this.handleError),tap(responseData=>{}));
  }

  private handleError(errorRes :HttpErrorResponse)
  {
      let errorMessage = "Something is wrong please try again";
      console.log(errorRes);
      if(!errorRes.message)
      {
        return throwError(errorMessage);
      } 
      return throwError(errorMessage);
  } 
}
