<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Form</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add Sample User</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3"></div>
          <!-- left column -->
          <div class="col-md-7">
            <!-- general form elements -->
            <div class="card card-primary">
              <app-loader *ngIf="isLoader"></app-loader>

              <div class="card-header">
                <h3 class="card-title">Add Sample User</h3>
               
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form [formGroup]="addSample" (ngSubmit)="sampleFormsubmit();">
                <div class="card-body">
                   <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                  <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                  <div class="form-group">
                    <label for="name">Name <span style="color: red;">*</span></label>
                    <input type="text" formControlName="first_name" class="form-control" id="name" placeholder="Enter Name">
                    <span *ngIf="isFormsubmit && f.first_name.errors">
                      <span *ngIf="f.first_name.errors.required" style="color:red;" class="help-block">Please enter name.</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="mobile_no">Mobile No <span style="color: red;">*</span></label>
                    <input type="text" maxlength="10" formControlName="mobile_no" class="form-control" id="mobile_no" placeholder="Mobile number">
                    <span *ngIf="isFormsubmit && f.mobile_no.errors">
                      <span *ngIf="f.mobile_no.errors.required" style="color:red;" class="help-block">Please enter mobile no.</span>
                      <span style='color:red;' *ngIf="f.mobile_no.errors.pattern" >Please enter valid mobile number</span>
                    </span>
                  </div>
                </div>
                <!--card-body-->
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
         </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>