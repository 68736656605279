import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../service/setting.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.css']
})
export class DataFormComponent implements OnInit {

  Datafrm : FormGroup;
  isLoader = false;
  isFormsubmit=false;
  errorMessage='';
  successMessage='';
  token='';
  state_list = [];
  isShow = true;
  district_list=[];
  pc_list=[];
  ac_list=[];
  select_state_id ='';
  isPincode =false;
  csv_file_url='';
  pincod_list=[];
  hideDiv=true;
  disable = true;
  type_check='';
  file_url_data='';
  req_sample='';
  upload_data = '';
  state_url ='';
  userID:number;
  AreaType:any =[];
  area_Type_check=true;
  constructor(private setting:SettingService,private formBuilder:FormBuilder,) { }
  ngOnInit(): void {
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } 
    =JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.userID = userData.user_id;
    const check_find = {
      'status' : 1
    };
    this.setting.stateList(this.token,check_find).subscribe(res =>{
      if(res['status']=='success')
      {
        this.state_list=res.response_data;
      }
    });
    this.isFormsubmit=false;
    /*form validation*/
    this.Datafrm= this.formBuilder.group({
      state_data : [null,[Validators.required]],
      sample_size :['',''],
      district_data : ['',''],
      pc_data : ['',''],
      ac_data : ['',''],
      pincode_data : ['',''],
      per_record : ['',''],
      radio_type : ['',''],
      detailed_option:['',''],
      by_pass:['',''],
      area_type:['','']
     });
    /*form validation*/
  }
  get f(){ return this.Datafrm.controls;}
  selectState(getevent)
  {
    this.isPincode=false;
    if(getevent)
    {
      this.select_state_id = getevent;
      this.state_url = this.setting.downloadsample('state_'+getevent+'.csv');
      const check_district = {
        'state_id' : getevent
      };
      if(getevent=='28'|| getevent=='43' || getevent=='60')
      {
        this.isPincode=true;
        this.setting.getPincode(this.token,check_district).subscribe(res =>{
          if(res['status']=='success')
          {
            this.pincod_list=res.response_data.state_list;
          }
        });
      }
     
      
      this.setting.getDistrict(this.token,check_district).subscribe(res =>{
          if(res['status']=='success')
          {
            this.district_list=res.response_data;
          }
      });
      const check_pc = {
        'state_id' : getevent,
        'district_no': ''
      }; 
     this.setting.getPc(this.token,check_pc).subscribe(res =>{
        if(res['status']=='success')
        {
          this.pc_list=res.response_data;
        }
      });   
     const check_ac = {
        'state_id' : getevent,
        'district_no': '',
        'pc_no':''
      }; 
      this.setting.getAc(this.token,check_ac).subscribe(res =>{
        if(res['status']=='success')
        {
          this.ac_list=res.response_data.state_list;
        }
      });
    }
  }
  getDitrict(geteventDist)
  {
    let check_pc = {
      'state_id' : this.select_state_id,
      'district_no': ''
    }; 
    let check_ac = {
        'state_id' : this.select_state_id ,
        'district_no': '',
        'pc_no':''
      }; 
    
    if(geteventDist.length >0)
    { 
        this.Datafrm.controls['pc_data'].disable();
        this.hideDiv=false;
        let distric_list =geteventDist.toString();
        check_pc = {
          'state_id' : this.select_state_id,
          'district_no': distric_list
        }; 
        if(this.select_state_id=='28'|| this.select_state_id=='43' || this.select_state_id=='60')
        {
            this.setting.getPincode(this.token,check_pc).subscribe(res =>{
              if(res['status']=='success')
              {
                this.pincod_list=res.response_data.state_list;
              }
            });
        }

        check_ac = {
            'state_id' : this.select_state_id ,
            'district_no': distric_list,
            'pc_no':''
          }; 
    }
    else
    {
      this.hideDiv=true;  
      this.Datafrm.controls['pc_data'].enable();
      if(this.select_state_id=='28'|| this.select_state_id=='43' || this.select_state_id=='60')
      {
        check_pc = {
              'state_id' : this.select_state_id,
              'district_no': ''
            };  
          this.setting.getPincode(this.token,check_pc).subscribe(res =>{
            if(res['status']=='success')
            {
              this.pincod_list=res.response_data.state_list;
            }
          });
      }
    }
    this.setting.getAc(this.token,check_ac).subscribe(res =>{
      if(res['status']=='success')
      {
        this.ac_list=res.response_data.state_list;
      }
    });
    this.setting.getPc(this.token,check_pc).subscribe(res =>{
      if(res['status']=='success')
      {
        this.pc_list=res.response_data;
      }
    }); 
  }
  getPc(totalpc)
  {
    if(totalpc.length >0)
    {
      this.Datafrm.controls['district_data'].disable();
      this.hideDiv=false;
      let pc_list =totalpc.toString();
      let check_ac = {
        'state_id' : this.select_state_id ,
        'district_no': '',
        'pc_no':pc_list
      }; 
      if(this.select_state_id=='28'|| this.select_state_id=='43' || this.select_state_id=='60')
      {
        this.setting.getPincode(this.token,check_ac).subscribe(res =>{
          if(res['status']=='success')
          {
            this.pincod_list=res.response_data.state_list;
          }
        });
      }
      this.setting.getAc(this.token,check_ac).subscribe(res =>{
        if(res['status']=='success')
        {
          this.ac_list=res.response_data.state_list;
        }
      });
    } 
    else
    {
      this.Datafrm.controls['district_data'].enable();
      this.hideDiv=true;  
      if(this.select_state_id=='28'|| this.select_state_id=='43' || this.select_state_id=='60')
      {
         const check_pc = {
              'state_id' : this.select_state_id,
            };  
          this.setting.getPincode(this.token,check_pc).subscribe(res =>{
            if(res['status']=='success')
            {
              this.pincod_list=res.response_data.state_list;
            }
          });
      }
    }
  }
  sampleRecords(getevent)
  {
    this.req_sample='';
    if(getevent.target.value!='')
    {
      this.Datafrm.controls['per_record'].disable();
    }
    else
    {
      this.Datafrm.controls['per_record'].enable();
    }
  }
  perRecords(getevent)
  {
    this.req_sample='';
    if(getevent.target.value!='')
    {
      this.Datafrm.controls['sample_size'].disable();
    }
    else
    {
      this.Datafrm.controls['sample_size'].enable();
    }
  }
  clickCheck(event)
  {
   if(event.target.checked)
   {
      this.type_check=event.target.value;
      this.isShow =false;
      this.area_Type_check=false;
   }
  }
  fileChangeData(event)
  {
    //console.log(event.target.files);
    if(event.target.files && event.target.files[0])
    {
      this.upload_data= '';
      this.file_url_data=event.target.files[0];
    }
  }
  getPincode(totalac)
  {
    if(this.select_state_id=='28'|| this.select_state_id=='43' || this.select_state_id=='60')
    {
      if(totalac.length >0)
      {
        let ac_list =totalac.toString();
          let check_ac = {
            'state_id' : this.select_state_id,
            'district_no': '',
            'ac_no':ac_list
          }; 
          this.setting.getPincode(this.token,check_ac).subscribe(res =>{
              if(res['status']=='success')
              {
                this.pincod_list=res.response_data.state_list;
              }
            });
        this.area_Type_check=false;    
      } 
      else
      {
        const check_pc = {
              'state_id' : this.select_state_id,
            };  
          this.setting.getPincode(this.token,check_pc).subscribe(res =>{
            if(res['status']=='success')
            {
              this.pincod_list=res.response_data.state_list;
            }
          });
          this.area_Type_check=true;
        
      }
    }
    else
    {
      if(totalac.length >0)
      {
        this.area_Type_check=false;
      }
      else
      {
        this.area_Type_check=true;
      }
    }
  }
  onCheckboxarea($event)
  {
    if($event.target.checked)
    {
      this.AreaType.push($event.target.value);
    }
    else
    {
      this.AreaType.forEach((value,index)=>{
        if(value==$event.target.value) this.AreaType.splice(index,1);
      });
    }
  }

  
  resetButton()
  {
    this.Datafrm.reset({
      state_data : null,
      sample_size :'',
      district_data :'',
      pc_data : '',
      ac_data : '',
      pincode_data :'',
      per_record :'',
      radio_type :'',
      detailed_option:'',

     });
    this.hideDiv =true;
    this.area_Type_check=true;
    this.isShow=true;
    this.csv_file_url = '';
    this.req_sample='';
    this.upload_data = '';
    this.state_url ='';
    this.Datafrm.controls['sample_size'].enable();
    this.Datafrm.controls['district_data'].enable();
    this.Datafrm.controls['per_record'].enable();
    this.Datafrm.controls['pc_data'].enable();
    this.AreaType=[];
  }
  DataextractFormsubmit()
  {
    this.req_sample ='';
    this.upload_data='';
    let araType ='';
    if(this.AreaType.length>0)
    {
      araType=this.AreaType.toString();
    }
    else
    {
      araType='';
    }
    if(this.Datafrm.valid)
    {
      if(this.Datafrm.controls.radio_type.value)
      {
        if(this.file_url_data=='')
        {
          this.upload_data= "Please upload valid file";
          return false;
        }
      }
      const formData:FormData = new FormData();
      if(this.Datafrm.controls.radio_type.value)
      {
          formData.append('state_id',this.Datafrm.controls.state_data.value);
          formData.append('sample_size',this.Datafrm.controls.sample_size.value);
          formData.append('per_record',this.Datafrm.controls.per_record.value);
          formData.append('type',this.Datafrm.controls.radio_type.value);
          formData.append('csv_file',this.file_url_data);
          formData.append('detailed_option',this.Datafrm.controls.detailed_option.value);
          formData.append('user_id',this.userID.toString());
          formData.append('region_type',araType);
          formData.append('age_gender_bypass',this.Datafrm.controls.by_pass.value);
      }
      else
      {
          formData.append('state_id',this.Datafrm.controls.state_data.value);
          formData.append('ac_no',this.Datafrm.controls.ac_data.value.toString());
          formData.append('district_no',this.Datafrm.controls.district_data.value.toString());
          formData.append('pc_no',this.Datafrm.controls.pc_data.value.toString());
          formData.append('pincode',this.Datafrm.controls.pincode_data.value.toString());
          formData.append('sample_size',this.Datafrm.controls.sample_size.value);
          formData.append('per_record',this.Datafrm.controls.per_record.value);
          formData.append('detailed_option',this.Datafrm.controls.detailed_option.value);
          formData.append('user_id',this.userID.toString());
          formData.append('region_type',araType);
          formData.append('age_gender_bypass',this.Datafrm.controls.by_pass.value);
      }
      if(this.Datafrm.controls.sample_size.value!='' || this.Datafrm.controls.per_record.value!='')
      {
        this.isLoader=true;
        this.setting.dataextract(this.token,formData).subscribe(res=>{
          if(res['status']=='success')
          {
            this.successMessage =res['msg'];
            this.resetButton();
            //this.csv_file_url = this.setting.datadownload(res.response_data.csv_url);
          }
          else
          {
            this.errorMessage = res['msg'];
          }
          this.isLoader=false;
        });
      }
      else
      {
        this.req_sample = "Please enter sample size or per unit size.";
      }
    }
    else
    {
      this.isFormsubmit=true;
      if(this.Datafrm.controls.sample_size.value=='' || this.Datafrm.controls.per_record.value=='')
      {
       this.req_sample = "Please enter sample size or per unit size.";
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
