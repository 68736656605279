  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Survey List</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Survey</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Survey List</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <app-loader *ngIf="isLoader"></app-loader>
                <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="example1" class="table  table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Sr.No</th> 
                    <th>Customer Name</th>
                    <th>Mobile</th>
                    <th>Survey Name</th>
                    <th>Date</th>
                    <th>Updated Date</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr  *ngFor="let survey of surveyArray; let i = index"   style="cursor: pointer;" >
                    <td>{{ i+1 }} 
                       </td>
                    <td>{{ survey.user_data.full_name }}</td>
                    <td>{{ survey.user_data.user_mobile  }}</td>
                    <td>{{ survey.survey_name  }}</td>
                    <td>{{ survey.created_at1}}</td>
                    <td>{{ survey.updated_at1}}</td>
                    <td>
                     <!-- <span *ngIf="survey.is_text_file_survey==='1'"><span class="badge badge-danger">Text</span></span>
                      <span *ngIf="survey.is_text_file_survey==='0'"><span class="badge badge-secondary">Audio</span></span>
                     -->
                     <span class="survey-text" *ngIf="survey.is_text_file_survey==='1' || survey.survey_type==='2' " title="Text"><i class="fa fa-text-width" aria-hidden="true"></i></span>
                      <span class="survey-text" *ngIf="survey.is_text_file_survey==='1' || survey.survey_type==='1' " title="Text"><i class="fa fa-text-width" aria-hidden="true"></i></span>
                      <span class="survey-audio" *ngIf="survey.is_text_file_survey==='0' && survey.survey_type==='0'" title="Audio"><i class="fa fa-volume-up" aria-hidden="true"></i></span>
                    </td>
                    <td>
                      <a *ngIf="survey.survey_type==='0'" routerLink="{{survey.id}}/edit" style="margin-left: 10px;" title="View survey"><i class="fas fa-eye" style="font-size: 20px;"></i></a>
                      <a  *ngIf="survey.survey_type==='1'" routerLink="{{survey.id}}/edit/whatsapp" style="margin-left: 10px;" title="View survey"><i class="fas fa-eye" style="font-size: 20px;"></i></a>
                      <a  *ngIf="survey.survey_type==='2'" routerLink="{{survey.id}}/edit/online" style="margin-left: 10px;" title="View survey"><i class="fas fa-eye" style="font-size: 20px;"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="surveyArray.length==0">
                    <td colspan="8">No Record Found</td>
                  </tr>
                 </tbody>
                </table>
              </div>
             </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->