  <style>
    .action-links a{font-size:22px;}
    .edit-link{color:#000}
    .active-status-link{color:green}
    .block-status-link{color:red;}
  </style>
  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
 
     <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header">
                 <h3 class="card-title">Audio List</h3>
               </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped " datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                   <tr>
                     <th>Sr.No</th> 
                     <th>Audio Name</th>
                     <th>State</th>
                     <th>Status</th>
                     <th>Action</th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr *ngFor="let audio of audioListArray; let i = index">
                     <td>{{ i+1 }}</td>
                     <td>{{ audio.custom_audio_name }}</td>
                     <td>{{ audio.state_name  }}</td>
                     <td>
                       <span *ngIf="audio.status==='1'"><span class="badge badge-success">Active</span></span>
                       <span *ngIf="audio.status==='0'"><span class="badge badge-danger">Block</span></span>
                     </td>
                     <td class="action-links">
                       <a routerLink="/audio/{{audio.custom_audio_id}}/edit/" class="edit-link" ><i class="far fa-edit"></i></a>
                       <a class="active-status-link" *ngIf="audio.status==='1'" href="javascript:void(0);" (click)="audioStatus(audio.custom_audio_id,audio.status)" style="margin-left: 10px;"><i class="fas fa-toggle-on"></i></a>
                       <a class="block-status-link" *ngIf="audio.status==='0'" href="javascript:void(0);" (click)="audioStatus(audio.custom_audio_id,audio.status)" style="margin-left: 10px;"><i class="fas fa-toggle-off"></i></a>
                     </td>
                   </tr>
                   <tr *ngIf="audioListArray.length==0">
                     <td colspan="5">No Record Found</td>
                   </tr>
                   </tbody>
                 </table>
               </div>
              </div> 
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->