<style>
    .action-links a{font-size:22px;}
    .edit-link{color:#000}
    .active-status-link{color:green}
    .block-status-link{color:red;}
  </style>
  <!-- Content Wrapper. Contains page content -->
   <div class="content-wrapper">
     <!-- Content Header (Page header) -->
     <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>DataTables</h1>
           </div>
           <div class="col-sm-6">
           </div>
         </div>
       </div><!-- /.container-fluid -->
     </section>
    <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <div class="col-12">
             <div class="card">
               <div class="card-header">
                 <h3 class="card-title"  style="margin-right: 100px;" >Panel Builder</h3>
                 <ul class="list-navi-info" >
                  <li>    
                  <a title="Resend Message"  class="edit-link t-extra-btn debitbtn" style="margin-right: 10px;"><svg viewBox="0 0 24 24" width="16" height="16" stroke="#FFFFFF" stroke-width="2.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg></a>
                   Resend SMS 
                  </li>
                  <li>      
                   <a title="The button is activated by 24 hrs." class="edit-link t-extra-btn disablebtn" style="margin-right: 10px;"><svg viewBox="0 0 24 24" width="16" height="16" stroke="#FFFFFF" stroke-width="2.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg></a>
                   Campaign Underway
                  </li>
                  <li>      
                    <a title="Download File" class="edit-link t-extra-btn addbtn"><i class="fa fa-download"></i></a>
                    Download Report 
                  </li>
                </ul>
               </div>
               <!-- /.card-header -->
               <div class="card-body">
                 <app-loader *ngIf="isLoader"></app-loader>
                 <div class="row">
                   <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
                   <div class="alert alert-success" *ngIf="successMessage">{{ successMessage }}</div>
                 </div>
                
                 <div class="table-responsive">
                 <table  id="example1" class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                   <thead>
                    <tr>
                        <th>Sr.No</th> 
                        <th>Survey Name</th>
                        <th>Numbers Attempted</th>
                        <th>Calls Picked</th>
                        <th>Opted in</th>
                        <th>Unique People SMS</th>
                        <th>Total SMS</th>
                        <th>Clicked on SMS</th>
                        <th>Used Chatbot</th>
                        <th>Completed Profile</th>
                        <th>Total Seconds Consumed</th>
                        <th title="Cost per completed profile">CPCP</th>
                        <th>Cost</th>
                        <th>Type</th>
                        <th style="width: 55px;">Action</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr *ngFor="let builder of builderListArray; let i = index">
                      <td>{{ i+1 }}</td> 
                      <td>{{ builder.survey_name }}</td> 
                      <td>{{ builder.numbers_attempted | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.calls_picked | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.valid_key_press | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.people_count | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.sent_sms_count | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.click_on_sms | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.click_on_chatbot_cnt | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.profile_completed_cnt | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.total_second_consumed | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.CPCP_cost | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.CPCP_numerator | number :'1.0-0':'en-IN' }}</td>
                      <td>{{ builder.campaign_type }}</td>
                      <td  class="action-links">
                        <a title="Resend Message" *ngIf="builder.is_show_resend_button=='yes'" (click)="resendmessageSurvey(builder.survey_id);" class="edit-link t-extra-btn debitbtn" style="margin-right: 10px; cursor: pointer;"><svg viewBox="0 0 24 24" width="16" height="16" stroke="#FFFFFF" stroke-width="2.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg></a>
                        <a title="The button is activated by 24 hrs." *ngIf="builder.is_show_resend_button=='no'" class="edit-link t-extra-btn disablebtn" style="margin-right: 10px; cursor: pointer;"><svg viewBox="0 0 24 24" width="16" height="16" stroke="#FFFFFF" stroke-width="2.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg></a>
                        <a title="Download File" class="edit-link t-extra-btn addbtn" href="{{downloadData(builder.csv_url)}}"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                   <tr *ngIf="builderListArray.length==0">
                     <td colspan="10">No Record Found</td>
                   </tr>
                   </tbody>
                 </table>
                </div>
               </div>
               <!-- /.card-body -->
             </div>
             <!-- /.card -->
           </div>
           <!-- /.col -->
         </div>
         <!-- /.row -->
       </div>
       <!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   </div>
   <!-- /.content-wrapper -->
   