import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, RouterLink, Router } from '@angular/router';
import { first, count } from 'rxjs/operators';
import * as $ from 'jquery';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { DomSanitizer } from '@angular/platform-browser';
import { SurveyService } from 'src/app/service/survey.service';
import { UserService } from 'src/app/service/user.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { element } from 'protractor';
import { start } from 'repl';



@Component({
  selector: 'app-survey-wave',
  templateUrl: './survey-wave.component.html',
  styleUrls: ['./survey-wave.component.css']
})
export class SurveyWaveComponent implements OnInit {

  randomForm: FormGroup;
  loginPage = false;
  wave: WaveSurfer;
  resultArray = [];
  audioID: number;
  url_data = '';
  surveyID: number;
  random_default_parameter = [];
  Survey_name = '';
  errorMessage = '';
  successMessage = '';
  isLoader = false;
  token  ='';
  isRandom = '0';
  randomArry = [];
  update_end = '';
  regionStartCtrl = new FormControl(0);
  regionEndCtrl = new FormControl(0);
  regionPositions: Regions;
  regions: any[] = [];
  isValid = false
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private surveysrv: SurveyService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((param: Params) => {
      this.audioID = +param['id']
      this.surveyID = +param['surveyID']
      console.log(this.audioID)
    });
    //this.audioID = 7762;
    //this.surveyID = 4049;


    const userData: {
      email: string,
      mobile_no: string,
      user_id: number,
      full_name: string,
      admin_type: string,
      token: string
    }
      = JSON.parse(localStorage.getItem('userData'));
     this.token = userData.token;

     console.log('token First',this.token);
    const user_id = userData.user_id;
    const admin_type = userData.admin_type;
    //7726/4036
    //this.randomOption().push(this.newOption());
    //this.audioID
    this.surveysrv.audioDetails( this.token, this.audioID, user_id).pipe(first()).subscribe(survey => {
      if (survey.status == 'success') {
        this.url_data = survey.response_data.audio_data.original_mp3_file;
        this.Survey_name = survey.response_data.audio_data.survey_name;
        this.random_default_parameter = survey.response_data.random_default_parameter;
        this.isRandom = survey.response_data.isRandom;
        this.randomArry = survey.response_data.variations;
        this.getAudio();
      }
    });
    if (this.isRandom == '0') {
      this.randomForm = this.fb.group({
        audio_id: this.audioID,
        survey_id: this.surveyID,
        randomOption: this.fb.array([]),
      });
    }
    
  }
  

  randomOption(): FormArray {
    return this.randomForm.get("randomOption") as FormArray
  }
  newOption(): FormGroup {
    let optiondata = {
      audio_option: [''],
      start_time_frm: ['0'],
      end_time_frm: ['0']
    };
    return this.fb.group(optiondata)
  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wave = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'violet',
        progressColor: 'purple',
        responsive: true,
        backend: 'MediaElement',
        loop: false,
        plugins: [
          TimelinePlugin.create({
            primaryLabelInterval: 1,
            container: '#wave-timeline'
          }),
          Regions.create({
            regions: [
              {
                start: 0,
                end: 1,
                loop: false,
                color: 'hsla(400, 100%, 30%, 0.5)'
              }
            ],
            dragSelection: {
              slop: 1
            },
            loopSelection: false
          }),

        ],
        xhr: {
          credentials: 'same-origin',
          cache: "default",
          crossOrigin: 'anonymous',
          method: "GET",
          responseType: 'arraybuffer',
        }

      });
      this.wave.on("error", function (error) {
        console.log("error = ", error);
      });
    });
  }
  addReligion() {
    const regions = this.wave.regions.list;
    this.resultArray = Object.keys(this.wave.regions.list).map(function (personNamedIndex) {
      let person = regions[personNamedIndex];
      return person;
    });
    console.log(this.resultArray);
    if (this.resultArray.length > this.randomOption().length) {
      var i = 0
      this.resultArray.forEach(res => {
        this.randomOption().push(this.newOption());
        i++;
      });
    }
    // console.log(this.resultArray.length,'====',this.randomOption().length)
    this.successMessage = '';
    this.errorMessage = '';
  }
  removeData() {
    this.wave.clearRegions();
    this.addReligion();
    this.successMessage = '';
    this.errorMessage = '';
  }
  playAudioReligion(playID) {
    let region = Object.values(this.wave.regions.list)[playID];
    this.wave.play(region['start'], region['end']);
  }
  getAudio() {
    if (!this.wave) {
      this.generateWaveform();
    }
    this.cdr.detectChanges();
    const get_url = this.url_data
    Promise.resolve().then(() => this.wave.load(this.url_data, [0.0218, 0.0183, 0.0165, 0.0198, 0.2137]));
  }
  onStopPressed(): void {
    this.wave.stop();
  }
  playAudio() {
    console.log('testing');
    this.wave.play();
  }
  stopAudio() {
    this.wave.pause();
  }
  randomFormsubmit() {
    
      console.log(this.randomForm.value, 'Dhananjay');
      console.log(this.randomForm.valid, 'Suprit');
  
    if (this.randomForm.valid) {
      const regions = this.wave.regions.list;
      const getArry = Object.keys(this.wave.regions.list).map(function (personNamedIndex) {
        let person = regions[personNamedIndex];
        return person;
      });
      console.log(getArry);
      const userData: {
        email: string,
        mobile_no: string,
        user_id: number,
        full_name: string,
        admin_type: string,
        token: string
      }
        = JSON.parse(localStorage.getItem('userData'));
        const token = userData.token;
      const user_id = userData.user_id;
      const admin_type = userData.admin_type;
      this.successMessage = '';
      this.errorMessage = '';
      console.log(this.resultArray);
      var i = 0;
      this.resultArray.forEach(res => {
        const secondControl = this.randomOption().at(i).get('start_time_frm').patchValue(res.start);
        const thirdControl = this.randomOption().at(i).get('end_time_frm').patchValue(res.end);
        i++;
      });
      this.isLoader = true;
      console.log('for submit data',this.randomForm.value);
          this.surveysrv.addRandom(token, this.randomForm.value).pipe(first()).subscribe(survey => {
          this.isLoader = false;
          if (survey.status == 'success') {
            this.errorMessage = '';
            this.successMessage = survey.msg;
            this.isRandom = '1';
            this.randomArry = survey.response_data.variations;
          }
          else {
            this.errorMessage = survey.msg;
          }
        });
      }else{
        this.errorMessage = "Please fill the required fields.";
      }
  }

  detailSurvey(){
    this.isLoader = true;
    const sent_data = {
      'audio_id': this.audioID,
    };

    console.log('token',this.token)

    this.surveysrv.deleteRandom(this.token,sent_data).subscribe(survey => {
      this.isLoader = false;
      if (survey.status == 'success') {
        console.log('after delete veriation',survey);
        this.successMessage = survey.msg;
        this.isRandom = '0';
      }
      else {
        this.errorMessage = survey.msg;
      }
    });
  }


  backButton(surveyID) {
    this.router.navigate(['/survey/' + surveyID + '/edit']);
  }
  // onchaange(event) {
  //   console.log('1111', event);
  //   this.wave.getDuration(event);
    
  // }

  deleteAudio(index){

    let region = this.wave.regions.list[Object.keys(this.wave.regions.list)[index]];
    
    // console.log(' region in deletes function',region);


  //   console.log('deleted region',region);
  //   // console.log('start',this.regions[index].start);
  //   // console.log('End',this.regions[index].end);
    if (region) {
      region.remove(region[index]);
      this.addReligion();
    }
    // this.wave.on('region-removed', (region) => {
    //     console.log('after deleted region',region);
    // });

  }

  updateRegion(index: any): void {
    

    const formArray = this.randomForm.get('randomOption') as FormArray;

    const formGroup = formArray.at(index) as FormGroup;

    const startTime = formGroup.get('start_time_frm').value;
    const endTime = formGroup.get('end_time_frm').value;
 
    // Update the region start and end times
    const region = this.wave.regions.list[Object.keys(this.wave.regions.list)[index]];

    // console.log('region',region);
    // console.log('start',this.regions[index].start);
    // console.log('End',this.regions[index].end);
    if (region) {
      region.update({
        start: parseFloat(startTime),
        end: parseFloat(endTime),
        loop: false,
        color: 'rgba(0, 0, 0, 0.1)'
        
      });
    }
  //   this.wave.on('region-updated', (region) => {
  //     console.log(`Region ${region.id} was updated. New start: ${region.start}, New end: ${region.end}`);
  // });
   

  }
  // validateRandomOptionArray(randomOptionArray) {

  //   console.log('Check for validation',randomOptionArray)

  //     for (const option of randomOptionArray) {
  //         const {audio_option,start_time_frm, end_time_frm } = option;

  //           // Convert the start and end times to numbers for validation
  //         const startTime = parseFloat(start_time_frm);
  //         const endTime = parseFloat(end_time_frm);

  //         console.log('start_time_frm', start_time_frm);
  //         console.log('end_time_frm', end_time_frm);

  //         // Check if start_time_frm or end_time_frm is invalid (empty, NaN, or less than 0)
  //         if (
  //             audio_option == '' ||
  //             start_time_frm === '' || isNaN(startTime) || startTime < 0 ||
  //             end_time_frm === '' || isNaN(endTime) || endTime < 0
  //         ) {
  //             // One of the values is invalid, return false
  //             return false;
  //         }
  //       }

  //     // All values are valid, return true
  //     return true;
  // }


}
