import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StepsService } from 'src/app/service/steps.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-step',
  templateUrl: './add-step.component.html',
  styleUrls: ['./add-step.component.css']
})
export class AddStepComponent implements OnInit {
  questionsForm      : FormGroup   ;
  langArray = [];
  refQuestionArray  = [];
  questionTypeArray  = [];
  surveyId   : any = "";
  surveyName : any = "";
  isLoader = true;
  token = "";
  questionId = "";
  user_id :number;
  errorMessage = '';
  successMessage='';
  selectedQuestion: boolean = false;
  selectedOptionType : boolean = false;
  constructor(
      private fb       : FormBuilder,
      private stepServ : StepsService,  
      private router   : Router,
      private route    : ActivatedRoute
  ) {
    this.questionsForm = this.fb.group({
        lang_id: ['', Validators.required],
        txt_question: ['', Validators.required],
        ref_id: [''],
        option_type: ['', Validators.required],
        question_type: ['', Validators.required],
        qoptions: this.fb.array([]),
    });
    const userData:{
      email:string,
      mobile_no:string,
      user_id:number,
      full_name : string,
      admin_type:string,
      token:string
    } = JSON.parse(localStorage.getItem('userData'));
    this.token = userData.token;
    this.user_id = userData.user_id;
    this.surveyName =  localStorage.getItem('survey_name');
   }

  ngOnInit(): void {
    this.route.params.subscribe((param :  Params)=> {
      this.surveyId  = atob(param['id']);
    });
    this.getMasterData();
  }
  addRows() {
    let ctrl = <FormArray>this.questionsForm.controls.qoptions;
    ctrl.push(
      this.fb.group({
        name: ['', Validators.required],
        preference: [''],
      })
    );
  }
  removeAddressAt(i) {
      let ctrl = <FormArray>this.questionsForm.controls.qoptions;
      ctrl.removeAt(i);
  }
  getMasterData(){
    this.isLoader = true;
    this.errorMessage   = '';
    this.successMessage = '';  
    this.stepServ.getMasterOfSteps(this.token,this.surveyId).pipe(first()).subscribe((data: any) => { 
        this.isLoader = false;
        if(data.status == 'success'){ 
            this.langArray  = data.response_data.language;
            this.questionTypeArray = data.response_data.question_type;
        }else{
          this.errorMessage = data.msg;
        }
      },(error) => {
          this.isLoader = false;
          this.errorMessage = "Something Went Wrong, Please Try Again";
      }
     );
  }
  checkQuestionType(id:any){
    if(id == 3){
      this.selectedQuestion = false;
      this.questionsForm.controls.option_type.clearValidators(); // 6. Clear All Validators
      this.questionsForm.controls.option_type.updateValueAndValidity();
      this.questionsForm.controls.option_type.setValue('');
      let ctrl = <FormArray>this.questionsForm.controls.qoptions;
      ctrl.clear();
    }else{
      this.selectedQuestion = true;
      this.questionsForm.controls.option_type.setValidators([Validators.required]);
      this.questionsForm.controls.option_type.updateValueAndValidity();
    }
  }
  getQuestions(id: any){
    if(id){
      this.isLoader = true;
      this.errorMessage   = "";
      this.successMessage = "";  
      this.stepServ.getQuestions(this.token,this.surveyId,id,this.questionId).pipe(first()).subscribe((data: any) => { 
        this.isLoader = false;
        if(data.status == 'success'){ 
            this.refQuestionArray  = data.response_data;
        }else{
            this.errorMessage = data.msg;
            this.refQuestionArray  = [];
        }
        },(error) => {
            this.isLoader = false;
            this.errorMessage = "Something Went Wrong, Please Try Again";
        }
      );

   
    }
  }
  onSubmit(values: any){
    if(this.questionsForm.valid){
      this.isLoader = true;
      values['survey_id'] = this.surveyId;
      values['user_id']   = this.user_id;
      if(values.qoptions.length > 0){
        for (let val of values.qoptions) {              
              val['preference']  = (val.preference == true) ? '1' : '0';       
        }
      }
      this.errorMessage   = '';
      this.successMessage = '';
      this.stepServ.addStep(this.token,values).pipe(first()).subscribe((res: any) => { 
        this.isLoader = false;
        this.scrollPage();
        if(res.status=='success'){           
            this.questionsForm.reset();
            this.questionsForm.controls.lang_id.setValue('');
            this.questionsForm.controls.ref_id.setValue('');
            this.questionsForm.controls.question_type.setValue('');
            this.selectedQuestion = false;
            this.selectedOptionType = false;
            let ctrl = <FormArray>this.questionsForm.controls.qoptions;
            ctrl.clear();
            this.successMessage = res.msg;            
        }else{
            this.errorMessage   = res.msg;
        }
      },(error) => {
        this.isLoader = false;
        this.errorMessage = "Something Went Wrong, Please Try Again";
      }
   );
    }
    console.log(values);
  }
  handleChangeOptionType(value: any){
     let ctrl = <FormArray>this.questionsForm.controls.qoptions;
     console.log("length =>",ctrl.length);
      if(value == 0){
        this.selectedOptionType = true;  
        this.addRows();     
      }else{  
        this.selectedOptionType = false;
        ctrl.clear();
      }
  }
  scrollPage(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
